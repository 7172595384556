import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const create = async (data) => {
  const response = await axios.post(
    `${API_URL}api/guestcare_ut_establishments`,
    data
  );
  return response.data;
};

const update = async (data, establishmentId) => {
  const response = await axios.put(
    `${API_URL}api/guestcare_ut_establishments/${establishmentId}`,
    data
  );

  return response;
};

const deleteEstablishment = async (establishmentId) => {
  const response = await axios.delete(
    `${API_URL}api/guestcare_ut_establishments/${establishmentId}`
  );

  return response;
};

export default {
  create,
  update,
  deleteEstablishment,
};
