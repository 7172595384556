import React from "react";
import { v4 as uuidv4 } from "uuid";

export default function Staff({ dataEtablissements, dataStaff, setDataStaff }) {
  const addNewUser = (establishmentId) => {
    // Nouvel user avec id rajouté pour retrouver l'objet lors du POST

    const initialUser = {
      id: uuidv4(),
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      role: "",
      correspondingEstablishment: establishmentId,
    };

    const updatedUser = [...dataStaff, initialUser];
    setDataStaff(updatedUser);
  };

  const removeUser = (userId) => {
    // Suppression du user grace à son id
    const updatedUser = dataStaff.filter((user) => user.id !== userId);
    setDataStaff(updatedUser);
  };

  const handleUserChange = (userId, field, value) => {
    // Modification du user
    const updatedUser = dataStaff.map((user) =>
      user.id === userId ? { ...user, [field]: value } : user
    );
    setDataStaff(updatedUser);
  };

  return (
    <div className="">
      <h5>Informations du personnel</h5>

      {dataEtablissements.length === 0 ? (
        <p>Aucun établissement disponible.</p>
      ) : (
        <div className="accordion" id="accordionEstablishments">
          {dataEtablissements.map((establishment, estIndex) => (
            <div className="card mb-4" key={estIndex}>
              <div className="card-header" id={`heading-${establishment.id}`}>
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapse-${establishment.id}`}
                    aria-expanded={estIndex === 0 ? "true" : "false"}
                    aria-controls={`collapse-${establishment.id}`}
                  >
                    {establishment.name || `Établissement ${estIndex + 1}`}
                  </button>
                </h5>
              </div>

              <div
                id={`collapse-${establishment.id}`}
                className="collapse"
                aria-labelledby={`heading-${establishment.id}`}
                data-parent="#accordionEstablishments"
              >
                <div className="card-body">
                  <h6>
                    Utilisateurs de l'établissement {establishment.name} :
                  </h6>
                  <ul>
                    {dataStaff
                      .filter(
                        (user) =>
                          user.correspondingEstablishment === establishment.id
                      )
                      .map((user, userIndex) => (
                        <li key={estIndex + "-" + userIndex} className="mb-3">
                          <div className="card p-3">
                            <div className="form-group">
                              <label>Email :</label>
                              <input
                                type="email"
                                className="form-control"
                                value={user.email}
                                onChange={(e) =>
                                  handleUserChange(
                                    user.id,
                                    "email",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Prénom :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={user.firstname}
                                onChange={(e) =>
                                  handleUserChange(
                                    user.id,
                                    "firstname",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Nom :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={user.lastname}
                                onChange={(e) =>
                                  handleUserChange(
                                    user.id,
                                    "lastname",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Mot de passe :</label>
                              <input
                                type="password"
                                className="form-control"
                                value={user.password}
                                onChange={(e) =>
                                  handleUserChange(
                                    user.id,
                                    "password",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Rôle :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={user.role}
                                onChange={(e) =>
                                  handleUserChange(
                                    user.id,
                                    "role",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="text-center">
                              <p
                                style={{ cursor: "pointer" }}
                                className="text-center text-danger mt-3"
                                onClick={() => removeUser(user.id)}
                              >
                                Enlever cet utilisateur
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  <button
                    className="btn btn-link"
                    onClick={() => addNewUser(establishment.id)}
                  >
                    Ajouter un nouvel utilisateur
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
