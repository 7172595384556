import React from "react";

export default function Entreprise() {
  return (
    <form className="mt-4">
      <div className="mb-3">
        <label htmlFor="companyName" className="form-label">
          Nom de l'entreprise
        </label>
        <input
          type="text"
          className="form-control"
          id="companyName"
          placeholder="Nom de l'entreprise"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="companyAddress" className="form-label">
          Adresse de l'entreprise
        </label>
        <input
          type="text"
          className="form-control"
          id="companyAddress"
          placeholder="Adresse de l'entreprise"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="companyEmail" className="form-label">
          Email de l'entreprise
        </label>
        <input
          type="email"
          className="form-control"
          id="companyEmail"
          placeholder="Email de l'entreprise"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="companyPhone" className="form-label">
          Numéro de téléphone
        </label>
        <input
          type="tel"
          className="form-control"
          id="companyPhone"
          placeholder="Numéro de téléphone"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="companyCity" className="form-label">
          Ville
        </label>
        <input
          type="text"
          className="form-control"
          id="companyCity"
          placeholder="Ville"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="companyRooms" className="form-label">
          Nombre de chambres
        </label>
        <input
          type="number"
          className="form-control"
          id="companyRooms"
          placeholder="Nombre de chambres"
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Enregistrer les changements
      </button>
    </form>
  );
}
