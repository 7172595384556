import React from "react";
import { v4 as uuidv4 } from "uuid";

export default function Lockers({ dataRooms, dataLockers, setDataLockers }) {
  const addNewLocker = (establishmentId) => {
    // Nouveau locker avec id qui sert à retrouver l'objet lors du POST
    const initialLocker = {
      id: uuidv4(),
      number: "",
      label: "",
      description: "",
      correspondingRoom: establishmentId,
      createdAt: new Date().toISOString(),
      modifiedAt: new Date().toISOString(),
    };

    const updatedLockers = [...dataLockers, initialLocker];

    setDataLockers(updatedLockers);
  };

  const removeLocker = (lockerId) => {
    // Suppression du locker grâce à son id
    const updatedLocker = dataLockers.filter(
      (locker) => locker.id !== lockerId
    );
    setDataLockers(updatedLocker);
  };

  const handleLockerChange = (lockerId, field, value) => {
    // Changement des valeurs de locker
    const updatedLockers = dataLockers.map((locker) =>
      locker.id === lockerId ? { ...locker, [field]: value } : locker
    );
    setDataLockers(updatedLockers);
  };

  return (
    <div className="">
      <h5>Informations des chambres </h5>

      {dataRooms.length === 0 ? (
        <p>Aucun établissement disponible.</p>
      ) : (
        <div className="accordion" id="accordionEstablishments">
          {dataRooms.map((room, roomIndex) => (
            <div className="card mb-4" key={room.id}>
              <div className="card-header" id={`heading-${room.id}`}>
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapse-${room.id}`}
                    aria-expanded={roomIndex === 0 ? "true" : "false"}
                    aria-controls={`collapse-${room.id}`}
                  >
                    {room.label || `Chambre numéro ${roomIndex + 1}`}
                  </button>
                </h5>
              </div>

              <div
                id={`collapse-${room.id}`}
                className="collapse"
                aria-labelledby={`heading-${room.id}`}
                data-parent="#accordionEstablishments"
              >
                <div className="card-body">
                  <h6>Lockers de la chambre {room.label} :</h6>
                  <ul>
                    {dataLockers
                      .filter((locker) => locker.correspondingRoom === room.id)
                      .map((locker) => (
                        <li key={locker.id} className="mb-3">
                          <div className="card p-3">
                            <div className="form-group">
                              <label>Numéro :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={locker.number}
                                onChange={(e) =>
                                  handleLockerChange(
                                    locker.id,
                                    "number",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Label :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={locker.label}
                                onChange={(e) =>
                                  handleLockerChange(
                                    locker.id,
                                    "label",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Description :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={locker.description}
                                onChange={(e) =>
                                  handleLockerChange(
                                    locker.id,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="text-center">
                              <p
                                style={{ cursor: "pointer" }}
                                className="text-center text-danger mt-3"
                                onClick={() => removeLocker(locker.id)}
                              >
                                Supprimer cette chambre
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  <button
                    className="btn btn-link"
                    onClick={() => addNewLocker(room.id)}
                  >
                    Ajouter une nouvelle chambre
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
