{
  "clients": [
    {
      "id": "db6b49af-0b7a-4bb6-a93d-0f4d052e312a",
      "nom": "Dupont",
      "prenom": "Jean",
      "email": "jean.dupont@example.com",
      "numero_tel": "0123456789",
      "password": "hashed_password",
      "isVerified": true
    },
    {
      "id": "3e1b5d5a-c0a7-4f64-b719-94bfb1f15c94",
      "nom": "Martin",
      "prenom": "Sophie",
      "email": "sophie.martin@example.com",
      "numero_tel": "0987654321",
      "password": "hashed_password",
      "isVerified": false
    },
    {
      "id": "3e1b5d5a-c0a7-4f64-b719-94bfb1f154",
      "nom": "Martina",
      "prenom": "Sophia",
      "email": "sophia.martina@example.com",
      "numero_tel": "0987654321",
      "password": "hashed_password",
      "isVerified": false
    }
  ],
  "etablissements": [
    {
      "id": "de1b2187-332c-4343-8013-1d8cfd2ec438",
      "nom": "Hotel A",
      "ville": "VilleA",
      "adresse": "123 Rue de la République",
      "coordonnees": {
        "longitude": 1.234567,
        "latitude": 48.987654
      },
      "nombre_lockers": 10,
      "proprietaire_id": "db6b49af-0b7a-4bb6-a93d-0f4d052e312a",
      "numero_tel": "0123456789"
    },
    {
      "id": "83f20905-60cc-4e80-86c7-7e0b7e828ff6",
      "nom": "Hotel B",
      "ville": "VilleB",
      "adresse": "456 Avenue du Commerce",
      "coordonnees": {
        "longitude": 2.345678,
        "latitude": 49.876543
      },
      "nombre_lockers": 8,
      "proprietaire_id": "3e1b5d5a-c0a7-4f64-b719-94bfb1f15c94",
      "numero_tel": "0987654321"
    },
    {
      "id": "83f20905-60cc-4e80-86c7-7e0b7e8ff6",
      "nom": "Hotel C",
      "ville": "VilleC",
      "adresse": "456 Avenue du Commerce",
      "coordonnees": {
        "longitude": 2.345678,
        "latitude": 47.876543
      },
      "nombre_lockers": 8,
      "proprietaire_id": "db6b49af-0b7a-4bb6-a93d-0f4d052e312a",
      "numero_tel": "0987654321"
    }
  ],
  "lockers": [
    {
      "id": "locker_de1b2187-332c-4343-8013-1d8cfd2ec438",
      "status": "en_ligne",
      "nombre_casiers": 20,
      "etablissement_id": "de1b2187-332c-4343-8013-1d8cfd2ec438"
    },
    {
      "id": "locker_de1b2187-332c-4343-8013-1d8cfd2ec43R",
      "status": "en_ligne",
      "nombre_casiers": 20,
      "etablissement_id": "de1b2187-332c-4343-8013-1d8cfd2ec438"
    },
    {
      "id": "locker_de1b2187-332c-4343-8013-1d8cfd2ec431",
      "status": "en_ligne",
      "nombre_casiers": 20,
      "etablissement_id": "de1b2187-332c-4343-8013-1d8cfd2ec438"
    },
    {
      "id": "locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6",
      "status": "hors_ligne",
      "nombre_casiers": 15,
      "etablissement_id": "83f20905-60cc-4e80-86c7-7e0b7e828ff6"
    },
    {
      "id": "locker_de1b2187-332c-4343-8013-1d8decfd2ec43R",
      "status": "en_ligne",
      "nombre_casiers": 20,
      "etablissement_id": "de1b2187-332c-4343-8013-1d8cfd2ec438"
    },
    {
      "id": "locker_de1b2187-332c-4343-8013-1d8cfefd2ec431",
      "status": "en_ligne",
      "nombre_casiers": 20,
      "etablissement_id": "de1b2187-332c-4343-8013-1d8cfd2ec438"
    },
    {
      "id": "locker_83f20905-60cc-4e80-86c7-7e0b7efe828ff6",
      "status": "hors_ligne",
      "nombre_casiers": 15,
      "etablissement_id": "83f20905-60cc-4e80-86c7-7e0b7e828ff6"
    }
  ],
  "casiers": [
    {
      "id": "casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_1",
      "locker_id": "locker_de1b2187-332c-4343-8013-1d8cfd2ec438",
      "nb_produits": 8
    },
    {
      "id": "casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_2",
      "locker_id": "locker_de1b2187-332c-4343-8013-1d8cfd2ec438",
      "nb_produits": 5
    },
    {
      "id": "casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_1",
      "locker_id": "locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6",
      "nb_produits": 4
    },
    {
      "id": "casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_2",
      "locker_id": "locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6",
      "nb_produits": 8
    }
  ],
  "produits": [
    {
      "id": "prod_casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_1",
      "nom": "Produit A",
      "description_courte": "Description courte du produit A",
      "description_longue": "Description longue du produit A",
      "prix": 9.99,
      "categorie": "catégorie A",
      "catalogue": "catalogue_1",
      "casier_id": "casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_1"
    },
    {
      "id": "prod_casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_2",
      "nom": "Produit B",
      "description_courte": "Description courte du produit B",
      "description_longue": "Description longue du produit B",
      "prix": 19.99,
      "categorie": "catégorie B",
      "catalogue": "catalogue_2",
      "casier_id": "casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_2"
    },
    {
      "id": "prod_casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_1",
      "nom": "Produit C",
      "description_courte": "Description courte du produit C",
      "description_longue": "Description longue du produit C",
      "prix": 14.99,
      "categorie": "catégorie C",
      "catalogue": "catalogue_3",
      "casier_id": "casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_1"
    },
    {
      "id": "prod_casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_2",
      "nom": "Produit D",
      "description_courte": "Description courte du produit D",
      "description_longue": "Description longue du produit D",
      "prix": 24.99,
      "categorie": "catégorie D",
      "catalogue": "catalogue_2",
      "casier_id": "casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_2"
    }
  ],
  "catalogues": [
    {
      "id": "catalogue_1",
      "titre": "Catalogue 1",
      "description": "Description du catalogue 1",
      "produits": ["prod_casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_1"]
    },
    {
      "id": "catalogue_2",
      "titre": "Catalogue 2",
      "description": "Description du catalogue 2",
      "produits": [
        "prod_casier_locker_de1b2187-332c-4343-8013-1d8cfd2ec438_2",
        "prod_casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_2"
      ]
    },
    {
      "id": "catalogue_3",
      "titre": "Catalogue 3",
      "description": "Description du catalogue 3",
      "produits": ["prod_casier_locker_83f20905-60cc-4e80-86c7-7e0b7e828ff6_1"]
    }
  ]
}
