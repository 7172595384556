import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const SERVICE_API_TOKEN = process.env.SERVICE_API_TOKEN;

const getTokenServiceApi = async (role) => {
  const response = await axios.get(
    `${API_URL}service-api/security/${SERVICE_API_TOKEN}/${role}`
  );

  return response.data;
};

export { getTokenServiceApi };
