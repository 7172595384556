import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../context/AppContext";
import { Link } from "react-router-dom";
import TechnicalForm from "./client/TechnicalForm";
import StaffForm from "./client/StaffForm";

export default function CreateClient() {
  const { typeUserCreation, setTypeUserCreation } = useContext(Context); // state pour avoir sélectionné le type de user que l'on veut creer

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between ">
        <h2>Ajouter un nouvel utilisateur</h2>
        {typeUserCreation && (
          <button
            style={{ cursor: "pointer" }}
            onClick={() => setTypeUserCreation(null)}
            className="btn btn-sm btn-primary"
          >
            Revenir au choix du type d'utilisateur
          </button>
        )}
      </div>
      {typeUserCreation ? (
        typeUserCreation === "technical" ? (
          <TechnicalForm />
        ) : typeUserCreation === "staff" ? (
          <StaffForm />
        ) : null
      ) : (
        <div>
          <h4 className="mt-5">Choisissez le type d'utilisateur</h4>
          <div className="row">
            <div className="p-3 col-6 col-md-4">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setTypeUserCreation("technical")}
                className="card d-flex align-items-center justify-content-center"
              >
                <div className="card-body d-flex align-items-center justify-content-center text-center">
                  EQUIPE TECHNIQUE
                </div>
              </div>{" "}
            </div>
            <div className="p-3 col-6 col-md-4">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setTypeUserCreation("staff")}
                className="card d-flex align-items-center justify-content-center"
              >
                <div className="card-body d-flex align-items-center justify-content-center text-center">
                  PERSONNEL
                </div>
              </div>{" "}
            </div>{" "}
            <div className="p-3 col-6 col-md-4">
              <Link
                to="/groupes/creer"
                style={{ cursor: "pointer" }}
                className="card d-flex align-items-center justify-content-center border border-info"
              >
                <div className="card-body d-flex align-items-center justify-content-center text-center">
                  MANAGER
                </div>
              </Link>{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
