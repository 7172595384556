import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/AppContext";

const fakeMail =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto modi aut voluptatibus, ut, labore consectetur sapiente eum nihil animi in non perferendis quia, placeat ducimus ipsum maxime? Molestias atque voluptatibus natus necessitatibus nisi autem aliquam, sequi rem accusantium, dolor, consequuntur similique minima! Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto modi aut voluptatibus, ut, labore consectetur sapiente eum nihil animi in non perferendis quia, placeat ducimus ipsum maxime? Molestias atque voluptatibus natus necessitatibus nisi autem aliquam, sequi rem accusantium, dolor, consequuntur similique minima!";

export default function Mails({ jwtUser }) {
  const [mailSelected, setMailSelected] = useState(null);
  const { clientsData, setClientSelected } = useContext(Context);

  const navigate = useNavigate();

  const handleNavigateToClient = (clientIndex, isModal) => {
    // Permet de naviguer jusqu'au client en utilisant le useContext
    setClientSelected(clientIndex);
    navigate("/clients");
    if (isModal) document.getElementById("modalOpener").click();
  };

  return (
    <div className=" container-fluid">
      <div className="d-flex align-items-center justify-content-between">
        <h2>Mails</h2>
        <Link className="btn btn-sm btn-primary" to="/mails/creer">
          Ecrire un nouveau mail
        </Link>
      </div>
      <div className="d-flex flex-column ">
        <div className="mt-3 bg-white p-4 row">
          <h4 className="mb-4 col-12">Mails envoyés</h4>
          <div className="col-12">
            <h5>Filtres : </h5>
            <div className="mb-4 row">
              <div className="col-12 col-md-8 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez l'object ou le contenu du mail"
                />{" "}
              </div>
              <div className="col-12 col-md-4 mt-3 mt-md-0 pl-md-2">
                <input
                  type="date"
                  className="form-control "
                  placeholder="Entrez l'object ou le contenu du mail"
                />
              </div>
            </div>
          </div>
          <hr className="col-12" />
          <div
            style={{ maxHeight: "400px" }}
            className="col-4 border-right overflow-auto"
          >
            {[0, 1, 2, 3, 4, 5, 6].map((item, index) => (
              <div
                style={{ transition: "0.2s", cursor: "pointer" }}
                onClick={() => setMailSelected(index)}
                className={`border-bottom  mb-2 ${
                  mailSelected === index && "border-primary pl-2"
                }`}
              >
                <p className="text-multiline-ellipsis">{fakeMail}</p>
                <p style={{ fontSize: "12px" }}>
                  Envoyé le 14/05/2024 par Coco
                </p>
              </div>
            ))}
          </div>
          <div className="col-8 p-4">
            <p>
              Envoyé le 14/05/2024 par{" "}
              <span
                onClick={() => handleNavigateToClient(0)}
                className="text-primary"
              >
                {clientsData && clientsData[0].email}
              </span>{" "}
            </p>
            <p>
              Destinataires :{" "}
              {clientsData &&
                clientsData.slice(0, 4).map((item, index) => (
                  <span>
                    <span
                      onClick={() => handleNavigateToClient(index)}
                      className="text-primary"
                      key={index}
                    >
                      {item.email}
                    </span>
                    <span> , </span>
                  </span>
                ))}
              <span
                id="modalOpener"
                type="button"
                data-toggle="modal"
                data-target="#modalPoll"
                className="text-primary"
              >
                Voir tout
              </span>
              <div
                className="modal fade"
                id="modalPoll"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                data-backdrop="true"
                aria-modal="true"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-notify modal-primary"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header bg-primary text-white">
                      <h5 className="modal-title">
                        Détails de l'établissement
                      </h5>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="btnCloseModalPoll"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                        {clientsData &&
                          clientsData.map((item, index) => (
                            <span>
                              {" "}
                              <span
                                onClick={() =>
                                  handleNavigateToClient(index, true)
                                }
                                className="text-primary btn-link"
                                to="#"
                              >
                                {item.email}
                              </span>
                            </span>
                          ))}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        data-dismiss="modal"
                      >
                        Fermer
                      </button>
                      <button type="button" className="btn btn-sm btn-primary">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </p>
            <h5>Object</h5>
            <p>{fakeMail}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
