import React, { useContext, useState, useMemo } from "react";
import { Context } from "../../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { checkPermission } from "../../../utils/checkPermission";
import authApi from "../../../services/api/authApi";

export default function HotelsGroups() {
  const [jwtUser] = useState(authApi.setup());

  const { fullData, setEtablissementSelected, groupsData, clientsData } =
    useContext(Context);

  const establishmentNumberFilter = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5 ou plus", value: "5" },
  ];

  const navigate = useNavigate();

  const handleNavigate = (url, item) => {
    // Permet de naviguer vers la page établissement en sélectionnant d'office le bon établissment ( reste en memoire grace au useContext)
    setEtablissementSelected(item);
    navigate(url);
  };

  const [managerSearch, setManagerSearch] = useState("");
  const [establishmentsNumber, setEstablishmentsNumber] = useState("all");

  const filteredGroups = useMemo(() => {
    // Permet de filter les groupes en fonction du nom ou prenom du manager, du nombre d'etablissements
    if (!groupsData || !clientsData) return [];

    return groupsData.filter((group) => {
      const proprietary = clientsData.find(
        (client) => client["@id"] === group.manager
      );
      const fullName =
        `${proprietary.firstname} ${proprietary.lastname}`.toLowerCase();
      const managerSearchLower = managerSearch.toLowerCase();
      const matchesManager =
        !managerSearch || fullName.includes(managerSearchLower);

      const establishmentCount = group.establishment.length;
      const matchesEstablishmentNumber =
        establishmentsNumber === "all" ||
        (establishmentsNumber === "5" && establishmentCount >= 5) ||
        establishmentCount.toString() === establishmentsNumber;

      return matchesManager && matchesEstablishmentNumber;
    });
  }, [managerSearch, establishmentsNumber, groupsData, clientsData]);

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="d-flex flex-column flex-md-row justify-content-md-between align-content-md-center mb-4">
        <h4>Liste des groupes d'hôtel :</h4>
        {checkPermission(jwtUser.roles, "admin") && (
          <Link
            to="/groupes/creer"
            style={{ width: "fit-content" }}
            className="btn btn-sm btn-primary"
          >
            Créer un nouveau groupe
          </Link>
        )}
      </div>
      <div className="row mb-3">
        <div className="form-group col-12 col-md-6">
          <label htmlFor="name">Nom du manager :</label>
          <input
            placeholder="Nom du manager"
            type="search"
            className="form-control"
            id="name"
            value={managerSearch}
            onChange={(e) => setManagerSearch(e.target.value)}
          />
        </div>
        <div className="form-group col-6 col-md-3">
          <label htmlFor="category">Nombre d'établissements :</label>
          <select
            onChange={(e) => setEstablishmentsNumber(e.target.value)}
            value={establishmentsNumber}
            className="form-control"
            id="category"
          >
            <option value="all">Tout</option>
            {establishmentNumberFilter.map((number, index) => (
              <option key={index} value={number.value}>
                {number.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row px-2">
        {filteredGroups.map((group, index) => {
          const proprietary = clientsData.find(
            (client) => client["@id"] === group.manager
          );
          return (
            <div className="col-12 col-sm-6 col-md-4 p-2" key={index}>
              <div className="card p-3">
                <img
                  style={{ maxHeight: "170px" }}
                  className=""
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fblog.artonemfg.com%2Fhubfs%2FArlo.jpg&f=1&nofb=1&ipt=7e903f86dd088605443b14af9f5f514c6f0404d2917bff417b7a8a17dcbdce7d&ipo=images"
                  alt=""
                />
                <h5 className="mt-4">
                  Etablissements de{" "}
                  {proprietary.firstname + " " + proprietary.lastname}
                </h5>
                <ul className="d-flex flex-column">
                  {group.establishment.map((establishmentOfUser, index) => {
                    const establishmentsFounded = fullData.find(
                      (establishment) =>
                        establishment["@id"] === establishmentOfUser
                    );
                    return (
                      <button
                        key={index}
                        className="btn btn-sm mx-0 w-100"
                        onClick={() => {
                          handleNavigate(
                            `/etablissements`,
                            establishmentsFounded["@id"]
                          );
                        }}
                      >
                        {establishmentsFounded.name}
                      </button>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
