import React from "react";
import { useParams } from "react-router-dom";
import NotFundView from "../NotFundView";
import CreateClient from "../../components/create/CreateClient";
import CreateEstablishment from "../../components/create/CreateEstablishment";
import CreateLocker from "../../components/create/CreateLocker";
import CreateProduct from "../../components/create/CreateProduct";
import CreateRoom from "../../components/create/CreateRoom";
import CreateMail from "../../components/create/CreateMail";

export default function Create() {
  const params = useParams();

  // exemple d'url : /:category/créer

  return (
    <div className="container-fluid d-flex flex-column">
      {params.category === "clients" && <CreateClient />}
      {params.category === "etablissements" && <CreateEstablishment />}
      {params.category === "chambres" && <CreateRoom />}
      {params.category === "lockers" && <CreateLocker />}
      {params.category === "produits" && <CreateProduct />}
      {params.category === "mails" && <CreateMail />}
      {![
        "clients",
        "etablissements",
        "chambres",
        "lockers",
        "produits",
        "mails",
      ].includes(params.category) && <NotFundView />}
    </div>
  );
}
