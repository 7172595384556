import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { etablissements } from "../../../../data/data.json";
import { useNavigate } from "react-router-dom";
import ClientInfos from "../../../components/detailsWithModal/ClientInfos";
import { Context } from "../../../context/AppContext";
import FilterTable from "../../../components/FilterTable";
import { checkPermission } from "../../../utils/checkPermission";
import authApi from "../../../services/api/authApi";

export default function Clients() {
  const [jwtUser] = useState(authApi.setup());

  const [page, setPage] = useState(1);
  const [clientsFiltered, setClientsFiltered] = useState([]); // setter à envoyer au composant FilterTable
  const [searchFilter, setSearchFilter] = useState(""); // servira pour le composant FilterTable
  const [roleFilter, setRoleFilter] = useState("all"); // État pour le filtre "rôle"
  const [clientEtablissements, setClientEtablissments] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const { clientsData, clientSelected, setClientSelected } =
    useContext(Context);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (clientSelected) {
      const foundEtablissements = etablissements.filter(
        (etablissement) => etablissement.proprietaire_id === clientSelected.id
      );
      setClientEtablissments(foundEtablissements);
    }
  }, [clientSelected]);

  const navigate = useNavigate();
  const itemsPerPage = 20;

  // Liste des rôles possibles pour le filtre
  const rolesList = [
    { label: "Tous les rôles", value: "all" },
    { label: "Admin", value: "admin" },
    { label: "Utilisateur", value: "user" },
    { label: "Manager", value: "manager" },
  ];

  // Gestion du changement de filtre par rôle
  const handleRoleChange = (event) => {
    setRoleFilter(event.target.value);
  };

  // Filtrer les clients en fonction de la recherche et du rôle
  useEffect(() => {
    if (clientsData) {
      const filtered = clientsData.filter((client) => {
        const matchesSearch =
          searchFilter === "" ||
          client.firstname.toLowerCase().includes(searchFilter.toLowerCase()) ||
          client.lastname.toLowerCase().includes(searchFilter.toLowerCase()) ||
          client.email.toLowerCase().includes(searchFilter.toLowerCase());

        const matchesRole =
          roleFilter === "all" ||
          (client.user_type &&
            client.user_type.toLowerCase() === roleFilter.toLowerCase());

        return matchesSearch && matchesRole;
      });
      setClientsFiltered(filtered);
    }
  }, [searchFilter, roleFilter, clientsData]);

  return (
    clientsData && (
      <div className="container-fluid d-flex flex-column">
        <div className="d-flex flex-column">
          <div className="row">
            <div className="col-12 d-md-flex justify-content-between mb-4">
              <button
                id="modalOpenerClient"
                type="button"
                data-toggle="modal"
                className="d-none btn btn-sm btn-primary"
                data-target="#modalBottom"
                disabled={width < 768 ? false : true}
              ></button>
              <h4 className="mb-3 mb-md-0">Liste des utilisateurs :</h4>
              {checkPermission(jwtUser.roles, "admin") && (
                <Link to="/clients/creer" className="btn btn-sm btn-primary">
                  Ajouter un nouvel utilisateur
                </Link>
              )}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6 col-lg-8">
              <FilterTable
                placeholder="Entrez le prénom, le nom ou l'email de l'utilisateur"
                data={clientsData}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                setterDataFiltered={setClientsFiltered}
                elementsToIncludesInSearch={[
                  { name: "id", type: "string" },
                  { name: "firstname", type: "string" },
                  { name: "lastname", type: "string" },
                  { name: "email", type: "string" },
                ]}
              />
            </div>
            <div className="col-6 col-lg-4">
              <label htmlFor="roleFilter">Filtrer par rôle :</label>
              <select
                id="roleFilter"
                className="form-control"
                value={roleFilter}
                onChange={handleRoleChange}
              >
                {rolesList.map((role, index) => (
                  <option key={index} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className=" overflow-hidden">
            <div className="row">
              <div className="col-12 col-md-4 table-responsive overflow-auto">
                <table className="table table-bordered table-hover position-relative">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Rôle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientsFiltered
                      .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                      .map((client, index) => (
                        <tr
                          className={`${
                            clientSelected === index && "bg-primary text-white"
                          }`}
                          style={{ cursor: "pointer" }}
                          {...(width < 768 && {
                            "data-toggle": "modal",
                            "data-target": "#modalBottom",
                          })}
                          onClick={() => {
                            setClientSelected(index);
                          }}
                          key={index}
                        >
                          <td>{client.email}</td>
                          <td>{client.lastname}</td>
                          <td>
                            {client.user_type.toLowerCase() || "Non défini"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="d-none d-md-block card col-8">
                <ClientInfos
                  clientSelected={clientSelected}
                  clientEtablissements={clientEtablissements}
                />
              </div>
            </div>
          </div>
          <div
            className="modal fade bottom"
            id="modalBottom"
            tabIndex="-1"
            aria-labelledby="modalBottomLabel"
            data-backdrop="true"
            aria-modal="true"
            role="dialog"
          >
            <div
              className="modal-dialog modal-bottom modal-notify modal-primary"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <p className="heading lead">Détails du client</p>
                  <button
                    style={{ fontSize: "12px" }}
                    type="button"
                    className="close btn btn-sm btn-primary"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="btnCloseModalPoll"
                  >
                    <span aria-hidden="true" className="white-text">
                      ×
                    </span>
                  </button>
                </div>
                <ClientInfos />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
