import React from "react";
import LoginForm from "../components/form_components/LoginForm";
import AuthApi from "../services/api/authApi";

const LoginView = () => {
  // AuthApi.logout();

  const currentYear = new Date().getFullYear();

  return (
    <div className="container" style={{ marginTop: "3%" }}>
      <div className="row">
        <div
          className="col-lg-12 text-center mb-4"
          style={{ fontSize: "2rem" }}
        >
          <img
            src={require("../../styles/img/resources/cube.png")}
            width="60px"
            alt="logo"
          />
          <span className="dark-grey-text font-weight-bolder">
            Guest<span style={{ color: "#5fb2ee" }}>Care</span>
            <sup>
              <sup style={{ fontSize: "0.6rem" }}>Admin</sup>
            </sup>
          </span>
        </div>
        <div className="col-lg-4" />
        <div className="col-lg-4">
          <LoginForm />
        </div>
        <div className="col-lg-4" />
        <div
          className="col-lg-12 text-center mt-3 p-3"
          style={{ fontSize: "0.7rem" }}
        >
          &copy; 2022 - {currentYear}{" "}
          <a href="https://fairytalevending.com" target="_blank">
            <i>GuestCare</i>
          </a>{" "}
          - Tous droits réservés.
          <br />
          FairyTaleVending &copy; et GuestCare &copy; sont des marques déposées.
        </div>
      </div>
    </div>
  );
};

export default LoginView;
