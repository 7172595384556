import axios from "axios";

export const fetchAllData = async (url, setData) => {
  try {
    const response = await axios.get(url);
    setData(response.data["hydra:member"]);
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
  }
};
