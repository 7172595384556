import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/AppContext";
import ChartWithSelect from "../charts/ChartWithSelect";
import authApi from "../../services/api/authApi";
import { toast } from "react-toastify";

export default function ClientInfos() {
  const [editMode, setEditMode] = useState(false);
  const {
    setEtablissementSelected,
    clientsData,
    clientSelected,
    refreshData,
    setRefreshData,
  } = useContext(Context);

  // Permet d'afficher les valeurs initials en fonction de si clientsData avec l'index stocké dans le usecontext contient les valeurs des champs suivants
  const initialValues = {
    lastname: clientsData[clientSelected]?.lastname || "",
    firstname: clientsData[clientSelected]?.firstname || "",
    email: clientsData[clientSelected]?.email || "",
  };

  const [inputsValue, setInputsValue] = useState(initialValues);
  const [elementBeingEdited, setElementBeingEdited] = useState();

  useEffect(() => {
    // initialise les inputs avec les valeur de initialValues au demarrage et à chaque fois que le client selectionné change
    setInputsValue(initialValues);
  }, [clientSelected]);

  const navigate = useNavigate();

  const handleNavigate = (url, item) => {
    // Permet de naviguer et de selectionner l'etablissement
    setEtablissementSelected(item);
    document.getElementById("btnCloseModalPoll").click();
    navigate(url);
  };

  const handleInputChange = (e) => {
    // Met à jour l'input
    const { name, value } = e.target;
    setInputsValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setInputsValue(initialValues);
    setEditMode(false);
  };

  const handleSaveClick = () => {
    handleUpdateUser();
    setEditMode(false);
  };

  const handleDeleteUser = async (userUri) => {
    // Met à jour l'input
    // Récupere l'id du user à partir de son uri
    const parts = userUri.split("/");
    const userId = parts[parts.length - 1];
    const response = await authApi.deleteUser(userId);
    if (!response) {
      toast.success("Utilisateur supprimé !");
      setRefreshData(!refreshData);
    } else toast.success("Une erreur est survenue !");
  };

  const handleUpdateUser = async () => {
    // Récupere l'id du user à partir de son uri
    const parts = clientsData[clientSelected]["@id"].split("/");
    const userId = parts[parts.length - 1];

    const response = await authApi.updateUser(userId, inputsValue);
    console.log(response);
    if (response?.email) {
      toast.success("Utilisateur modifié !");
      setRefreshData(!refreshData);
    } else toast.success("Une erreur est survenue !");
  };

  return clientSelected !== null && clientsData ? (
    <div className="card-body py-4">
      <div className="d-md-flex justify-content-between mb-4">
        <h5 className=" mb-3 mb-md-0 ">Détails du client : </h5>
        {editMode ? (
          <div
            style={{ flexWrap: "nowrap", gap: "10px" }}
            className="d-flex align-items-center"
          >
            <button
              onClick={handleCancelClick}
              className="btn btn-sm btn-action"
            >
              Annuler
            </button>{" "}
            <button
              onClick={handleSaveClick}
              className="btn btn-sm btn-primary"
            >
              Enregistrer
            </button>
          </div>
        ) : (
          <button className="btn btn-sm btn-primary" onClick={handleEditClick}>
            Modifier
          </button>
        )}
      </div>{" "}
      <div className="form-group">
        <label>Nom</label>
        <input
          type="text"
          name="lastname"
          className="form-control"
          value={inputsValue.lastname}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Prénom</label>
        <input
          type="text"
          name="firstname"
          className="form-control"
          value={inputsValue.firstname}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          name="email"
          className="form-control"
          value={inputsValue.email}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Créé le</label>
        <input
          type="text"
          name="created_at"
          className="form-control"
          value={clientsData[clientSelected]?.created_at || ""}
          readOnly={true}
        />
      </div>
      <div className="mt-4" id="accordionClientEtablissements">
        <div className="card">
          <button
            className="btn btn-link"
            data-toggle="collapse"
            data-target="#collapseClientEtablissements"
            aria-expanded="true"
            aria-controls="collapseClientEtablissements"
          >
            {clientsData[clientSelected]?.hotelGroup?.establishment?.length > 0
              ? "Liste des établissements"
              : "Aucun établissement associé"}
          </button>

          <div
            id="collapseClientEtablissements"
            className="collapse"
            aria-labelledby="headingClientEtablissements"
            data-parent="#accordionClientEtablissements"
          >
            {clientsData[clientSelected]?.hotelGroup &&
              clientsData[clientSelected].hotelGroup.establishment.map(
                (item, index) => (
                  <div
                    key={index}
                    style={{ borderTop: index !== 0 && "1px solid grey" }}
                    className="card-body"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p className="m-0 mb-3">{item.name}</p>
                        <p className="m-0">{item.city}</p>
                      </div>
                      <button
                        className="text-primary bg-transparent border-0 "
                        onClick={() =>
                          handleNavigate(`/etablissements`, item["@id"])
                        }
                      >
                        Plus d'informations
                      </button>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <ChartWithSelect />
      </div>
      <button
        onClick={() => handleDeleteUser(clientsData[clientSelected]["@id"])}
        className="btn-sm btn-danger float-right mb-3 mt-4"
      >
        Supprimer cet utilisateur
      </button>
    </div>
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <p>Aucun client sélectionné</p>
    </div>
  );
}
