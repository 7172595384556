import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const fetchCustomProduct = async (id) => {
  let response;

  try {
    if (id) {
      response = await axios.get(
        `${API_URL}api/guestcare_ut_custom_products/${id}`
      );
    } else {
      response = await axios.get(`${API_URL}api/guestcare_ut_custom_products`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching custom product:", error);
    throw error;
  }
};

const fetchFtvProduct = async (id) => {
  let response;

  try {
    if (id) {
      response = await axios.get(`${API_URL}api/guestcare_ut_products/${id}`);
    } else {
      response = await axios.get(`${API_URL}api/guestcare_ut_products`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching custom product:", error);
    throw error;
  }
};

const createFtvProduct = async (data) => {
  const response = await axios.post(
    `${API_URL}api/guestcare_ut_products`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Optionnel, axios peut le deviner
      },
    }
  );

  return response;
};

const create = async (data) => {
  const response = await axios.post(
    `${API_URL}guestcare_ut_custom_products`,
    data
  );

  return response;
};

const update = async (data, productId) => {
  const response = await axios.put(
    `${API_URL}api/guestcare_ut_products/${productId}`,
    data
  );

  return response;
};

const deleteProduct = async (productId) => {
  const response = await axios.delete(
    `${API_URL}api/guestcare_ut_products/${productId}`
  );

  return response;
};

export default {
  fetchCustomProduct,
  fetchFtvProduct,
  createFtvProduct,
  create,
  deleteProduct,
  update,
};
