export const factures = [
  {
    numero: "F202405061",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie premium",
        description:
          "Inclut draps en coton égyptien, couette en duvet et oreillers hypoallergéniques.",
        quantite: 10,
        prix_unitaire: 150,
        total: 1500,
      },
      {
        nom: "Articles de toilette de luxe",
        description:
          "Ensemble de produits de bain haut de gamme, comprenant shampooing, revitalisant, gel douche et lotion pour le corps.",
        quantite: 20,
        prix_unitaire: 20,
        total: 400,
      },
      {
        nom: "Machine à café professionnelle",
        description:
          "Machine à café espresso avec moulin à grains intégré et mousseur à lait automatique.",
        quantite: 2,
        prix_unitaire: 800,
        total: 1600,
      },
    ],
    total_HT: 3500,
    TVA: 700,
    total_TTC: 4200,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
  {
    numero: "F202405062",
    date: "2024-05-06",
    destinataire: {
      nom: "Hôtel BelleVue",
      adresse: "123 Rue des Hôteliers, Ville, Pays",
      email: "contact@hotelbellevue.com",
      telephone: "+1234567890",
    },
    produits: [
      {
        nom: "Ensemble de literie standard",
        description: "Inclut draps en coton, couverture et oreillers.",
        quantite: 8,
        prix_unitaire: 100,
        total: 800,
      },
      {
        nom: "Articles de toilette basiques",
        description: "Ensemble de produits de bain essentiels.",
        quantite: 15,
        prix_unitaire: 10,
        total: 150,
      },
      {
        nom: "Machine à café standard",
        description: "Machine à café avec carafe en verre.",
        quantite: 3,
        prix_unitaire: 200,
        total: 600,
      },
    ],
    total_HT: 1550,
    TVA: 310,
    total_TTC: 1860,
  },
];
