import React, { useState } from "react";
import TimeoutService from "../../services/common/timeoutService";
import AuthApi from "../../services/api/authApi";

const LoginForm = () => {
  const [error, setError] = useState("");

  const [waiting, setWaiting] = useState(false);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setWaiting(true);
      await AuthApi.authenticate(credentials);
      setError("");
      TimeoutService.redirect_on_event_checking("/", 1000);
      window.location.href = "/";
      window.location.reload();
    } catch (error) {
      setWaiting(false);
      setError("⚠️ Identifiants invalides !");
      TimeoutService.hide_on_event_checking(setError, "", 3000);
    }
  };

  return (
    <div className="card card-cascade">
      <div className="view view-cascade overlay">
        <img
          className="card-img-top"
          src={require("../../../styles/img/resources/cc.jpg")}
          alt="Card image cap"
        />
        <div>
          <div className="mask rgba-white-slight"></div>
        </div>
      </div>

      {error ? <div className="text-center red-text my-2">{error}</div> : <></>}
      {waiting && (
        <div className="text-center">
          <i className="fas fa-spinner fa-pulse text-primary" />
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="card-body card-body-cascade text-center">
          <h4 className="card-title">
            <strong>Tableau de bord</strong>
          </h4>
          <div className="md-form input-with-pre-icon">
            <i className="fas fa-user input-prefix text-black-50"></i>
            <input
              className="form-control form-control-sm"
              name="username"
              id="username"
              value={credentials.username}
              type="email"
              placeholder="Email"
              onChange={handleChange}
              required
            />
            <label htmlFor="prefixInside" className="active">
              Login
            </label>
          </div>
          <div className="md-form input-with-pre-icon">
            <i className="fas fa-lock input-prefix text-black-50 "></i>
            <input
              className="form-control form-control-sm"
              name="password"
              id="password"
              value={credentials.password}
              type="password"
              // placeholder="8 caractères minimum"
              //   minLength={8}
              onChange={handleChange}
              required
            />
            <label htmlFor="prefixInside" className="active">
              Mot de passe
            </label>
          </div>
          <div className="pt-3 text-center">
            <button className="btn btn-sm btn-primary">Connexion</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
