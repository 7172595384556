import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//// Créer l'entite correspondante à la navigation avec les roles
const navElements = [
  {
    category: "Tableau de bord",
    href: "/",
    links: [],
    roleAuthorized: "all",
  },
  {
    category: "Ressources",
    roleAuthorized: "all",
    links: [
      {
        name: "Groupe d'hôtels",
        href: "/groupes",
        roleAuthorized: "support",
      },
      {
        name: "Utilisateurs",
        href: "/clients",
        roleAuthorized: "support",
      },
      {
        name: "Etablissement",
        href: "/etablissements",
        roleAuthorized: "support",
      },
      {
        name: "Chambres",
        href: "/chambres",
        roleAuthorized: "support",
      },
      {
        name: "Lockers",
        href: "/lockers",
        roleAuthorized: "support",
      },
      {
        name: "Produits",
        href: "/produits",
        roleAuthorized: "support",
      },
    ],
  },
  {
    category: "Comptabilité",
    roleAuthorized: "none",
    links: [
      {
        name: "Commandes",
        href: "/commandes",
        roleAuthorized: "none",
      },
      {
        name: "Factures",
        href: "/factures",
        roleAuthorized: "none",
      },
    ],
  },
  {
    category: "Global",
    roleAuthorized: "support",
    links: [
      {
        name: "Paiements",
        href: "/paiements",
        roleAuthorized: "none",
      },
      {
        name: "Paramètres",
        href: "/parametres",
        roleAuthorized: "none",
      },
      {
        name: "Mails",
        href: "/mails",
        roleAuthorized: "support",
      },
    ],
  },
];

const listNotifsPossibles = [
  {
    message: "Etablissement créé : ",
    alertLevel: 1,
    data: "",
    isAlreadySeen: false,
  },
  {
    message: "Client créé : ",
    alertLevel: 1,
    data: "",
    isAlreadySeen: false,
  },
  {
    message: "Chambres créée : ",
    alertLevel: 1,
    data: "",
    isAlreadySeen: false,
  },
  {
    message: "Nouveau message reçu : ",
    alertLevel: 2,
    data: "",
    isAlreadySeen: false,
  },
  {
    message: "Locker sans signal : ",
    alertLevel: 2,
    data: "",
    isAlreadySeen: false,
  },
  {
    message: "Connexion à votre depuis un nouvel appareil: ",
    alertLevel: 3,
    data: "",
    isAlreadySeen: false,
  },
];

import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/AppContext";
import { checkPermission } from "../../utils/checkPermission";
import authApi from "../../services/api/authApi";

export default function NavbarLateral({ sidebarOpen }) {
  const [jwtUser] = useState(authApi.setup());

  const navigate = useNavigate();

  ////////////// Notifications zone //////////////////////////////
  const { listNotifications, setListNotifications } = useContext(Context);

  const notify = () => {
    const randomIndex = Math.floor(Math.random() * listNotifsPossibles.length);

    const randomLocker = listNotifsPossibles[randomIndex];
    const newListNotifications = listNotifications.concat(randomLocker);
    setListNotifications(newListNotifications);
  };

  useEffect(() => {
    if (listNotifications && listNotifications.length > 0) {
      const latestUnseenNotification = listNotifications
        .slice()
        .reverse()
        .find((notification) => !notification.isAlreadySeen);

      if (latestUnseenNotification) {
        if (latestUnseenNotification.alertLevel === 1) {
          toast.info(`${latestUnseenNotification.message}`);
        } else if (latestUnseenNotification.alertLevel === 2) {
          toast.warning(`${latestUnseenNotification.message}`);
        } else if (latestUnseenNotification.alertLevel === 3) {
          toast.error(`${latestUnseenNotification.message}`);
        }

        const updatedNotifications = listNotifications.map((notification) =>
          notification === latestUnseenNotification
            ? { ...notification, isAlreadySeen: true }
            : notification
        );

        setListNotifications(updatedNotifications);
      }
    }
  }, [listNotifications]);
  /////////////////////////////////////////////////////////

  return (
    <div
      id="mySidebar"
      className={`sidebar bg-white ${sidebarOpen ? "sidebar-open" : ""}`}
    >
      <div className="d-flex flex-column justify-content-between py-5 px-3">
        <div id="accordionLateralNav">
          {navElements.map((elem, index) => {
            console.log(jwtUser.roles);
            console.log(elem.roleAuthorized);
            // Vérifier si l'élément de navigation principal doit être affiché
            console.log(checkPermission(jwtUser.roles, elem.roleAuthorized));
            if (
              elem.roleAuthorized !== "all" &&
              elem.roleAuthorized &&
              !checkPermission(jwtUser.roles, elem.roleAuthorized)
            ) {
              return null; // Ne pas afficher cet élément principal
            }

            if (elem.links.length < 1) {
              return (
                <Link
                  key={index}
                  to={elem.href}
                  style={{ fontSize: "14px", display: "block" }}
                  className={`bg-transparent text-uppercase pl-0 mb-4 position-relative border-0 text-left ${
                    location.pathname === elem.href ? "font-weight-bold" : ""
                  }`}
                >
                  {elem.category}
                </Link>
              );
            } else {
              return (
                <div className="mb-4" key={index}>
                  <div>
                    <div id={`heading${index}`}>
                      <h5 className="mb-0">
                        <button
                          style={{ fontSize: "14px", flexWrap: "nowrap" }}
                          className="text-uppercase d-flex align-items-center bg-transparent border-0 collapsed"
                          data-toggle="collapse"
                          data-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                        >
                          {elem.category}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            style={{
                              width: "15px",
                              height: "15px",
                              transition: "transform 0.3s",
                              transform: "rotate(180deg)",
                              marginLeft: "10px",
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m4.5 15.75 7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        </button>
                      </h5>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className={`collapse ${
                        elem.links.some((link) =>
                          location.pathname.startsWith(link.href)
                        )
                          ? "show"
                          : ""
                      }`}
                      aria-labelledby={`heading${index}`}
                      data-parent="#accordionLateralNav"
                    >
                      <div>
                        <ul className="list-group pl-3 mt-2">
                          {elem.links.map((link, indexLink) => {
                            // Vérifier si le lien doit être affiché
                            if (
                              link.roleAuthorized &&
                              !checkPermission(
                                jwtUser.roles,
                                link.roleAuthorized
                              )
                            ) {
                              return null; // Ne pas afficher ce lien
                            }

                            return (
                              <li key={indexLink}>
                                <Link
                                  className={`d-flex align-items-center ${
                                    location.pathname.startsWith(link.href)
                                      ? "font-weight-bold"
                                      : ""
                                  }`}
                                  style={{ flexWrap: "nowrap" }}
                                  to={link.href}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                                    />
                                  </svg>
                                  <span>{link.name}</span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>

        <button className="mt-4" onClick={notify}>
          Notification
        </button>
        <ToastContainer onClick={() => navigate("/notifications")} />
      </div>
    </div>
  );
}
