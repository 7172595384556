import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const create = async (data) => {
  const response = await axios.post(
    `${API_URL}api/guestcare_ut_product_sub_categories`,
    data
  );
  console.log(response);

  return response;
};

const update = async (data, productSubCategoryId) => {
  const response = await axios.put(
    `${API_URL}api/guestcare_ut_product_sub_categories/${productSubCategoryId}`,
    data
  );
  console.log(response);

  return response;
};

const deleteSubCategory = async (productSubCategoryId) => {
  const response = await axios.delete(
    `${API_URL}api/guestcare_ut_product_sub_categories/${productSubCategoryId}`
  );
  console.log(response);

  return response;
};

export default {
  create,
  update,
  deleteSubCategory,
};
