import React, { useContext } from "react";
import { Context } from "../context/AppContext";

// Liste des exemples de notifs possibles
const listNotifsCheckbox = [
  "Client ajoutés",
  "Etablissments ajoutés",
  "Chambres ajoutés",
  "Alerte sur les lockers",
  "Messages reçus",
];

export default function Notifications() {
  const { listNotifications, setListNotifications } = useContext(Context);

  // Permet d'afficher le notifs de la plus recente à la moins récente
  const reversedNotifications =
    listNotifications && listNotifications
      ? [...listNotifications].reverse()
      : [];

  return (
    <div className="container-fluid">
      <h3 className="mb-4">Notifications</h3>
      <div className="row bg-white p-4">
        <div className="col-12 col-md-4 d-flex flex-column border-right ">
          <h5 className="mb-3">Notifications activées</h5>
          {listNotifsCheckbox.map((item, index) => (
            <div key={index} className="form-check px-0 mb-2">
              <input
                type="checkbox"
                className="form-check-input"
                id={`client-${index}`}
                // checked={selectedClients.includes(item.email)}
                // onChange={() => handleCheckboxChange(item.email)}
              />
              <label htmlFor={`client-${index}`} className="form-check-label">
                {item}
              </label>
            </div>
          ))}
          <button className="btn btn-sm btn-primary align-self-end mt-4">
            Sauvegarder
          </button>
        </div>
        <div className="col-12 col-md-8 mt-5 mt-md-0">
          {reversedNotifications &&
            reversedNotifications.map((item, index) => (
              <div
                key={index}
                className={`alert mb-3 ${
                  item.alertLevel === 1
                    ? "alert-primary"
                    : item.alertLevel === 2
                    ? "alert-warning"
                    : "alert-danger"
                }`}
                role="alert"
              >
                {item.message}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
