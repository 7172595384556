import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/AppContext";
import productsApi from "../../../services/api/productsApi";

export default function CustomProduct() {
  const [productData, setProductData] = useState({
    reference: "",
    label: "",
    price: 0,
    category: "",
    isActive: true,
    shortDescription: "",
    longDescription: "",
    proposedBy: "",
    subCategory: "",
    thumbnailUrl: "",
    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
  });

  const [errors, setErrors] = useState({});
  const { categoriesData, subCategoriesData, fullData } = useContext(Context);
  const [upload, setUpload] = useState({
    selectedFile: null,
  });

  const [uploadUrl, setUploadUrl] = useState([]);

  const onFileChange = (event) => {
    setUpload({ selectedFile: event.target.files[0] });
  };

  const handleSubmitProduct = (e) => {
    e.preventDefault();

    // Valider les champs
    const newErrors = {};
    if (!productData.reference.trim())
      newErrors.reference = "Référence est requise.";
    if (!productData.label.trim()) newErrors.label = "Nom est requis.";
    if (productData.price <= 0)
      newErrors.price = "Le prix doit être supérieur à zéro.";
    if (!productData.category.trim())
      newErrors.category = "Catégorie est requise.";
    if (!productData.shortDescription.trim())
      newErrors.shortDescription = "Description courte est requise.";
    if (!productData.longDescription.trim())
      newErrors.longDescription = "Description longue est requise.";
    if (!productData.proposedBy.trim())
      newErrors.proposedBy = "Proposé par est requis.";
    if (!productData.subCategory.trim())
      newErrors.subCategory = "Sous catégorie est requise.";
    if (!productData.thumbnailUrl.trim() && !upload.selectedFile)
      newErrors.thumbnailUrl = "L'image est requise.";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // formdata avec toutes les valeurs
      const formData = new FormData();
      formData.append("reference", productData.reference);
      formData.append("label", productData.label);
      formData.append("price", productData.price);
      formData.append("category", productData.category);
      formData.append("isActive", productData.isActive);
      formData.append("shortDescription", productData.shortDescription);
      formData.append("longDescription", productData.longDescription);
      formData.append("proposedBy", productData.proposedBy);
      formData.append("subCategory", productData.subCategory);
      formData.append("createdAt", productData.createdAt);
      formData.append("modifiedAt", productData.modifiedAt);

      if (upload.selectedFile) {
        formData.append("image", upload.selectedFile, upload.selectedFile.name);
      } else if (productData.thumbnailUrl) {
        formData.append("thumbnailUrl", productData.thumbnailUrl);
      }

      // CALL API FUNCTION
      productsApi
        .createProduct(formData)
        .then((response) => {
          console.log("Product created successfully", response.data);
        })
        .catch((error) => {
          console.error("Error creating product", error);
        });
    }
  };

  const handleUpdateData = (e) => {
    // Mise à jour des data en fonction du type de l'input
    const { id, value, type, checked } = e.target;
    const newData = { ...productData };

    if (type === "checkbox") {
      newData[id] = checked;
    } else if (type === "number") {
      newData[id] = parseFloat(value);
    } else {
      newData[id] = value;
    }

    setProductData(newData);
    setErrors({ ...errors, [id]: "" });
  };

  useEffect(() => {
    if (upload.selectedFile === null) return;
    const newImageUrl = [];
    newImageUrl.push(URL.createObjectURL(upload.selectedFile));
    setUploadUrl(newImageUrl);

    setProductData({ ...productData, thumbnailUrl: newImageUrl[0] });
  }, [upload.selectedFile]);

  return (
    <form
      onSubmit={handleSubmitProduct}
      className="needs-validation"
      noValidate
    >
      <div className="form-group">
        <label htmlFor="reference">Référence :</label>
        <input
          type="text"
          className={`form-control ${errors.reference ? "is-invalid" : ""}`}
          id="reference"
          placeholder="Entrez la référence"
          onChange={handleUpdateData}
          required
        />
        {errors.reference && (
          <div className="invalid-feedback">{errors.reference}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="label">Nom :</label>
        <input
          type="text"
          className={`form-control ${errors.label ? "is-invalid" : ""}`}
          id="label"
          placeholder="Entrez le nom du produit"
          onChange={handleUpdateData}
          required
        />
        {errors.label && <div className="invalid-feedback">{errors.label}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="price">Prix :</label>
        <input
          type="number"
          className={`form-control ${errors.price ? "is-invalid" : ""}`}
          id="price"
          placeholder="Entrez le prix"
          onChange={handleUpdateData}
          required
        />
        {errors.price && <div className="invalid-feedback">{errors.price}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="category">Catégorie :</label>
        <select
          className={`form-control ${errors.category ? "is-invalid" : ""}`}
          id="category"
          onChange={handleUpdateData}
          required
        >
          <option value="" disabled selected>
            Choisissez une catégorie
          </option>
          {categoriesData &&
            categoriesData.map((category, index) => (
              <option key={index} value={category["@id"]}>
                {category.label}
              </option>
            ))}
          <option value="new">Créer une nouvelle catégorie</option>
        </select>
        {errors.category && (
          <div className="invalid-feedback">{errors.category}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="subCategory">Sous catégorie :</label>
        <select
          className={`form-control ${errors.subCategory ? "is-invalid" : ""}`}
          id="subCategory"
          onChange={handleUpdateData}
          required
        >
          <option value="" disabled selected>
            Choisissez une sous catégorie
          </option>
          {subCategoriesData &&
            subCategoriesData.map((item, index) => (
              <option key={index} value={item["@id"]}>
                {item.label}
              </option>
            ))}
          <option value="new">Créer une nouvelle sous catégorie</option>
        </select>
        {errors.subCategory && (
          <div className="invalid-feedback">{errors.subCategory}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="shortDescription">Description courte :</label>
        <textarea
          className={`form-control ${
            errors.shortDescription ? "is-invalid" : ""
          }`}
          id="shortDescription"
          rows="2"
          onChange={handleUpdateData}
          required
        ></textarea>
        {errors.shortDescription && (
          <div className="invalid-feedback">{errors.shortDescription}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="longDescription">Description longue :</label>
        <textarea
          className={`form-control ${
            errors.longDescription ? "is-invalid" : ""
          }`}
          id="longDescription"
          rows="5"
          onChange={handleUpdateData}
          required
        ></textarea>
        {errors.longDescription && (
          <div className="invalid-feedback">{errors.longDescription}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="proposedBy">Proposé par :</label>
        <select
          className={`form-control ${errors.proposedBy ? "is-invalid" : ""}`}
          id="proposedBy"
          onChange={handleUpdateData}
          required
        >
          <option value="" disabled selected>
            Choisissez un établissement
          </option>
          {fullData &&
            fullData.map((establishment, indexEta) => (
              <option key={indexEta} value={establishment["@id"]}>
                {establishment.name}
              </option>
            ))}
        </select>
        {errors.proposedBy && (
          <div className="invalid-feedback">{errors.proposedBy}</div>
        )}
      </div>
      <div className="form-group custom-file my-3">
        <label className="custom-file-label" htmlFor="thumbnail">
          Choisissez une image :
        </label>
        <input
          type="file"
          className={`form-control-file custom-file-input ${
            errors.thumbnailUrl ? "is-invalid" : ""
          }`}
          id="thumbnail"
          accept="image/*"
          onChange={onFileChange}
          required
        />
        {errors.thumbnailUrl && (
          <div className="invalid-feedback">{errors.thumbnailUrl}</div>
        )}
      </div>
      {uploadUrl.length > 0 && (
        <div className="md-form">
          <img width="200px" src={uploadUrl} alt="Aperçu de l'image" />
          <br />
          {upload.selectedFile && upload.selectedFile.name}
        </div>
      )}
      <button type="submit" className="btn btn-primary">
        Créer le produit
      </button>
    </form>
  );
}
