import React, { useEffect, useState } from "react";
import NavbarLateral from "./NavbarLateral";
import Header from "./Header";

export default function Layout({ children }) {
  const [isLateralNavOpen, setIsLateralNavOpen] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleNav = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleOpenLateralNav = () => {
    setIsLateralNavOpen(!isLateralNavOpen);
  };

  useEffect(() => {
    if (window.innerWidth > 900) {
      setIsLateralNavOpen(true);
    }
  }, []);

  return (
    <div style={{ minHeight: "100vh" }} className=" d-flex flex-column">
      <Header
        handleOpenLateralNav={handleOpenLateralNav}
        toggleNav={toggleNav}
      />

      <div className="container-fluid d-flex flex-column flex-1 pl-0 pr-0">
        <div className="d-flex flex-column flex-lg-row flex-1">
          <NavbarLateral sidebarOpen={sidebarOpen} />

          <div
            style={{
              flex: 1,
              backgroundColor: "#F5F5F5",
            }}
            className="p-2 pt-5 p-lg-5 d-flex flex-col"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
