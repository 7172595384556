import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/AppContext";
import productsApi from "../../services/api/productsApi";
import { toast } from "react-toastify";

export default function Produit({ id }) {
  const {
    productsData,
    categoriesData,
    subCategoriesData,
    fullData,
    setRefreshData,
    refreshData,
  } = useContext(Context);
  const [productFound, setProductFound] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [inputsValue, setInputsValue] = useState({});

  useEffect(() => {
    // Mettre à jour le usestate productFound avec le produit qui correspond à l'id des params
    if (productsData) {
      const foundProduit = productsData.find((produit) => produit.id == id);
      if (foundProduit) {
        setProductFound(foundProduit);
        setInputsValue({
          id: foundProduit["@id"],
          reference: foundProduit.reference,
          label: foundProduit.label,
          short_description: foundProduit.short_description,
          long_description: foundProduit.long_description,
          price: foundProduit.price,
        });
      }
    }
    setIsLoading(false);
  }, [id, productsData, fullData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputsValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    // Réinitialisation des inputs avec les valeurs de productFound
    if (productFound) {
      setInputsValue({
        reference: productFound.reference,
        label: productFound.label,
        short_description: productFound.short_description,
        long_description: productFound.long_description,
        price: productFound.price,
      });
    }
    setEditMode(false);
  };

  const handleSaveClick = () => {
    handleUpdateProduct();
    setEditMode(false);
  };

  const handleDeleteProduct = async () => {
    // Récuperation de l'id depuis son uri
    const parts = inputsValue.id.split("/");
    const productId = parts[parts.length - 1];
    const response = await productsApi.deleteProduct(productId);
    if (!response) {
      setRefreshData(!refreshData);
      toast.success("Produit supprimé !");
    } else {
      toast.success("Une erreur est survenue !");
    }
  };

  const handleUpdateProduct = async () => {
    // Récuperation de l'id depuis son uri
    const parts = inputsValue.id.split("/");
    const productId = parts[parts.length - 1];
    const response = await productsApi.update(inputsValue, productId);
    if (response.status === 200) {
      toast.success("Produit modifié !");
      setRefreshData(!refreshData);
    } else toast.success("Une erreur est survenue !");
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <i className="fas fa-spinner fa-pulse text-primary" />
      </div>
    );
  }

  if (!productFound) {
    return <p>Aucun produit trouvé</p>;
  }

  return (
    productFound && (
      <div className="w-100">
        <div className="d-md-flex justify-content-between align-items-center mb-4 ">
          <h4 className=" mb-3 mb-md-0 ">Détails du produit : </h4>
          {editMode ? (
            <div>
              <button
                onClick={handleCancelClick}
                className="btn btn-sm btn-action"
              >
                Annuler
              </button>{" "}
              <button
                onClick={handleSaveClick}
                className="btn btn-sm btn-primary"
              >
                Enregistrer
              </button>
            </div>
          ) : (
            <button
              onClick={handleEditClick}
              className="btn btn-sm btn-primary"
            >
              Modifier
            </button>
          )}
        </div>
        <div className="card">
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">Image</label>
            <div>
              <img
                src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flelingedejules.fr%2Fwp-content%2Fuploads%2F4-serviettes-de-toilette-2-draps-de-douche-turquoise-blanc.jpg&f=1&nofb=1&ipt=93573e14d720ccb11ac31cc6158e0a4e30ab3bcbefea03b34a4538bd255d0647&ipo=images"
                alt="Produit"
                className="p-3 p-sm-0"
                style={{ width: "130px", height: "130px" }}
              />
            </div>
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">Référence</label>
            <input
              type="text"
              className="form-control px-0 col-12 col-sm-9 col-md-6 px-2"
              name="reference"
              value={inputsValue.reference}
              onChange={handleInputChange}
              readOnly={!editMode}
            />
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">Nom</label>
            <input
              type="text"
              className="form-control px-0 col-12 col-sm-9 col-md-6 px-2"
              name="label"
              value={inputsValue.label}
              onChange={handleInputChange}
              readOnly={!editMode}
            />
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">
              Description courte
            </label>
            <input
              type="text"
              className="form-control px-0 col-12 col-sm-9 col-md-6 px-2"
              name="short_description"
              value={inputsValue.short_description}
              onChange={handleInputChange}
              readOnly={!editMode}
            />
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">
              Description longue
            </label>
            <input
              type="text"
              className="form-control px-0 col-12 col-sm-9 col-md-6 px-2"
              name="long_description"
              value={inputsValue.long_description}
              onChange={handleInputChange}
              readOnly={!editMode}
            />
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">Prix</label>
            <input
              type="number"
              className="form-control px-0 col-12 col-sm-9 col-md-6 px-2"
              name="price"
              value={inputsValue.price}
              onChange={handleInputChange}
              readOnly={!editMode}
            />
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">Catégorie</label>
            <Link
              className="text-primary"
              to={`/produits?category=${
                categoriesData?.find((x) => x["@id"] === productFound.category)
                  ?.label
              }`}
            >
              {
                categoriesData?.find((x) => x["@id"] === productFound.category)
                  ?.label
              }
            </Link>
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">Sous catégorie</label>
            <Link
              className="text-primary"
              to={`/produits?category=${
                categoriesData?.find((x) => x["@id"] === productFound.category)
                  ?.label
              }&subcategory=${
                subCategoriesData?.find(
                  (x) => x["@id"] === productFound.sub_category
                )?.label
              }`}
            >
              {
                subCategoriesData?.find(
                  (x) => x["@id"] === productFound.sub_category
                )?.label
              }
            </Link>
          </div>
          <div
            className="p-3 mb-0 form-group row pl-0 pr-0 border-top"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <label className="col-12 col-sm-3 col-md-6">
              Détails du casier
            </label>
            <Link
              className="text-primary"
              to={`/lockers/${productFound.casier_id}`}
            >
              Afficher les détails du casier
            </Link>
          </div>
        </div>
        <button
          onClick={() => handleDeleteProduct()}
          className="btn-sm btn-danger float-right mb-3 mt-4"
        >
          Supprimer ce produit
        </button>
      </div>
    )
  );
}
