import React, { useContext, useState } from "react";
import { Context } from "../../context/AppContext";
import { Link } from "react-router-dom";
import productsCategoriesApi from "../../services/api/productsCategoriesApi";
import productsSubCategoriesApi from "../../services/api/productsSubCategoriesApi";
import { currentTime } from "../../utils/generateDate";
import { toast } from "react-toastify";
import { getIdFromUri } from "../../services/common/getIdFromUri";
import { checkPermission } from "../../utils/checkPermission";

export default function Catalogue({ jwtUser }) {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isInputCategory, setIsInputCategory] = useState(false);

  const [subCategoryCategorySelected, setSubCategoryCategorySelected] =
    useState("");
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [isInputSubCategory, setIsInputSubCategory] = useState(false);
  const { categoriesData, subCategoriesData, refreshData, setRefreshData } =
    useContext(Context);

  const handleCreateCategory = async () => {
    const dataToSend = {
      label: newCategoryName,
      createdAt: currentTime(),
      modifiedAt: currentTime(),
    };
    console.log(dataToSend);

    // CALL API
    const response = await productsCategoriesApi.create(dataToSend);
    if (response.status === 201) {
      toast.success(`Catégorie ${newCategoryName} créée`);
      setRefreshData(refreshData);
    } else toast.error("Une erreur est survenue");
  };

  const handleCreateSubCategory = async () => {
    const dataToSend = {
      label: newSubcategoryName,
      category: subCategoryCategorySelected,
      createdAt: currentTime(),
      modifiedAt: currentTime(),
    };

    console.log(dataToSend);
    // CALL API
    const response = await productsSubCategoriesApi.create(dataToSend);
    if (response.status === 201) {
      toast.success(`Sous catégorie ${newSubcategoryName} créée`);
      setRefreshData(refreshData);
    } else toast.error("Une erreur est survenue");
  };

  const handleDeleteCategory = async (uri) => {
    const id = await getIdFromUri(uri);
    const response = await productsCategoriesApi.deleteCategory(id);
    if (response.status === 204) toast.success("Categorie supprimée !");
    else toast.error("Une erreur est survenue");
  };

  const handleDeleteSubCategory = async (uri) => {
    const id = await getIdFromUri(uri);
    const response = await productsSubCategoriesApi.deleteSubCategory(id);
    if (response.status === 204) toast.success("Sous-catégorie supprimée !");
    else toast.error("Une erreur est survenue");
  };

  return (
    <div>
      <div className="mt-4 mb-4 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
        <h4 className="">Toutes les catégories</h4>
        {checkPermission(jwtUser.roles, "admin") ? (
          isInputCategory ? (
            <div>
              <button
                onClick={() => {
                  setNewCategoryName("");
                  setIsInputCategory(false);
                }}
                style={{ width: "fit-content" }}
                className="btn btn-sm btn-action"
              >
                Annuler
              </button>{" "}
              <button
                onClick={() => {
                  // CALL API
                  handleCreateCategory();
                  setNewCategoryName("");
                  setIsInputCategory(false);
                }}
                style={{ width: "fit-content" }}
                className="btn btn-sm btn-primary"
              >
                Créer
              </button>{" "}
            </div>
          ) : (
            <button
              onClick={() => setIsInputCategory(true)}
              style={{ width: "fit-content" }}
              className="btn btn-sm btn-primary"
            >
              Créer une nouvelle catégorie
            </button>
          )
        ) : null}
      </div>
      <ul className="list-group">
        {isInputCategory && (
          <li className="list-group-item">
            <input
              className=" form-control"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              type="text"
              placeholder="Nouvelle catégorie"
              id
            />
          </li>
        )}
        {categoriesData &&
          categoriesData.map((category, index) => (
            <li
              key={index}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              <span>
                {category.label} (
                <Link
                  className="text-primary"
                  to={`/produits?category=${category.label}`}
                >
                  {jwtUser && jwtUser.roles.includes("SUPPORT")
                    ? category.customProducts.length +
                      category.gcProducts.length
                    : category.gcProducts.length}{" "}
                  produits
                </Link>
                )
              </span>
              <button
                onClick={() => handleDeleteCategory(category["@id"])}
                style={{ width: "30px", height: "30px", cursor: "pointer" }}
                className="bg-danger border-0 rounded-circle text-white"
              >
                <i class="fa-solid fa-x" />
              </button>
            </li>
          ))}
      </ul>
      <div className="mt-5 mb-4 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
        <h4 className="">Toutes les sous-catégories</h4>
        {checkPermission(jwtUser.roles, "admin") ? (
          isInputSubCategory ? (
            <div>
              <button
                onClick={() => {
                  setNewSubcategoryName("");
                  setIsInputSubCategory(false);
                }}
                style={{ width: "fit-content" }}
                className="btn btn-sm btn-action"
              >
                Annuler
              </button>{" "}
              <button
                onClick={() => {
                  // CALL API
                  handleCreateSubCategory();
                  setNewSubcategoryName("");
                  setIsInputSubCategory(false);
                }}
                style={{ width: "fit-content" }}
                className="btn btn-sm btn-primary"
              >
                Créer
              </button>{" "}
            </div>
          ) : (
            <button
              onClick={() => setIsInputSubCategory(true)}
              style={{ width: "fit-content" }}
              className="btn btn-sm btn-primary"
            >
              Créer une nouvelle catégorie
            </button>
          )
        ) : null}
      </div>{" "}
      <ul className="list-group mt-3">
        {isInputSubCategory && (
          <li className="list-group-item d-flex align-items-center">
            <select
              onChange={(e) => setSubCategoryCategorySelected(e.target.value)}
              value={subCategoryCategorySelected}
              style={{ maxWidth: "300px" }}
              className="form-control"
              name=""
              id=""
            >
              <option value="" disabled>
                Choisissez une catégorie
              </option>
              {categoriesData.map((category, index) => (
                <option value={category["@id"]} key={index}>
                  {category.label}
                </option>
              ))}
            </select>
            <input
              className="ml-3 form-control"
              value={newSubcategoryName}
              onChange={(e) => setNewSubcategoryName(e.target.value)}
              type="text"
              placeholder="Nouvelle catégorie"
              id
            />
          </li>
        )}
        {subCategoriesData &&
          subCategoriesData.map((subcategory, indexItem) => {
            const categoryFound =
              categoriesData &&
              categoriesData.find(
                (category) => category["@id"] === subcategory.category
              );

            return (
              <li key={indexItem} className="list-group-item">
                <div className="d-flex justify-content-between align-items-lg-center">
                  <div className="row align-items-center w-100">
                    <span
                      style={{ fontSize: "12px", textTransform: "uppercase" }}
                      className="col-3 "
                    >
                      {categoryFound && categoryFound.label}
                    </span>
                    <span className="col-9">
                      {subcategory.label} (
                      <Link
                        className="text-primary"
                        to={`/produits?category=${categoryFound.label}&subcategory=${subcategory.label}`}
                      >
                        {jwtUser && jwtUser.roles.includes("SUPPORT")
                          ? subcategory.customProducts.length +
                            subcategory.gcProducts.length
                          : subcategory.gcProducts.length}{" "}
                        produits
                      </Link>
                      )
                    </span>
                  </div>
                  <button
                    onClick={() => handleDeleteSubCategory(subcategory["@id"])}
                    style={{ width: "30px", height: "30px", cursor: "pointer" }}
                    className="bg-danger border-0 rounded-circle text-white"
                  >
                    <i class="fa-solid fa-x" />
                  </button>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
