import React, { useState } from "react";
import { transactions } from "../../../data/transaction";
import PaginationTable from "../../components/PaginationTable";
import FilterTable from "../../components/FilterTable";

export default function TransactionsView() {
  const [page, setPage] = useState(1);
  const [dataFiltered, setDataFiltered] = useState([]); // setter à envoyer au composant FilterTable
  const [searchFilter, setSearchFilter] = useState(""); // servira pour le composant FilterTable

  const itemsPerPage = 20;

  return (
    <div className=" container-fluid">
      <div className="d-flex justify-content-between mb-4">
        <h4>Historique des transactions : </h4>
      </div>
      <FilterTable
        placeholder="Entrez la l'id de la transaction, la date, le client ou la référence du produit"
        data={transactions}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        setterDataFiltered={setDataFiltered}
        elementsToIncludesInSearch={[
          { name: "id", type: "string" },
          { name: "date", type: "string" },
          { name: "client", type: "string" },
          { name: "produit", type: "string" },
        ]}
      />
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Montant</th>
              <th scope="col">Date</th>
              <th scope="col">Statut</th>
              <th scope="col">Produit</th> <th scope="col">Client</th>{" "}
              <th scope="col">Actions</th>{" "}
            </tr>
          </thead>
          <tbody>
            {dataFiltered
              .slice(
                (page - 1) * itemsPerPage,
                page === 1 ? 20 : (page - 1) * itemsPerPage + itemsPerPage
              )
              .map((transaction, index) => (
                <tr key={index}>
                  <th scope="row">{transaction.id}</th>
                  <td>{transaction.montant}</td>
                  <td>{transaction.date}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.produit}</td>
                  <td>{transaction.client}</td>
                  <td>
                    <button className="btn btn-primary px-2 py-1">
                      Éditer
                    </button>{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <PaginationTable
          data={transactions}
          itemsPerPage={itemsPerPage}
          pageSetter={setPage}
          pageGetter={page}
        />
      </div>
    </div>
  );
}
