import React, { useState } from "react";
import Manager from "../../../components/hotelGroup/Manager";
import Establishments from "../../../components/hotelGroup/Establishments";
import Rooms from "../../../components/hotelGroup/Rooms";
import Staff from "../../../components/hotelGroup/Staff";
import { v4 as uuidv4 } from "uuid";
import { currentTime } from "../../../utils/generateDate";
import hotelsGroups from "../../../services/api/hotelsGroups";
import establishmentsApi from "../../../services/api/establishmentsApi";
import authApi from "../../../services/api/authApi";
import Lockers from "../../../components/hotelGroup/Lockers";
import { getIdFromUri } from "../../../services/common/getIdFromUri";
import { toast } from "react-toastify";
import { getTokenServiceApi } from "../../../services/api/getTokenServiceApi";

export default function CreateHotelGroup() {
  const steps = [
    {
      isOptionnel: false,
      list: ["Manager", "Etablissements"],
    },
    {
      isOptionnel: true,
      list: ["Personnel", "Chambres", "Lockers"],
    },
  ];

  // Initialisez stepSelected à une valeur qui correspond à l'un des éléments de la liste.
  const [stepSelected, setStepSelected] = useState("Manager");

  const [dataClient, setDataClient] = useState({
    // Ajout d'un id uuid pour permettre de relier les élement lors de l'envoie des datas

    id: uuidv4(),
    email: "m1@gmail.com",
    roles: ["MANAGER"],
    password: "azerty",
    firstname: "m1",
    lastname: "m1",
    // hotelGroup: [],
    userType: "manager",
    isActive: true,
    // correspondingEstablishment: [],
  });
  const [dataEtablissements, setDataEtablissement] = useState([
    // Ajout d'un id uuid pour permettre de relier les élement lors de l'envoie des datas
    {
      id: uuidv4(),
      name: "e1",
      zipcode: "e1",
      city: "e1",
      phone: "e1",
      fax: "e1",
      email: "e1@gmail.com",
      // hotelGroup: "",
      // customProducts: [],
      // rooms: [],
      // staff: "",
      // bookings: [],
      rating: 0,
      // establishmentStripeParameters: "",
      // gcApiKey: "e1",
      addressOne: "string",
      addressTwo: "string",
      isActive: true,
      createdAt: "",
      modifiedAt: "",
      etoken: "m23",
      subscriptionType: "string",
    },
  ]);
  const [dataRooms, setDataRooms] = useState([]);
  const [dataStaff, setDataStaff] = useState([]);
  const [dataLockers, setDataLockers] = useState([]);

  const [establishmentIdInDB, setEstablishmentIdInDB] = useState([]);

  const handleNavigationSteps = (direction) => {
    // Permet de naviguer dans les étape en fonction de la direction et de l'etape actuelle
    const allSteps = steps.flatMap((step) => step.list);
    const currentStepIndex = allSteps.indexOf(stepSelected);

    if (direction === "next" && currentStepIndex < allSteps.length - 1) {
      setStepSelected(allSteps[currentStepIndex + 1]);
      scrollToTop();
    }

    if (direction === "back" && currentStepIndex > 0) {
      setStepSelected(allSteps[currentStepIndex - 1]);
      scrollToTop();
    }
  };

  const handleValidateWithoutOptionnal = async () => {
    // Creation du groupe sans les étapes optionnelles
    console.log("Ajouter nouveau groupe");
    console.log("dataClient : ", dataClient);
    console.log("dataEtablissements : ", dataEtablissements);

    let clientId;
    let establishmentsId = [];

    try {
      // Créez le client
      try {
        clientId = await createClient();
        console.log("Client created with ID: ", clientId);
      } catch (error) {
        console.error("Error creating client: ", error);
        throw error; // Rejette l'erreur pour sortir du try global
      }

      // Créez les établissements
      try {
        for (let i in dataEtablissements) {
          console.log(dataEtablissements[i]);
          const etaId = await createEstablishment(dataEtablissements[i]);
          if (etaId) establishmentsId.push(etaId);
        }
        console.log("All establishments created with IDs: ", establishmentsId);
      } catch (error) {
        console.error("Error creating establishments: ", error);

        // Supprime le client créé
        if (clientId) await deleteClient(clientId);
        throw error; // Rejette l'erreur pour sortir du try global
      }

      // Créez le groupe d'hôtel
      try {
        const hotelGroup = await createHotelGroup(clientId, establishmentsId);
        console.log("Hotel group created: ", hotelGroup);
      } catch (error) {
        console.error("Error creating hotel group: ", error);
        // Supprime les établissements créés
        for (let etaId of establishmentsId) {
          await deleteEstablishment(etaId);
        }
        // Supprime le client créé
        if (clientId) await deleteClient(clientId);
        throw error; // Rejette l'erreur pour sortir du try global
      }
    } catch (error) {
      toast.error("Erreur lors de la création du groupe");
      console.error("Error during the creation process: ", error);
    }
  };

  const deleteClient = async (clientId) => {
    // Suppression du client
    const id = getIdFromUri(clientId);
    try {
      await authApi.deleteUser(id);
      console.log(`Client with ID ${id} deleted.`);
    } catch (error) {
      console.error(`Failed to delete client with ID ${id}:`, error);
    }
  };

  const deleteEstablishment = async (etaId) => {
    // Suppression de l'etablissement
    const id = getIdFromUri(etaId);
    try {
      await establishmentsApi.deleteEstablishment(id);
      console.log(`Establishment with ID ${id} deleted.`);
    } catch (error) {
      console.error(`Failed to delete establishment with ID ${id}:`, error);
    }
  };

  const createClient = async () => {
    // Creation du client
    const time = currentTime();
    const { id, ...clientDataWithoutId } = dataClient;

    const updatedClientData = {
      ...clientDataWithoutId,
      createdAt: time,
      modifiedAt: time,
    };

    const userCreated = await authApi.createUser(updatedClientData);
    console.log("userCreated ", userCreated);
    return userCreated["@id"];
  };

  const createHotelGroup = async (managerUri, establishmentsUri) => {
    // Creation du groupe d'hotel avec managerUri et establishmentsUri qui correspondent au client et aux établissements créés
    console.log("managerUri : ", managerUri);
    console.log("establishmentsUri : ", establishmentsUri);
    const hotelGroupCreated = await hotelsGroups.createGroup({
      manager: managerUri,
      establishment: establishmentsUri,
    });
    console.log("hotelGroupCreated ", hotelGroupCreated);
  };

  const createEstablishment = async (data) => {
    console.log("data : ", data);
    const token = await getTokenServiceApi("admin");
    const time = currentTime();
    const { id, ...establishmentDataWithoutId } = data;

    const updatedEstablishmentData = {
      ...establishmentDataWithoutId,
      createdAt: time,
      modifiedAt: time,
      gcApiKey: token,
      etoken: token,
    };
    console.log(updatedEstablishmentData);
    const establishmentCreated = await establishmentsApi.create(
      updatedEstablishmentData
    );
    setEstablishmentIdInDB([
      ...establishmentIdInDB,
      { id: id, idInDb: establishmentCreated["@id"] },
    ]);
    console.log({ id: id, idInDb: establishmentCreated["@id"] });
    return establishmentCreated["@id"];
  };

  const createStaffUser = async (data) => {
    const { id, ...staffUserWithId } = data;

    const findEstablishmentCorresponding = establishmentIdInDB.find(
      (item) => item.id === data.correspondingEstablishment
    );

    const updatedStaffUserData = {
      ...staffUserWithId,
      correspondingEstablishment: findEstablishmentCorresponding["@id"],
    };

    const establishmentCreated = await authApi.createUser(updatedStaffUserData);

    return establishmentCreated["@id"];
  };

  const createRoom = async (data) => {
    const { id, ...roomWithoutId } = data;

    const findEstablishmentCorresponding = establishmentIdInDB.find(
      (item) => item.id === data.correspondingEstablishment
    );

    const updatedRoomData = {
      ...roomWithoutId,
      correspondingEstablishment: findEstablishmentCorresponding["@id"],
    };

    const roomCreated = await authApi.createUser(updatedRoomData);

    return roomCreated["@id"];
  };

  const scrollToTop = () => {
    // Permet d'etre à chaque fois en haut de la page lorsqu'on navigue
    window.scrollTo(0, 0);
  };

  const handleValidateForm = async () => {
    // Validation du formulaire avec les etapes optionnelles incluses
    console.log("dataClient : ", dataClient);
    console.log("dataEtablissements : ", dataEtablissements);
    console.log("dataRooms : ", dataRooms);
    console.log("dataStaff : ", dataStaff);
    console.log("dataLockers : ", dataLockers);

    try {
      // Créez le client et attendez la fin de cette opération
      const clientId = await createClient();
      console.log("Client created with ID: ", clientId);

      // Créez les établissements et attendez que toutes les opérations soient terminées
      const establishmentsId = [];
      for (let i in dataEtablissements) {
        console.log(dataEtablissements[i]);
        const etaId = await createEstablishment(dataEtablissements[i]);
        if (etaId) establishmentsId.push(etaId);
      }

      console.log("All establishments created with IDs: ", establishmentsId);

      // Création du groupe d'hôtel en utilisant les ID du client et des établissements
      const hotelGroup = await createHotelGroup(clientId, establishmentsId);
      console.log("Hotel group created: ", hotelGroup);

      for (let i in dataStaff) {
        console.log(dataStaff[i]);
        const user = await createStaffUser(dataEtablissements[i]);
      }
      for (let i in dataRooms) {
        console.log(dataRooms[i]);
        const room = await createRoom(dataEtablissements[i]);
      }
    } catch (error) {
      console.error("Error during the creation process: ", error);
    }
  };
  // Fonction pour rendre le formulaire en fonction de l'étape sélectionnée
  const renderForm = () => {
    switch (stepSelected) {
      case "Manager":
        return (
          <Manager dataClient={dataClient} setDataClient={setDataClient} />
        );
      case "Etablissements":
        return (
          <Establishments
            dataEtablissements={dataEtablissements}
            setDataEtablissement={setDataEtablissement}
          />
        );
      case "Personnel":
        return (
          <Staff
            dataEtablissements={dataEtablissements}
            dataStaff={dataStaff}
            setDataStaff={setDataStaff}
          />
        );
      case "Chambres":
        return (
          <Rooms
            dataEtablissements={dataEtablissements}
            dataRooms={dataRooms}
            setDataRooms={setDataRooms}
          />
        );

      case "Lockers":
        return (
          <Lockers
            dataRooms={dataRooms}
            dataLockers={dataLockers}
            setDataLockers={setDataLockers}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="w-100 d-flex flex-column">
        <div className="w-100 py-4">
          <h2>Création d'un groupe</h2>
          <div className="row position-relative">
            <div className="col-12 col-md-3 py-3">
              <div
                style={{
                  overflowX: "auto",
                  height: "fit-content",
                  top: "20px",
                }}
                className="pb-2 mb-2 d-flex flex-column  position-sticky "
              >
                {steps.map(
                  (step, index) =>
                    !step.isOptionnel &&
                    step.list.map((item, index) => (
                      <div
                        key={index}
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        className={`px-4 py-2 text-left  ${
                          stepSelected === item && "bg-primary text-white"
                        }`}
                        onClick={() => setStepSelected(item)}
                      >
                        <span>{item}</span>
                      </div>
                    ))
                )}
                <p
                  style={{ textDecoration: "underline" }}
                  className="mt-4 font-weight-bolder px-4"
                >
                  Optionnel
                </p>{" "}
                {steps.map(
                  (step, index) =>
                    step.isOptionnel &&
                    step.list.map((item, index) => (
                      <div
                        key={index}
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        className={`px-4 py-2 text-left  ${
                          stepSelected === item && "bg-primary text-white"
                        }`}
                        onClick={() => setStepSelected(item)}
                      >
                        <span>{item}</span>
                      </div>
                    ))
                )}
              </div>
            </div>
            <div className="col-12 col-md-9 py-3">
              <div className="card p-4">
                {renderForm()}
                <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center px-2 mt-4">
                  <div className=" mb-4 mt-3 mt-md-0 mb-md-0">
                    {stepSelected !== "Manager" && (
                      <button
                        className="btn btn-action btn-sm"
                        onClick={() => handleNavigationSteps("back")}
                      >
                        Retour
                      </button>
                    )}
                  </div>
                  <div>
                    {stepSelected === "Lockers" ? (
                      <button
                        onClick={() => handleValidateForm()}
                        className="btn btn-primary btn-sm"
                      >
                        Valider
                      </button>
                    ) : stepSelected === "Etablissements" ? (
                      <div
                        className="d-flex flex-column flex-md-row align-items-md-center "
                        style={{ gap: "10px" }}
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleValidateWithoutOptionnal()}
                        >
                          Ignorer les étapes optionnelles et valider
                        </button>{" "}
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleNavigationSteps("next")}
                        >
                          Suivant
                        </button>
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleNavigationSteps("next")}
                      >
                        Suivant
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h5>Client infos :</h5>
                  <pre>{JSON.stringify(dataClient, null, 2)}</pre>
                </div>{" "}
                <div className="mt-4">
                  <h5>Etablissement infos :</h5>
                  <pre>{JSON.stringify(dataEtablissements, null, 2)}</pre>
                </div>{" "}
                <div className="mt-4">
                  <h5>Rooms infos :</h5>
                  <pre>{JSON.stringify(dataRooms, null, 2)}</pre>
                </div>{" "}
                <div className="mt-4">
                  <h5>Personnel infos :</h5>
                  <pre>{JSON.stringify(dataStaff, null, 2)}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
