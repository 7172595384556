import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Context } from "../context/AppContext";

export function ResetStatesOnRouteChange() {
  // Fonction pour reset tous les states du useContext associés au element selectionné sur les pages
  // pour éviter que lorsqu'on re va sur une page ca selectionne encore l'element qui etait precedemment selectionné
  const {
    setEtablissementSelected,
    setLockerSelected,
    setRoomSelected,
    setEstablishmentOfClientCreate,
    setTypeUserCreation,
  } = useContext(Context);

  const location = useLocation();

  useEffect(() => {
    // Permet de reset en fonction de l'url
    const pathname = location.pathname;
    if (pathname !== "/etablissements") setEtablissementSelected(null);
    if (pathname !== "/chambres") {
      setRoomSelected({
        etablissement: null,
        room: null,
      });
    }
    if (pathname !== "/lockers") {
      setLockerSelected({
        etablissement: null,
        room: null,
        locker: null,
      });
    }
    if (pathname !== "/clients/creer") {
      setTypeUserCreation(null);
    }
  }, [location]);

  return null;
}
