import React, { useState } from "react";
import CustomProduct from "./product/CustomProduct";
import FtvProduct from "./product/CreateFtv";

const tabs = ["Produit custom", "Produit Ftv"];

export default function CreateProduct() {
  const [tabSelected, setTabSelected] = useState(tabs[0]);

  const renderForm = () => {
    switch (tabSelected) {
      case "Produit custom":
        return <CustomProduct />;
      case "Produit Ftv":
        return <FtvProduct />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <h2>Créer un nouveau produit</h2>
      <div
        style={{ overflowX: "auto" }}
        className="mt-4 mb-4 d-flex align-items-center pb-2"
      >
        {tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => setTabSelected(tab)}
            style={{ cursor: "pointer" }}
            className={`px-4 py-2 ${
              tabSelected === tab && " border-bottom border-primary"
            }`}
          >
            <span>{tab}</span>
          </div>
        ))}
      </div>
      {renderForm()}
    </div>
  );
}
