import React, { useState } from "react";
import { Link } from "react-router-dom";
import { casiers } from "../../../../data/data.json";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationTable from "../../../components/PaginationTable";
import FilterTable from "../../../components/FilterTable";

export default function Casiers() {
  const [page, setPage] = useState(1);
  const [CasiersFiltered, setCasiersFiltered] = useState([]); // setter à envoyer au composant FilterTable
  const [searchFilter, setSearchFilter] = useState(""); // servira pour le composant FilterTable

  const navigate = useNavigate();
  const itemsPerPage = 20;

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between mb-4">
          <h4>Liste des casiers : </h4>
        </div>
        <FilterTable
          placeholder="Entrez la réf, le nom ou le catalogue du produit"
          data={casiers}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          setterDataFiltered={setCasiersFiltered}
          elementsToIncludesInSearch={[
            { name: "id", type: "string" },
            { name: "nb_produits", type: "string" },
          ]}
        />

        <div className="table-responsive overflow-auto">
          <table className="table table-bordered table-hover position-relative">
            <thead className="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Nombre de produits</th>
              </tr>
            </thead>
            <tbody>
              {CasiersFiltered.slice(
                (page - 1) * itemsPerPage,
                page === 1 ? 20 : (page - 1) * itemsPerPage + itemsPerPage
              ).map((casier, index) => (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/casiers/details/${casier.id}`)}
                  key={index}
                >
                  <th scope="row">{casier.id}</th>
                  <th scope="row">{casier.nb_produits}</th>
                </tr>
              ))}{" "}
            </tbody>
          </table>
        </div>
        <PaginationTable
          data={casiers}
          itemsPerPage={itemsPerPage}
          pageSetter={setPage}
          pageGetter={page}
        />
      </div>
    </div>
  );
}
