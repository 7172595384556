import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register the components required for the Line chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Tooltip,
  Legend
);

export default function ChartWithSelect() {
  const [monthsToShow, setMonthsToShow] = useState(12);

  const generateDataHistory = () => {
    const years = 5; // Nombre d'années dans l'historique
    const monthsInYear = 12;
    const totalMonths = years * monthsInYear; // Nombre total de mois

    const dataHistory = [];
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    for (let i = 0; i < totalMonths; i++) {
      const caClient = Math.floor(Math.random() * 1000) + 500; // Valeur aléatoire entre 500 et 1500
      const caCommissions = Math.floor(Math.random() * 500) + 100; // Valeur aléatoire entre 100 et 600
      const caAbonnements = Math.floor(Math.random() * 300) + 200; // Valeur aléatoire entre 200 et 500
      const caGC = caCommissions + caAbonnements;

      dataHistory.push({
        month: `${months[i % 12]}`,
        caClient,
        caCommissions,
        caAbonnements,
        caGC,
      });
    }

    return dataHistory;
  };

  const handleTimePeriodChange = (months) => {
    setMonthsToShow(months);
  };

  const dataHistory = generateDataHistory();

  const filteredDataHistory = dataHistory.slice(-monthsToShow);

  const lineChartData = {
    labels: filteredDataHistory.map((item) => item.month),
    datasets: [
      {
        label: "CA Client",
        data: filteredDataHistory.map((item) => item.caClient),
        fill: false,
        borderColor: "#007bff",
      },
      {
        label: "CA Commissions",
        data: filteredDataHistory.map((item) => item.caCommissions),
        fill: false,
        borderColor: "#28a745",
      },
      {
        label: "CA Abonnements",
        data: filteredDataHistory.map((item) => item.caAbonnements),
        fill: false,
        borderColor: "#ffc107",
      },
      {
        label: "CA GC",
        data: filteredDataHistory.map((item) => item.caGC),
        fill: false,
        borderColor: "#17a2b8",
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 45,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 10,
          padding: 10,
        },
      },
    },
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-4 d-md-flex align-items-md-center justify-content-md-between">
          <h5 className="card-title">Chiffre d'affaire</h5>
          <select
            style={{ maxWidth: "200px" }}
            className="form-control input"
            onChange={(e) => handleTimePeriodChange(parseInt(e.target.value))}
          >
            <option value="3">3 Mois</option>
            <option value="6">6 Mois</option>
            <option selected value="12">
              1 An
            </option>
            <option value="24">2 Ans</option>
            <option value="60">5 Ans</option>
          </select>
        </div>
        <div
          style={{
            height: "500px",
            width: "100%",
            position: "relative",
          }}
        >
          <Line data={lineChartData} options={lineChartOptions} />
        </div>
      </div>
    </div>
  );
}
