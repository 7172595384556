import React, { useContext, useEffect, useState, useMemo } from "react";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";
import { Context } from "../../../context/AppContext";
import RoomInfo from "../../../components/detailsWithModal/RoomInfos";
import { checkPermission } from "../../../utils/checkPermission";
import authApi from "../../../services/api/authApi";

export default function Rooms() {
  const [jwtUser] = useState(authApi.setup());

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [lockersFilter, setLockersFilter] = useState("all");
  const [roomFiltered, setRoomFiltered] = useState([]);

  const { fullData, roomSelected, setRoomSelected } = useContext(Context);

  const itemsPerPage = 20;

  const lockersNumberFilter = [
    { label: "Tout", value: "all" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5 ou plus", value: "5" },
  ];

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLockersChange = (event) => {
    setLockersFilter(event.target.value);
  };

  const filteredRooms = useMemo(() => {
    // Permet de Filtrer les rooms en fonctions de la valeur de search, du nombre de locker
    if (!fullData) return [];

    return fullData.flatMap((etablissement, etablissementIndex) =>
      etablissement.rooms
        .filter((room) => {
          const query = searchQuery.toLowerCase();
          const matchesSearchQuery =
            !query ||
            room.label.toLowerCase().includes(query) ||
            room.description.toLowerCase().includes(query);

          const matchesLockersFilter =
            lockersFilter === "all" ||
            (lockersFilter === "5" && room.shops.length >= 5) ||
            room.shops.length.toString() === lockersFilter;

          return matchesSearchQuery && matchesLockersFilter;
        })
        .map((room, indexRoom) => ({
          ...room,
          etablissementIndex,
          roomIndex: indexRoom,
        }))
    );
  }, [searchQuery, lockersFilter, fullData]);

  useEffect(() => {
    setRoomFiltered(filteredRooms);
  }, [filteredRooms]);

  useEffect(() => {
    // Permet d'afficher directement la modal de la room lorsqu'on veut l'afficher depuis une autre page
    if (roomSelected && roomSelected.etablissement !== null) {
      document.getElementById("modalOpener").click();
    }
  }, [roomSelected]);

  return (
    <div className="container-fluid">
      <div className="container-fluid d-flex flex-column">
        <div className="d-md-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-4">Liste des chambres :</h4>
          {checkPermission(jwtUser.roles, "admin") && (
            <Link to="/chambres/creer" className="btn btn-sm btn-primary">
              Ajouter une nouvelle chambre
            </Link>
          )}
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center">
          <div style={{ maxWidth: "500px" }} className="form-group w-100">
            <label htmlFor="searchQuery">
              Recherche une chambre par son nom ou sa description
            </label>
            <input
              placeholder="Recherche une chambre"
              className="form-control"
              name="searchQuery"
              id="searchQuery"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="form-group ml-3 w-100" style={{ maxWidth: "200px" }}>
            <label htmlFor="lockersFilter">Nombre de lockers</label>
            <select
              className="form-control"
              id="lockersFilter"
              value={lockersFilter}
              onChange={handleLockersChange}
            >
              {lockersNumberFilter.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <button
            id="modalOpener"
            type="button"
            style={{ display: "none" }}
            data-toggle="modal"
            data-target="#modalPoll"
          ></button>
        </div>

        <div className="table-responsive overflow-auto">
          <table className="table table-bordered table-hover position-relative">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Description</th>
                <th scope="col">Nombre de lockers</th>
              </tr>
            </thead>
            <tbody>
              {roomFiltered
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((room, index) => (
                  <tr
                    // className={`${
                    //   roomSelected.room === index && "bg-primary text-white"
                    // }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRoomSelected({
                        etablissement: room.etablissementIndex,
                        room: room.roomIndex,
                      });
                      document.getElementById("modalOpener").click();
                    }}
                    key={index}
                  >
                    <th scope="row">{room.label}</th>
                    <th scope="row">{room.description}</th>
                    <th scope="row">{room.shops.length}</th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade right"
        id="modalPoll"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-backdrop="true"
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-full-height modal-right modal-notify modal-primary"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <p className="heading lead">Détails de la chambre</p>
              <button
                style={{ fontSize: "12px" }}
                type="button"
                className="close btn btn-sm btn-primary"
                data-dismiss="modal"
                aria-label="Close"
                id="btnCloseModalPoll"
              >
                <span aria-hidden="true" className="white-text">
                  ×
                </span>
              </button>
            </div>
            <div>
              <RoomInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
