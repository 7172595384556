import React, { useContext, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { Context } from "../context/AppContext";
import ChartWithSelect from "../components/charts/ChartWithSelect";
import authApi from "../services/api/authApi";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const { establishmentsData } = useContext(Context);

  const [monthsToShow, setMonthsToShow] = useState(12);

  const handleTimePeriodChange = (months) => {
    setMonthsToShow(months);
  };

  const generateDataHistory = () => {
    const years = 5; // Nombre d'années dans l'historique
    const monthsInYear = 12;
    const totalMonths = years * monthsInYear; // Total number of months

    const dataHistory = [];
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    for (let i = 0; i < totalMonths; i++) {
      const caClient = Math.floor(Math.random() * 1000) + 500; // Valeur aléatoire entre 500 et 1500
      const caCommissions = Math.floor(Math.random() * 500) + 100; // Valeur aléatoire entre 100 et 600
      const caAbonnements = Math.floor(Math.random() * 300) + 200; // Valeur aléatoire entre 200 et 500
      const caGC = caCommissions + caAbonnements;

      dataHistory.push({
        month: `${months[i % 12]}`,
        caClient,
        caCommissions,
        caAbonnements,
        caGC,
      });
    }

    return dataHistory;
  };

  const dataHistory = generateDataHistory();

  const filteredDataHistory = dataHistory.slice(-monthsToShow);

  const caClient = Math.floor(Math.random() * 1000) + 500; // Valeur aléatoire entre 500 et 1500
  const caCommissions = Math.floor(Math.random() * 500) + 100; // Valeur aléatoire entre 100 et 600
  const caAbonnements = Math.floor(Math.random() * 300) + 200; // Valeur aléatoire entre 200 et 500

  const caGC = caCommissions + caAbonnements;

  const barChartData = {
    labels: ["Chiffres d'affaire"],
    datasets: [
      {
        label: "CA Client",
        data: [caClient],
        backgroundColor: "#007bff",
      },
      {
        label: "CA Commissions",
        data: [caCommissions],
        backgroundColor: "#28a745",
      },
      {
        label: "CA Abonnements",
        data: [caAbonnements],
        backgroundColor: "#ffc107",
      },
      {
        label: "CA GC",
        data: [caGC],
        backgroundColor: "#17a2b8",
      },
    ],
  };

  const transactions = [
    {
      category: "Payment",
      client: "Hotel",
      Montant: +5252,
    },
    {
      category: "Payment",
      client: "Hotel",
      Montant: +452,
    },
    {
      category: "Refund",
      client: "Hotel",
      Montant: -300,
    },
    {
      category: "Payment",
      client: "Hotel",
      Montant: +2252,
    },
    {
      category: "Refund",
      client: "Hotel",
      Montant: -300,
    },
    {
      category: "Payment",
      client: "Hotel",
      Montant: +2252,
    },
  ];

  const lockersData = {
    labels: ["Connecté", "Hors ligne", "En panne"],
    datasets: [
      {
        data: [8, 2, 2],
        backgroundColor: ["green", "orange", "darkGrey"],
        borderWidth: 1,
      },
    ],
  };

  const [jwtUser] = useState(authApi.setup());

  return (
    <div className="container-fluid d-flex flex-column">
      <h1 className="mb-4">Bonjour {jwtUser.username} </h1>

      <div className="row">
        <div className="col-md-6 p-2 p-lg-3 ">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Dernières notifications</h5>
              <ul className="list-group list-group-flush">
                {[1, 2, 3, 4].map((x, index) => (
                  <li key={index} className="list-group-item">
                    Derniere notifications {x}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-6 p-2 p-lg-3">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Chiffre d'affaire total</h5>
              <Bar data={barChartData} />
            </div>
          </div>
        </div>

        <div className="col-md-12 p-2 p-lg-3">
          <ChartWithSelect />
        </div>

        <div className="p-2 col-12 col-sm-6 col-md-8 p-lg-3">
          <div className="card p-3 rounded-lg h-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5>Transactions récentes</h5>
              <Link className="text-primary" to="/transactions">
                Voir tout
              </Link>
            </div>
            <div className="d-flex flex-column">
              {transactions.map((x, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between mb-2 border-bottom p-2 "
                >
                  <div className="d-flex align-items-center">
                    {x.category === "Payment" ? (
                      <div
                        style={{
                          backgroundColor: "#EDEDFF",
                          color: "#7174FF",
                          width: "40px",
                          height: "40px",
                        }}
                        className="d-flex align-items-center justify-content-center rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          style={{ width: "25px", height: "25px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#FFE7E3",
                          color: "#FF3E1D",
                          width: "40px",
                          height: "40px",
                        }}
                        className="d-flex align-items-center justify-content-center rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          style={{ width: "25px", height: "25px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75a48.736 48.736 0 0 0 12 0v-.75m-12 0A2.25 2.25 0 0 1 6 2.25h12a2.25 2.25 0 0 1 2.25 2.25m-15 0v12.75a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.5m-6 5.25v3m0 0-1.5 1.5m1.5-1.5 1.5 1.5"
                          />
                        </svg>
                      </div>
                    )}
                    <div className="d-flex flex-column ml-2">
                      <h6 className="font-weight-bold mb-0">{x.client}</h6>
                      <span className="text-muted">{x.category}</span>
                    </div>
                  </div>
                  <span
                    className={
                      x.Montant > 0
                        ? "text-success font-weight-bold"
                        : "text-danger font-weight-bold"
                    }
                  >
                    {x.Montant}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-4 p-2 p-lg-3">
          <div className="card p-3 rounded-lg h-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5>Box connected</h5>
              <Link className="text-primary" to="/transactions">
                Voir tout
              </Link>
            </div>
            <div className="d-flex flex-column">
              <Pie data={lockersData} />
            </div>
          </div>
        </div>

        <div className="p-2 col-12 col-sm-6 col-md-6 p-lg-3">
          <div className="card p-3 rounded-lg h-100">
            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
              <h5>Historique du chiffre d'affaire (CA GC)</h5>
              <div className="btn-group mt-2">
                <button
                  className={`btn btn-sm btn-${
                    monthsToShow === 3 ? "primary" : "secondary"
                  }`}
                  onClick={() => handleTimePeriodChange(3)}
                >
                  3 derniers mois
                </button>
                <button
                  className={`btn btn-sm btn-${
                    monthsToShow === 6 ? "primary" : "secondary"
                  }`}
                  onClick={() => handleTimePeriodChange(6)}
                >
                  6 derniers mois
                </button>
                <button
                  className={`btn btn-sm btn-${
                    monthsToShow === 12 ? "primary" : "secondary"
                  }`}
                  onClick={() => handleTimePeriodChange(12)}
                >
                  12 derniers mois
                </button>
              </div>
            </div>
            <div className="d-flex flex-column">
              <Bar
                data={{
                  labels: filteredDataHistory.map((data) => data.month),
                  datasets: [
                    {
                      label: "CA GC",
                      data: filteredDataHistory.map((data) => data.caGC),
                      backgroundColor: "#007bff",
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
