import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../context/AppContext";
import { currentTime } from "../../../utils/generateDate";
import authApi from "../../../services/api/authApi";

export default function StaffForm() {
  const [clientData, setClientData] = useState({
    email: "",
    roles: ["STAFF"],
    password: "",
    firstname: "",
    lastname: "",
    userType: "Staff",
    correspondingEstablishment: "",
    createdAt: "",
    modifiedAt: "",
    isActive: true,
  });

  const [errors, setErrors] = useState({});
  const { fullData, establishmentOfClientCreate } = useContext(Context);

  useEffect(() => {
    if (establishmentOfClientCreate && establishmentOfClientCreate !== "") {
      setClientData((prevData) => ({
        ...prevData,
        correspondingEstablishment: [establishmentOfClientCreate],
      }));
    }
  }, [establishmentOfClientCreate]);

  const handleUpdateData = (e) => {
    // Mise à jour des data grace au type de l'input

    const { id, value, type, checked, options } = e.target;
    const newData = { ...clientData };

    if (type === "checkbox") {
      newData[id] = checked;
    } else if (type === "select-multiple") {
      newData[id] = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      newData[id] = value;
    }

    setClientData(newData);
    setErrors({ ...errors, [id]: "" });
  };

  const handleSubmitClient = (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validation des datas
    if (!clientData.firstname.trim())
      newErrors.firstname = "Prénom est requis.";
    if (!clientData.lastname.trim()) newErrors.lastname = "Nom est requis.";
    if (!clientData.email.trim()) {
      newErrors.email = "Email est requis.";
    } else if (!/\S+@\S+\.\S+/.test(clientData.email)) {
      newErrors.email = "Email est invalide.";
    }
    if (!clientData.password) newErrors.password = "Mot de passe est requis.";
    if (clientData.correspondingEstablishment.length === 0) {
      newErrors.correspondingEstablishment = "Etablissement est requis.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const time = currentTime();
    const updatedClientData = {
      ...clientData,
      createdAt: time,
      modifiedAt: time,
    };

    authApi.createUser(updatedClientData);

    setErrors({});
  };

  return (
    <form
      onSubmit={handleSubmitClient}
      className="needs-validation mt-4"
      noValidate
    >
      <div className="form-group">
        <label htmlFor="firstname">Prénom :</label>
        <input
          type="text"
          className={`form-control ${errors.firstname ? "is-invalid" : ""}`}
          id="firstname"
          placeholder="Entrez le prénom"
          value={clientData.firstname}
          onChange={handleUpdateData}
          required
        />
        {errors.firstname && (
          <div className="invalid-feedback">{errors.firstname}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="lastname">Nom :</label>
        <input
          type="text"
          className={`form-control ${errors.lastname ? "is-invalid" : ""}`}
          id="lastname"
          placeholder="Entrez le nom"
          value={clientData.lastname}
          onChange={handleUpdateData}
          required
        />
        {errors.lastname && (
          <div className="invalid-feedback">{errors.lastname}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="email">Email :</label>
        <input
          type="email"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          id="email"
          placeholder="Entrez le email"
          value={clientData.email}
          onChange={handleUpdateData}
          required
        />
        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="password">Mot de passe :</label>
        <input
          type="password"
          className={`form-control ${errors.password ? "is-invalid" : ""}`}
          id="password"
          placeholder="Entrez le mot de passe"
          value={clientData.password}
          onChange={handleUpdateData}
          required
        />
        {errors.password && (
          <div className="invalid-feedback">{errors.password}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="roles">Rôles :</label>
        <p>Personnel</p>
      </div>
      <div className="form-group">
        <label htmlFor="userType">Poste du client :</label>
        <input
          type="text"
          className={`form-control ${errors.userType ? "is-invalid" : ""}`}
          id="userType"
          placeholder="Entrez le type d'utilisateur"
          value={clientData.userType}
          onChange={handleUpdateData}
          required
        />
        {errors.userType && (
          <div className="invalid-feedback">{errors.userType}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="correspondingEstablishment">
          Etablissement correspondant :
        </label>
        <select
          className={`mdb-select custom-select multiple-select-dropdown ${
            errors.correspondingEstablishment ? "is-invalid" : ""
          }`}
          id="correspondingEstablishment"
          value={clientData.correspondingEstablishment}
          onChange={handleUpdateData}
          required
        >
          {fullData &&
            fullData
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((establishment, index) => (
                <option key={index} value={establishment["@id"]}>
                  {establishment.name}
                </option>
              ))}
        </select>
        {errors.correspondingEstablishment && (
          <div className="invalid-feedback">
            {errors.correspondingEstablishment}
          </div>
        )}
      </div>

      <button type="submit" className="btn btn-primary">
        Soumettre
      </button>
    </form>
  );
}
