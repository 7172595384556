import React, { useContext, useState } from "react";
import { Context } from "../../context/AppContext";

export default function CreateLocker() {
  const { fullData } = useContext(Context);
  const [productData, setProductData] = useState({
    imei: "",
    serialNumber: "",
    numberOfPitches: 0,
    distributionScheme: [],
    correspondingRoom: "",
    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
  });

  const [errors, setErrors] = useState({});

  const handleSubmitProduct = (e) => {
    e.preventDefault();

    // Valider les champs avant de soumettre
    const newErrors = {};
    if (!productData.imei.trim()) {
      newErrors.imei = "IMEI est requis.";
    }
    if (!productData.serialNumber.trim()) {
      newErrors.serialNumber = "Numéro de série est requis.";
    }
    if (productData.numberOfPitches <= 0) {
      newErrors.numberOfPitches =
        "Le nombre de compartiments doit être supérieur à zéro.";
    }
    if (productData.distributionScheme.length === 0) {
      newErrors.distributionScheme =
        "Au moins un schéma de distribution est requis.";
    }
    if (!productData.correspondingRoom.trim()) {
      newErrors.correspondingRoom = "Salle correspondante est requise.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // fetch('https://your-api-endpoint.com/lockers', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(productData),
      // })
      // .then(response => response.json())
      // .then(data => console.log('Success:', data))
      // .catch(error => console.error('Error:', error));
    }
  };

  const handleUpdateData = (e) => {
    // mise à jour des datas en fonction du type de l'input
    const { id, value, type, checked } = e.target;
    const newData = { ...productData };

    if (type === "checkbox") {
      newData[id] = checked;
    } else if (type === "file") {
      const file = e.target.files[0];
    } else if (type === "number") {
      newData[id] = parseInt(value, 10);
    } else if (type === "select-multiple") {
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      newData[id] = selectedOptions;
    } else {
      newData[id] = value;
    }

    setProductData(newData);

    setErrors({ ...errors, [id]: "" });
  };

  return (
    <div className="container-fluid">
      <h2>Créer un nouveau locker</h2>
      <form
        onSubmit={handleSubmitProduct}
        className="needs-validation"
        noValidate
      >
        <div className="form-group">
          <label htmlFor="imei">IMEI :</label>
          <input
            type="text"
            className={`form-control ${errors.imei ? "is-invalid" : ""}`}
            id="imei"
            placeholder="Entrez le IMEI"
            onChange={handleUpdateData}
            required
          />
          {errors.imei && <div className="invalid-feedback">{errors.imei}</div>}
        </div>

        {/* Champ pour le numéro de série */}
        <div className="form-group">
          <label htmlFor="serialNumber">Numéro de série :</label>
          <input
            type="text"
            className={`form-control ${
              errors.serialNumber ? "is-invalid" : ""
            }`}
            id="serialNumber"
            placeholder="Entrez le numéro de série"
            onChange={handleUpdateData}
            required
          />
          {errors.serialNumber && (
            <div className="invalid-feedback">{errors.serialNumber}</div>
          )}
        </div>

        {/* Champ pour le nombre de compartiments */}
        <div className="form-group">
          <label htmlFor="numberOfPitches">Nombre de compartiments :</label>
          <input
            type="number"
            className={`form-control ${
              errors.numberOfPitches ? "is-invalid" : ""
            }`}
            id="numberOfPitches"
            placeholder="Entrez le nombre de compartiments"
            onChange={handleUpdateData}
            required
          />
          {errors.numberOfPitches && (
            <div className="invalid-feedback">{errors.numberOfPitches}</div>
          )}
        </div>

        {/* Champ pour le schéma de distribution */}
        <div className="form-group">
          <label htmlFor="distributionScheme">Schéma de distribution :</label>
          <select
            multiple
            className={`form-control ${
              errors.distributionScheme ? "is-invalid" : ""
            }`}
            id="distributionScheme"
            onChange={handleUpdateData}
            required
          >
            <option value="scheme1">Schéma 1</option>
            <option value="scheme2">Schéma 2</option>
          </select>
          {errors.distributionScheme && (
            <div className="invalid-feedback">{errors.distributionScheme}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="correspondingRoom">Salle correspondante :</label>
          <select
            className={`form-control ${
              errors.correspondingRoom ? "is-invalid" : ""
            }`}
            id="correspondingRoom"
            onChange={handleUpdateData}
            required
          >
            <option value="" disabled selected>
              Choisissez une salle
            </option>
            {fullData &&
              fullData.map((establishment, indexEta) =>
                establishment.rooms.map((room, roomIndex) => (
                  <option key={indexEta + "-" + roomIndex} value={room["@id"]}>
                    {room.label}
                  </option>
                ))
              )}
          </select>
          {errors.correspondingRoom && (
            <div className="invalid-feedback">{errors.correspondingRoom}</div>
          )}
        </div>

        <button type="submit" className="btn btn-sm btn-primary">
          Soumettre
        </button>
      </form>
    </div>
  );
}
