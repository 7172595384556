import React, { useState } from "react";
import Entreprise from "../../components/global/Entreprise";
import Product from "../../components/global/Product";
import Catalogue from "../../components/global/Catalogue";

const tabs = ["Entreprise", "Produits", "Catalogue"];

export default function SettingsView({ jwtUser }) {
  const [tabSelected, setTabSelected] = useState(tabs[0]);

  const renderForm = () => {
    switch (tabSelected) {
      case "Entreprise":
        return <Entreprise />;
      case "Produits":
        return <Product />;
      case "Catalogue":
        return <Catalogue jwtUser={jwtUser} />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <h3>Paramètres</h3>
      <div
        style={{ overflowX: "auto" }}
        className="mt-4 d-flex align-items-center pb-2"
      >
        {tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => setTabSelected(tab)}
            style={{ cursor: "pointer" }}
            className={`px-4 py-2 ${
              tabSelected === tab && "border-bottom border-primary"
            }`}
          >
            <span>{tab}</span>
          </div>
        ))}
      </div>
      {renderForm()}
    </div>
  );
}
