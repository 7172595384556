import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const create = async (data) => {
  const response = await axios.post(
    `${API_URL}api/guestcare_ut_product_categories`,
    data
  );
  console.log(response);
  return response;
};

const update = async (data, productCategoryId) => {
  const response = await axios.put(
    `${API_URL}api/guestcare_ut_product_categories/${productCategoryId}`,
    data
  );
  return response;
};

const deleteCategory = async (productCategoryId) => {
  const response = await axios.delete(
    `${API_URL}api/guestcare_ut_product_categories/${productCategoryId}`
  );
  console.log(response);
  return response;
};

export default {
  create,
  update,
  deleteCategory,
};
