import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/AppContext";
import roomsApi from "../../services/api/roomsApi";
import { toast } from "react-toastify";

export default function RoomInfo() {
  const {
    fullData,
    setLockerSelected,
    roomSelected,
    setRefreshData,
    refreshData,
  } = useContext(Context);

  const [editMode, setEditMode] = useState(false);
  const initialValues = {
    label:
      (fullData &&
        fullData[roomSelected.etablissement]?.rooms[roomSelected.room]
          ?.label) ||
      "",
    description:
      (fullData &&
        fullData[roomSelected.etablissement]?.rooms[roomSelected.room]
          ?.description) ||
      "",
  };
  const [inputsValue, setInputsValue] = useState({});

  const navigate = useNavigate();

  const handleNavigate = (url, item) => {
    setLockerSelected(item);
    document.getElementById("btnCloseModalPoll").click();
    navigate(url);
  };

  useEffect(() => {
    setInputsValue(initialValues);
  }, [roomSelected]);

  const handleInputChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setInputsValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setInputsValue(initialValues);
    setEditMode(false);
  };

  const handleSaveClick = () => {
    setEditMode(false);
    handleUpdateRoom();
  };

  const handleDeleteRoom = async (userUri) => {
    const parts =
      fullData[roomSelected.etablissement]?.rooms[roomSelected.room][
        "@id"
      ].split("/");
    const roomId = parts[parts.length - 1];
    const response = await roomsApi.deleteRoom(roomId);
    if (!response) {
      toast.success("Chambre supprimée !");
      setRefreshData(!refreshData);
    }
  };

  const handleUpdateRoom = async () => {
    const parts =
      fullData[roomSelected.etablissement]?.rooms[roomSelected.room][
        "@id"
      ].split("/");
    const roomId = parts[parts.length - 1];

    const response = await roomsApi.update(roomId, inputsValue);
    console.log(response);
    if (response.status === 200) {
      toast.success("Chambre mise à jour !");
      setRefreshData(!refreshData);
    }
  };

  return fullData && roomSelected && fullData[roomSelected.etablissement] ? (
    <div className="card-body py-4">
      <div className="d-md-flex justify-content-between mb-4">
        <h5 className="mb-3 mb-md-0">Détails du client :</h5>
        {editMode ? (
          <div
            style={{ flexWrap: "nowrap", gap: "10px" }}
            className="d-flex align-items-center"
          >
            <button
              onClick={handleCancelClick}
              className="btn btn-sm btn-primary"
            >
              Annuler
            </button>
            <button
              onClick={handleSaveClick}
              className="btn btn-sm btn-primary"
            >
              Enregistrer
            </button>
          </div>
        ) : (
          <button onClick={handleEditClick} className="btn btn-sm btn-primary">
            Modifier
          </button>
        )}
      </div>
      <div className="d-flex flex-column">
        <label>Appartient à l'établissement :</label>
        <a className="text-primary" href="#">
          {fullData[roomSelected.etablissement].name}
        </a>
      </div>
      <div className="form-group">
        <label>Nom</label>
        <input
          type="text"
          name="label"
          className="form-control"
          value={inputsValue.label}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <input
          type="text"
          name="description"
          className="form-control"
          value={inputsValue.description}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="mt-4" id="accordionClientEtablissements">
        <div className="card">
          <button
            className="btn btn-link"
            data-toggle="collapse"
            data-target="#collapseClientEtablissements"
            aria-expanded="true"
            aria-controls="collapseClientEtablissements"
          >
            {fullData[roomSelected.etablissement].rooms[roomSelected.room]
              ?.shops &&
            fullData[roomSelected.etablissement].rooms[roomSelected.room]?.shops
              .length < 1
              ? "Aucun locker associé"
              : "Liste des lockers"}
          </button>
          <div
            id="collapseClientEtablissements"
            className="collapse"
            aria-labelledby="headingClientEtablissements"
            data-parent="#accordionClientEtablissements"
          >
            {fullData[roomSelected.etablissement].rooms[roomSelected.room]
              ?.shops &&
              fullData[roomSelected.etablissement].rooms[
                roomSelected.room
              ]?.shops.map((item, index) => (
                <div
                  key={index}
                  style={{ borderTop: index !== 0 && "1px solid grey" }}
                  className="card-body"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      Contient {item.distribution_scheme.length} produit
                    </span>
                    <button
                      className="text-primary bg-transparent border-0"
                      onClick={() =>
                        handleNavigate(`/lockers`, {
                          etablissement: roomSelected.etablissement,
                          room: roomSelected.room,
                          locker: index,
                        })
                      }
                    >
                      Plus d'informations
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <button
          onClick={() => handleDeleteRoom()}
          className="btn-sm btn-danger float-right mb-3 mt-4"
        >
          Supprimer cette chambre
        </button>
      </div>
    </div>
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <p>Aucun établissement sélectionné</p>
    </div>
  );
}
