import React, { useContext, useState } from "react";
import { Context } from "../../context/AppContext";

export default function EstablishmentForm({
  establishment,
  index,
  handleEstablishmentChange,
}) {
  const { fullData, clientsData, customProductsData } = useContext(Context);
  const [errors, setErrors] = useState({});

  // Gestion de la mise à jour des données
  const handleUpdateData = (e) => {
    const { id, value } = e.target;
    const fieldName = id.split("-")[0]; // Extrait le nom du champ sans l'index
    handleEstablishmentChange(index, fieldName, value);
    setErrors({ ...errors, [fieldName]: "" });
  };

  return (
    <form className="needs-validation" noValidate>
      <div className="form-group">
        <label htmlFor={`name-${index}`}>Nom :</label>
        <input
          type="text"
          className={`form-control ${errors.name ? "is-invalid" : ""}`}
          id={`name-${index}`}
          placeholder="Entrez le nom de l'établissement"
          value={establishment.name || ""}
          onChange={handleUpdateData}
          required
        />
        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`zipcode-${index}`}>Code postal :</label>
        <input
          type="text"
          className={`form-control ${errors.zipcode ? "is-invalid" : ""}`}
          id={`zipcode-${index}`}
          placeholder="Entrez le code postal"
          value={establishment.zipcode || ""}
          onChange={handleUpdateData}
          required
        />
        {errors.zipcode && (
          <div className="invalid-feedback">{errors.zipcode}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor={`city-${index}`}>Ville :</label>
        <input
          type="text"
          className={`form-control ${errors.city ? "is-invalid" : ""}`}
          id={`city-${index}`}
          placeholder="Entrez la ville"
          value={establishment.city || ""}
          onChange={handleUpdateData}
          required
        />
        {errors.city && <div className="invalid-feedback">{errors.city}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`customProducts-${index}`}>
          Produits personnalisés :
        </label>
        <select
          multiple
          className={`mdb-select custom-select multiple-select-dropdown ${
            errors.customProducts ? "is-invalid" : ""
          }`}
          id={`customProducts-${index}`}
          value={establishment.customProducts || []}
          onChange={(e) => {
            const selectedOptions = Array.from(e.target.selectedOptions).map(
              (option) => option.value
            );
            handleDataChange(index, "customProducts", selectedOptions);
          }}
          required
        >
          {customProductsData &&
            customProductsData.map((product, index) => (
              <option key={index} value={product["@id"]}>
                {product.label}
              </option>
            ))}
        </select>
        {errors.customProducts && (
          <div className="invalid-feedback">{errors.customProducts}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor={`rooms-${index}`}>Chambres :</label>
        <select
          multiple
          className={`mdb-select custom-select multiple-select-dropdown ${
            errors.rooms ? "is-invalid" : ""
          }`}
          id={`rooms-${index}`}
          value={establishment.rooms || []}
          onChange={(e) => {
            const selectedOptions = Array.from(e.target.selectedOptions).map(
              (option) => option.value
            );
            handleDataChange(index, "rooms", selectedOptions);
          }}
          required
        >
          {fullData &&
            fullData.map((est, etaIndex) =>
              est.rooms.map((room, roomIndex) => (
                <option key={etaIndex + "-" + roomIndex} value={room.label}>
                  {room.label}
                </option>
              ))
            )}
        </select>
        {errors.rooms && <div className="invalid-feedback">{errors.rooms}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`staff-${index}`}>Personnel :</label>
        <select
          multiple
          className={`mdb-select custom-select multiple-select-dropdown ${
            errors.staff ? "is-invalid" : ""
          }`}
          id={`staff-${index}`}
          value={establishment.staff || []}
          onChange={(e) => {
            const selectedOptions = Array.from(e.target.selectedOptions).map(
              (option) => option.value
            );
            handleDataChange(index, "staff", selectedOptions);
          }}
          required
        >
          {clientsData &&
            clientsData.map((client, index) => (
              <option key={index} value={client["@id"]}>
                {client.lastname} {client.firstname}
              </option>
            ))}
        </select>
        {errors.staff && <div className="invalid-feedback">{errors.staff}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`phone-${index}`}>Téléphone :</label>
        <input
          type="tel"
          className={`form-control ${errors.phone ? "is-invalid" : ""}`}
          id={`phone-${index}`}
          placeholder="Entrez le numéro de téléphone"
          value={establishment.phone || ""}
          onChange={handleUpdateData}
          required
        />
        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`fax-${index}`}>Fax :</label>
        <input
          type="text"
          className="form-control"
          id={`fax-${index}`}
          placeholder="Entrez le numéro de fax"
          value={establishment.fax || ""}
          onChange={handleUpdateData}
        />
      </div>

      <div className="form-group">
        <label htmlFor={`email-${index}`}>Email :</label>
        <input
          type="email"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          id={`email-${index}`}
          placeholder="Entrez l'email"
          value={establishment.email || ""}
          onChange={handleUpdateData}
          required
        />
        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`addressOne-${index}`}>Adresse ligne 1 :</label>
        <input
          type="text"
          className={`form-control ${errors.addressOne ? "is-invalid" : ""}`}
          id={`addressOne-${index}`}
          placeholder="Entrez l'adresse ligne 1"
          value={establishment.addressOne || ""}
          onChange={handleUpdateData}
          required
        />
        {errors.addressOne && (
          <div className="invalid-feedback">{errors.addressOne}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor={`addressTwo-${index}`}>Adresse ligne 2 :</label>
        <input
          type="text"
          className="form-control"
          id={`addressTwo-${index}`}
          placeholder="Entrez l'adresse ligne 2"
          value={establishment.addressTwo || ""}
          onChange={handleUpdateData}
        />
      </div>
    </form>
  );
}
