function append_on_event_checking(functionCall, duration)
{
    setTimeout(functionCall, duration);
}

function hide_on_event_checking(variable, value, duration)
{
    setTimeout(() => {
        variable(value);
    }, duration);
}

function hide_timeout(variable, boolean) {
    setTimeout(() => {
        variable(boolean);
    }, 3000);
}

function redirect_on_event_checking (path, duration)
{
    setTimeout(() => {
        window.location.replace(path);
    },duration);
}

function reload_on_event_checking (duration)
{
    setTimeout(() => {
        window.location.reload();
    },duration);
}

function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

export default {
    append_on_event_checking,
    hide_on_event_checking,
    hide_timeout,
    redirect_on_event_checking,
    reload_on_event_checking,
    sleep
}