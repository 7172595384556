import React, { useState } from "react";

export default function SelectMultiple({
  setDataSelected, // setter du usestate qui servira à ajouter ou enlever des items à selectedData
  selectedData, // getter du usetate pour avoir les items selected
  data, // Liste des datas qui correspond à la liste des éléments que l'on peut sélectionner ici
  btnText, // Texte qui sera affiché sur le bouton
  elemToCompare, // Element qui servira de comparaison avec la valeur que l'on mettra dans le searchItem
  modalTitle, // Titre de la modal
  valueToDisplay, // Correspond à la valeur des items que l'on veut afficher (ex: item.email , on veut afficher la value "email")
  name, // Nom pour définir le rôle de ce select, servira à mettre un id au bouton et à la modal et les relier ( ex: name = "client")
  isSelectAllOption, // Boolean pour afficher oui ou non un option pour tout sélectionner d'un coup
}) {
  const [searchItem, setSearchItem] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);

  const handleCheckboxChange = (newElem) => {
    // Si la checkbox est déja sélectionnée, ça la retire du usestate sinon ça l'ajoute
    setDataSelected((prevState) => {
      if (prevState.includes(newElem)) {
        return prevState.filter((elem) => elem !== newElem);
      } else {
        return [...prevState, newElem];
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchItem(e.target.value);
  };

  const filteredClients =
    data &&
    data.filter((item) =>
      item[elemToCompare].toLowerCase().includes(searchItem.toLowerCase())
    );

  const handleSelectAllChange = () => {
    // Si selectAll est deja actif on deselectionne tout sinon on selectionne tout

    if (isSelectAll) {
      setDataSelected([]);
    } else {
      setDataSelected(data.map((item) => item[elemToCompare]));
    }
    setIsSelectAll(!isSelectAll);
  };

  return (
    <div>
      <button
        id={`#${name}Btn`}
        type="button"
        data-toggle="modal"
        data-target={`#${name}Modal`}
        className="btn btn-sm btn-primary mb-4"
      >
        {btnText}
      </button>
      <div
        className="modal fade"
        id={`${name}Modal`}
        tabIndex="-1"
        data-backdrop="true"
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-notify modal-primary"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header bg-primary text-white">
              <h5 className="modal-title">{modalTitle}</h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-dismiss="modal"
                aria-label="Close"
                id="btnCloseModalPoll"
              ></button>
            </div>

            <div className="modal-body">
              {isSelectAllOption && (
                <div className="form-check mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectAll"
                    checked={isSelectAll}
                    onChange={() => handleSelectAllChange()}
                  />
                  <label htmlFor="selectAll" className="form-check-label">
                    Tout sélectionner
                  </label>
                </div>
              )}

              <input
                type="text"
                className="form-control mb-3"
                value={searchItem}
                onChange={handleSearchChange}
                placeholder="Rechercher..."
              />
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                {filteredClients &&
                  filteredClients.map((item, index) => (
                    <div key={index} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`client-${index}`}
                        checked={
                          (selectedData &&
                            selectedData.includes(item[elemToCompare])) ||
                          isSelectAll
                        }
                        onChange={() =>
                          handleCheckboxChange(item[elemToCompare])
                        }
                      />
                      <label
                        htmlFor={`client-${index}`}
                        className="form-check-label"
                      >
                        {Array.isArray(valueToDisplay)
                          ? valueToDisplay.map((key) => item[key]).join(" ")
                          : item[valueToDisplay]}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button type="button" className="btn btn-sm btn-primary">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
