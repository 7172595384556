import React, { useState } from "react";
import EstablishmentForm from "./EstablishmentForm";
import { v4 as uuidv4 } from "uuid";

export default function Establishments({
  dataEtablissements,
  setDataEtablissement,
}) {
  const handleEstablishmentChange = (index, field, value) => {
    // Gérer le changement d'etablissement grâce à l'index
    const updatedEstablishments = [...dataEtablissements];
    updatedEstablishments[index] = {
      ...updatedEstablishments[index],
      [field]: value,
    };

    setDataEtablissement(updatedEstablishments);
  };

  const addNewEstablishment = () => {
    // Ajout d'un nouvel établissement, id rajouté pour permettre de retrouver l'etablissement lors du POST
    const initialEstablishment = {
      id: uuidv4(),
      name: "",
      zipcode: "",
      city: "",
      phone: "",
      fax: "",
      email: "",
      hotelGroup: "",
      customProducts: [],
      rooms: [],
      staff: "",
      bookings: [],
      rating: 0,
      establishmentStripeParameters: "",
      addressOne: "",
      addressTwo: "",
      isActive: true,
      createdAt: "",
      modifiedAt: "",
      eToken: "",
    };

    // Recupere les datas actuelles avec ...dataE et rajoute le nouvel etablissment : initialE
    const updatedEstablishments = [...dataEtablissements, initialEstablishment];

    setDataEtablissement(updatedEstablishments);
  };

  const removeEstablishment = (index) => {
    // Supprimer un etablissement specifique avec l'index
    const updatedEstablishments = [...dataEtablissements];
    updatedEstablishments.splice(index, 1);
    setDataEtablissement(updatedEstablishments);
  };

  return (
    <div className="">
      <h5>Informations des établissements</h5>

      <div className="mt-4" id="accordionEstablishments">
        {dataEtablissements.map((establishment, index) => (
          <div className="card mb-4" key={index}>
            <button
              className="btn btn-link"
              data-toggle="collapse"
              data-target={`#establishmentForm-${index}`}
              aria-expanded={index === 0}
              aria-controls={`establishmentForm-${index}`}
            >
              Établissement {index + 1}
            </button>
            <div
              id={`establishmentForm-${index}`}
              className="collapse show"
              data-parent="#accordionEstablishments"
            >
              <div className="card-body">
                <EstablishmentForm
                  index={index}
                  establishment={establishment}
                  handleEstablishmentChange={handleEstablishmentChange}
                />
                {dataEtablissements.length > 1 ? (
                  <p
                    style={{ cursor: "pointer" }}
                    className="text-danger text-center mt-4"
                    onClick={() => removeEstablishment(index)}
                  >
                    Supprimer cet établissement
                  </p>
                ) : (
                  <p className="text-info text-center mt-4">
                    Impossible de supprimer : Il faut au minimum un
                    établissement
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="card">
        <button className="btn btn-link" onClick={addNewEstablishment}>
          Ajouter un nouvel établissement
        </button>
      </div>
    </div>
  );
}
