import React, { useContext, useState } from "react";
import { Context } from "../../context/AppContext";
import SelectMultiple from "../form_components/SelectMultiple";

export default function CreateRoom() {
  const { fullData } = useContext(Context);
  const [lockersSelected, setLockersSelected] = useState([]);

  const [roomData, setRoomData] = useState({
    number: "",
    label: "",
    description: "",
    shops: [""],
    bookings: [""],
    correspondingEstablishment: "",
    rToken: "",
  });

  const [errors, setErrors] = useState({});

  const handleSubmitRoom = (e) => {
    e.preventDefault();

    const newErrors = {};
    const requiredFields = [
      "number",
      "label",
      "description",
      "correspondingEstablishment",
      "rToken",
    ];

    // Vérification que les champs required sont remplis
    requiredFields.forEach((field) => {
      if (!roomData[field].trim()) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } est requis.`;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log(roomData);
    }
  };

  const handleUpdateData = (e) => {
    // Mise à jour de data en fonction du type d'input
    const { id, value, type } = e.target;
    const newData = { ...roomData };

    if (type === "select-multiple") {
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      newData[id] = selectedOptions;
    } else {
      newData[id] = value;
    }

    setRoomData(newData);
    setErrors({ ...errors, [id]: "" });
  };

  return (
    <div className="container-fluid">
      <h2 className="mb-5">Ajouter une nouvelle chambre</h2>
      <form onSubmit={handleSubmitRoom} className="needs-validation" noValidate>
        <div className="form-group">
          <label htmlFor="number">Numéro :</label>
          <input
            type="text"
            className={`form-control ${errors.number ? "is-invalid" : ""}`}
            id="number"
            placeholder="Entrez le numéro"
            value={roomData.number}
            onChange={handleUpdateData}
            required
          />
          {errors.number && (
            <div className="invalid-feedback">{errors.number}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="label">Nom :</label>
          <input
            type="text"
            className={`form-control ${errors.label ? "is-invalid" : ""}`}
            id="label"
            placeholder="Entrez l'étiquette"
            value={roomData.label}
            onChange={handleUpdateData}
            required
          />
          {errors.label && (
            <div className="invalid-feedback">{errors.label}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="description">Description :</label>
          <textarea
            className={`form-control ${errors.description ? "is-invalid" : ""}`}
            id="description"
            placeholder="Entrez la description"
            value={roomData.description}
            onChange={handleUpdateData}
            required
          />
          {errors.description && (
            <div className="invalid-feedback">{errors.description}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="shops">Lockers :</label>
          <SelectMultiple
            dataSelected={lockersSelected}
            setDataSelected={setLockersSelected}
            valueToDisplay={"@id"}
            elemToCompare="@id"
            data={
              fullData?.flatMap((establishment) =>
                establishment.rooms.flatMap((room) => room.shops)
              ) || []
            }
            btnText="Sélectionner les lockers"
            name="shops"
            modalTitle="Sélection des lockers"
          />
        </div>
        <div className="form-group">
          <label htmlFor="bookings">Bookings :</label>
          <select
            multiple
            className="form-control"
            id="bookings"
            value={roomData.bookings}
            onChange={handleUpdateData}
          ></select>
        </div>
        <div className="form-group">
          <label htmlFor="correspondingEstablishment">
            Établissement correspondant :
          </label>
          <input
            type="url"
            className={`form-control ${
              errors.correspondingEstablishment ? "is-invalid" : ""
            }`}
            id="correspondingEstablishment"
            placeholder="Entrez le lien de l'établissement correspondant"
            value={roomData.correspondingEstablishment}
            onChange={handleUpdateData}
            required
          />
          {errors.correspondingEstablishment && (
            <div className="invalid-feedback">
              {errors.correspondingEstablishment}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="rToken">Token :</label>
          <input
            type="text"
            className={`form-control ${errors.rToken ? "is-invalid" : ""}`}
            id="rToken"
            placeholder="Entrez le token"
            value={roomData.rToken}
            onChange={handleUpdateData}
            required
          />
          {errors.rToken && (
            <div className="invalid-feedback">{errors.rToken}</div>
          )}
        </div>
        <button type="submit" className="btn btn-primary">
          Soumettre
        </button>
      </form>
    </div>
  );
}
