import React, { useState, useEffect } from "react";
import { factures } from "../../../data/factures";
import DetailsDataDisplay from "../DetailsDataDisplay";

export default function FacturesDetails({ id }) {
  const [commandData, setCommandData] = useState(null);

  useEffect(() => {
    const foundCommand = factures.find((facture) => facture.numero === id);
    if (foundCommand) {
      setCommandData(foundCommand);
    }
  }, [id]);

  if (!commandData) {
    return <p>Aucune facture trouvée</p>;
  }

  return (
    <div className="container-fluid">
      <h2 className="mb-4">Détails de la commande</h2>
      <DetailsDataDisplay
        data={[
          {
            label: "Référence",
            link: null,
            value: commandData.numero,
          },
          {
            label: "Data",
            link: null,
            value: commandData.date,
          },
          {
            label: "Nom",
            link: null,
            value: commandData.destinataire.nom,
          },
          {
            label: "Adresse",
            link: null,
            value: commandData.destinataire.adresse,
          },
          {
            label: "Email",
            link: null,
            value: commandData.destinataire.email,
          },
        ]}
        isSubDetails={false}
      />
      <h2 className="mt-5 mb-4">Liste des produits de la commande</h2>
      <div>
        {commandData.produits.map((produit, index) => (
          <DetailsDataDisplay
            key={index}
            data={[
              {
                label: "Nom",
                link: null,
                value: produit.nom,
              },
              {
                label: "Description",
                link: null,
                value: produit.description,
              },
              {
                label: "Quantité",
                link: null,
                value: produit.quantite,
              },
              {
                label: "Prix unitaire",
                link: null,
                value: produit.prix_unitaire,
              },
              {
                label: "Total",
                link: null,
                value: produit.total,
              },
            ]}
            isSubDetails={true}
            indexFromOriginMap={index}
          />
        ))}
      </div>
    </div>
  );
}
