import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import LoginView from "./src/assets/js/views/LoginView";
import AuthApi from "./src/assets/js/services/api/authApi";
import NotFundView from "./src/assets/js/views/NotFundView";

import Products from "./src/assets/js/views/ressources/globalView/Products";
import Casiers from "./src/assets/js/views/ressources/globalView/Casiers";
import Lockers from "./src/assets/js/views/ressources/globalView/Lockers";
import Etablissements from "./src/assets/js/views/ressources/globalView/Etablissements";
import Clients from "./src/assets/js/views/ressources/globalView/Clients";

import Notifications from "./src/assets/js/views/NotificationsView";
import DashboardView from "./src/assets/js/views/DashboardView";
import AccountView from "./src/assets/js/views/AccountView";

import CommandsView from "./src/assets/js/views/accounting/CommandsView";
import InvoicesView from "./src/assets/js/views/accounting/InvoicesView";
import TransactionsView from "./src/assets/js/views/accounting/TransactionsView";

import PaymentsView from "./src/assets/js/views/global/PaymentsView";
import SettingsView from "./src/assets/js/views/global/SettingsView";
import Catalogues from "./src/assets/js/views/ressources/globalView/Catalogues";
import Details from "./src/assets/js/views/ressources/Details";
import Create from "./src/assets/js/views/ressources/Create";
import AppContext from "./src/assets/js/context/AppContext";
import Rooms from "./src/assets/js/views/ressources/globalView/Rooms";
import {
  ResetStatesOnRouteChange,
  useResetStatesOnRouteChange,
} from "./src/assets/js/utils/resetStatesSelected";
import HotelsGroups from "./src/assets/js/views/ressources/globalView/HotelsGroups";
import CreateHotelGroup from "./src/assets/js/views/ressources/globalView/CreateHotelGroup";
import Mails from "./src/assets/js/views/global/Mails";
import Layout from "./src/assets/js/components/layout/Layout";

AuthApi.setup();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthApi.is_authenticated()
  );

  const [jwtUser, setJwtUser] = useState(AuthApi.setup());

  return (
    <AppContext>
      <BrowserRouter>
        <ResetStatesOnRouteChange />
        {isAuthenticated ? (
          <>
            {/* jwtUser.roles est un tableau et includes permet de voir si une de ses valeurs correspondant à la valeur de includes */}
            {jwtUser.roles.includes("ADMIN") && (
              <Layout jwtUser={jwtUser}>
                <Routes>
                  <Route path="/" element={<DashboardView />} />
                  <Route path="/produits" element={<Products />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/groupes" element={<HotelsGroups />} />
                  <Route path="/transactions" element={<TransactionsView />} />
                  <Route path="/factures" element={<InvoicesView />} />
                  <Route path="/casiers" element={<Casiers />} />
                  <Route path="/commandes" element={<CommandsView />} />
                  <Route path="/paiements" element={<PaymentsView />} />
                  <Route path="/parametres" element={<SettingsView />} />
                  <Route path="/mails" element={<Mails />} />
                  <Route path="/compte" element={<AccountView />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/etablissements" element={<Etablissements />} />
                  <Route path="/lockers" element={<Lockers />} />
                  <Route path="/chambres" element={<Rooms />} />
                  <Route path="/catalogues" element={<Catalogues />} />
                  <Route path="/:category/details/:id" element={<Details />} />
                  <Route path="/:category/creer" element={<Create />} />
                  <Route path="/groupes/creer" element={<CreateHotelGroup />} />
                  <Route path="*" exact={true} element={<NotFundView />} />
                </Routes>
              </Layout>
            )}
            {jwtUser.roles.includes("SUPPORT") &&
              !jwtUser.roles.includes("ADMIN") && (
                <Layout>
                  <Routes>
                    <Route path="/" element={<DashboardView />} />
                    <Route path="/produits" element={<Products />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/groupes" element={<HotelsGroups />} />
                    <Route path="/casiers" element={<Casiers />} />
                    <Route path="/parametres" element={<SettingsView />} />
                    <Route path="/mails" element={<Mails />} />
                    <Route path="/compte" element={<AccountView />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route
                      path="/etablissements"
                      element={<Etablissements />}
                    />
                    <Route path="/lockers" element={<Lockers />} />
                    <Route path="/chambres" element={<Rooms />} />
                    <Route path="/catalogues" element={<Catalogues />} />
                    <Route
                      path="/:category/details/:id"
                      element={<Details />}
                    />
                    <Route path="*" exact={true} element={<NotFundView />} />
                  </Routes>
                </Layout>
              )}
            {!jwtUser.roles.includes("ADMIN") &&
              !jwtUser.roles.includes("TEST") && (
                <Routes>
                  <Route path="*" element={<LoginView />} />
                </Routes>
              )}
          </>
        ) : (
          <Routes>
            <Route path="*" element={<LoginView />} />
          </Routes>
        )}
      </BrowserRouter>
    </AppContext>
  );
};

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App tab="home" />);
