import React from "react";
import { v4 as uuidv4 } from "uuid";

export default function Rooms({ dataEtablissements, dataRooms, setDataRooms }) {
  const addNewRoom = (establishmentId) => {
    // Nouvelle room avec id rajouté pour retrouver l'objet lors du POST

    const initialRoom = {
      id: uuidv4(),
      number: "",
      label: "",
      description: "",
      shops: [""],
      bookings: [""],
      correspondingEstablishment: establishmentId,
      rToken: "",
    };

    const updatedRooms = [...dataRooms, initialRoom];

    setDataRooms(updatedRooms);
  };

  const removeRoom = (roomId) => {
    // Suppression de la room avec son id
    const updatedRooms = dataRooms.filter((room) => room.id !== roomId);
    setDataRooms(updatedRooms);
  };

  const handleRoomChange = (roomId, field, value) => {
    // Modification de la room
    const updatedRooms = dataRooms.map((room) =>
      room.id === roomId ? { ...room, [field]: value } : room
    );
    setDataRooms(updatedRooms);
  };

  return (
    <div className="">
      <h5>Informations des chambres </h5>

      {dataEtablissements.length === 0 ? (
        <p>Aucun établissement disponible.</p>
      ) : (
        <div className="accordion" id="accordionEstablishments">
          {dataEtablissements.map((establishment, estIndex) => (
            <div className="card mb-4" key={estIndex}>
              <div className="card-header" id={`heading-${establishment.id}`}>
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapse-${establishment.id}`}
                    aria-expanded={estIndex === 0 ? "true" : "false"}
                    aria-controls={`collapse-${establishment.id}`}
                  >
                    {establishment.name || `Établissement ${estIndex + 1}`}
                  </button>
                </h5>
              </div>

              <div
                id={`collapse-${establishment.id}`}
                className="collapse"
                aria-labelledby={`heading-${establishment.id}`}
                data-parent="#accordionEstablishments"
              >
                <div className="card-body">
                  <h6>Chambres de l'établissement {establishment.name} :</h6>
                  <ul>
                    {dataRooms
                      .filter(
                        (room) =>
                          room.correspondingEstablishment === establishment.id
                      )
                      .map((room, roomIndex) => (
                        <li key={estIndex + "-" + roomIndex} className="mb-3">
                          <div className="card p-3">
                            <div className="form-group">
                              <label>Numéro :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={room.number}
                                onChange={(e) =>
                                  handleRoomChange(
                                    room.id,
                                    "number",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Label :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={room.label}
                                onChange={(e) =>
                                  handleRoomChange(
                                    room.id,
                                    "label",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Description :</label>
                              <input
                                type="text"
                                className="form-control"
                                value={room.description}
                                onChange={(e) =>
                                  handleRoomChange(
                                    room.id,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="text-center">
                              <p
                                style={{ cursor: "pointer" }}
                                className="text-center text-danger mt-3"
                                onClick={() => removeRoom(room.id)}
                              >
                                Supprimer cette chambre
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  <button
                    className="btn btn-link"
                    onClick={() => addNewRoom(establishment.id)}
                  >
                    Ajouter une nouvelle chambre
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
