export const checkPermission = (userRoles, rolesAllowed) => {
  // Convertir tous les rôles utilisateur en minuscules
  const normalizedUserRoles = userRoles.map((role) => role.toLowerCase());

  // Toujours autoriser si l'utilisateur est "admin"
  if (normalizedUserRoles.includes("admin")) {
    return true;
  }

  // Si rolesAllowed est une chaîne, la convertir en tableau
  if (typeof rolesAllowed === "string") {
    rolesAllowed = [rolesAllowed];
  }

  // Convertir tous les rôles autorisés en minuscules
  const normalizedRolesAllowed = rolesAllowed.map((role) => role.toLowerCase());

  // Vérifie si au moins un rôle de l'utilisateur est dans la liste des rôles autorisés
  return normalizedRolesAllowed.some((role) =>
    normalizedUserRoles.includes(role)
  );
};
