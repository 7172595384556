import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../context/AppContext";
import Locker from "../../../components/details/Locker";
import { checkPermission } from "../../../utils/checkPermission";
import authApi from "../../../services/api/authApi";

export default function Lockers() {
  const [jwtUser] = useState(authApi.setup());

  const [lockersFiltered, setLockersFiltered] = useState(null);
  const [lockerStatus, setLockerStatus] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [display, setDisplay] = useState("list"); // Permet d'afficher soit la liste de tous les lockers soit le détail d'un locker
  const { fullData, lockerSelected, setLockerSelected } = useContext(Context);

  const handleSelectChange = (event) => {
    setLockerStatus(event.target.value);
  };

  // Event handler for input field
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (fullData) {
      // const newLockersFiltered = fullData.filter((locker) => {
      //   // Filtrer en fonction du statut du locker
      //   if (lockerStatus === "online" && !locker.is_connected) {
      //     return false;
      //   }
      //   if (lockerStatus === "offline" && locker.is_connected) {
      //     return false;
      //   }

      //   // Filtrer en fonction de la recherche par référence
      //   if (
      //     searchQuery &&
      //     !locker.locker_group_id
      //       .toLowerCase()
      //       .includes(searchQuery.toLowerCase())
      //   ) {
      //     return false;
      //   }

      //   return true;
      // });

      setLockersFiltered(fullData);
    }
  }, [lockerStatus, searchQuery, fullData]);

  useEffect(() => {
    // Permet d'afficher les détails d'un locker directement lorsqu'on est redirigé depuis une autre page
    if (lockerSelected && lockerSelected.etablissement !== null) {
      setDisplay("details");
    } else {
      setDisplay("list");
    }
  }, [lockerSelected]);

  return display === "list" ? (
    <div className="container-fluid d-flex flex-column">
      <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-4">
        <h4>Liste des lockers</h4>
        {checkPermission(jwtUser.roles, "admin") && (
          <Link
            to="/lockers/creer"
            style={{ width: "fit-content" }}
            className="btn btn-sm btn-primary"
          >
            Créer un nouveau locker
          </Link>
        )}
      </div>
      <div className=" d-flex flex-column ">
        <div className="d-flex flex-column flex-md-row mb-3">
          <div style={{ maxWidth: "500px" }} className="form-group mr-3 w-100">
            <label htmlFor="connected_lockers">Status du locker</label>
            <select
              className="form-control"
              name="connected_lockers"
              id=""
              value={lockerStatus}
              onChange={handleSelectChange}
            >
              <option value="all">Tout</option>
              <option value="online">En ligne</option>
              <option value="offline">Hors ligne</option>
            </select>
          </div>{" "}
          <div style={{ maxWidth: "500px" }} className="form-group w-100">
            <label htmlFor="locker_id">
              Recherche un locker par sa référence
            </label>
            <input
              placeholder="Recherche un locker par sa référence"
              className="form-control"
              name="connected_lockers"
              id=""
              value={searchQuery}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div style={{ maxHeight: "800px" }} className="pr-2 row">
          {fullData &&
            fullData.map((etablissement, indexEtablissment) =>
              etablissement.rooms.map((room, indexRoom) =>
                room.shops.map((shop, indexShop) => (
                  <div
                    className="p-2 col-6 col-sm-4 col-lg-3"
                    key={indexEtablissment + "-" + indexRoom + "-" + indexShop}
                  >
                    <div
                      onClick={() => {
                        setLockerSelected({
                          etablissement: indexEtablissment,
                          room: indexRoom,
                          locker: indexShop,
                        });
                        // document
                        //   .getElementById("modalOpenerLocker")
                        //   .click();
                      }}
                      className="card-wrapper"
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        style={{ aspectRatio: 1 / 1 }}
                        id="card-1"
                        className="card"
                      >
                        <div className="card-up h-auto position-relative">
                          <img
                            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.premierlockers.co.uk%2Fwp-content%2Fuploads%2F2018%2F11%2FElectronic-locks-on-Metal-lockers-1080x810.jpg&f=1&nofb=1&ipt=270c570aae367e7b17d70d8fa316b57b0fc66338704db9f5d1ac6473f82f6d14&ipo=images"
                            className="img-fluid w-100"
                            style={{
                              maxHeight: "150px",
                              objectFit: "cover",
                            }}
                            alt="Image d'un locker"
                          />
                          <div
                            style={{ opacity: "60%", top: 0 }}
                            className="overlay position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"
                          ></div>
                        </div>
                        <div className="card-body">
                          <div className="mb-2 d-flex align-items-center">
                            <div
                              style={{
                                background: "lightGreen",
                                width: "20px",
                                height: "20px",
                                borderRadius: 9999,
                                marginRight: "10px",
                              }}
                            ></div>
                            <span>Connecté</span>
                          </div>
                          <h5>Locker {indexShop + 1}</h5>
                          <p style={{ fontSize: "12px" }}>
                            Nombre de produits :{" "}
                            {shop.distribution_scheme.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )
            )}
        </div>
      </div>
    </div>
  ) : (
    <Locker />
  );
}
