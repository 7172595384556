import React, { useContext, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

import Editor from "./Editor";
import { Context } from "../../context/AppContext";
import SelectMultiple from "../form_components/SelectMultiple";

export default function CreateMail() {
  const { clientsData } = useContext(Context);
  const quillRef = useRef();
  const [selectedClients, setSelectedClients] = useState([]); // State pour gérer les destinaire selectionné
  const [searchClient, setSearchClient] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null); // State pour gérer les files selected

  const getFullContent = () => {
    if (quillRef.current) {
      const quillInstance = quillRef.current;
      const htmlContent = quillInstance.root.innerHTML; // Tranforme en html
      console.log("Full content:", htmlContent);
      console.log("Selected files:", selectedFiles);
    } else {
      alert("Quill instance not available.");
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const filteredClients =
    clientsData &&
    clientsData.filter((client) =>
      client.email.toLowerCase().includes(searchClient.toLowerCase())
    );

  return (
    <div className="container-fluid">
      <h2>Écrire un nouveau mail</h2>
      <div>
        <SelectMultiple
          dataSelected={selectedClients}
          setDataSelected={setSelectedClients}
          valueToDisplay="email"
          elemToCompare="email"
          data={clientsData}
          btnText="Sélectionner les destionataires"
          name="client"
          modalTitle="Sélection des destinataires"
          isSelectAllOption={true}
        />

        <div className="d-flex flex-column ">
          <Editor ref={quillRef} />
          <div className="mt-5 d-flex flex-column">
            <div className="mb-3">
              <label htmlFor="fileInput" className="form-label">
                Pièces jointes :
              </label>
              <input
                type="file"
                className="form-control"
                id="fileInput"
                multiple
                onChange={handleFileChange}
              />
              {selectedFiles && (
                <div>
                  Fichiers sélectionnés :
                  {Array.from(selectedFiles).map((file, index) => (
                    <span key={index} className="badge bg-secondary mx-1">
                      {file.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <button
              className="btn btn-sm btn-primary align-self-end"
              type="button"
              onClick={() => {
                getFullContent();
              }}
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
