import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { lockers, casiers, produits } from "../../../data/data.json";
import DetailsDataDisplay from "../DetailsDataDisplay";

export default function Casier({ id }) {
  const [lockerData, setLockerData] = useState(null);
  const [casierData, setCasierData] = useState(null);
  const [produitsData, setProduitsData] = useState(null);

  useEffect(() => {
    const foundCasier = casiers.find((casier) => casier.id === id);
    if (foundCasier) {
      setCasierData(foundCasier);

      const foundLocker = lockers.find(
        (locker) => locker.id === foundCasier.locker_id
      );
      if (foundLocker) {
        setLockerData(foundLocker);
      }

      const foundProduits = produits.filter(
        (produit) => produit.casier_id === id
      );
      setProduitsData(foundProduits);
    }
  }, [id]);

  if (!casierData) {
    return <p>Aucun casier trouvé</p>;
  }

  return (
    <div className="w-100">
      <div className="d-md-flex justify-content-between mb-4">
        <h4 className=" mb-3 mb-md-0 ">Détails du casier : </h4>
        <Link to={`/casiers`} className="btn-primary p-2 rounded-lg">
          Modifier
        </Link>
      </div>
      <DetailsDataDisplay
        data={[
          {
            label: "Référence",
            link: null,
            value: casierData.id,
          },
          {
            label: "Produits",
            link: null,
            value:
              produitsData.length <= 0 ? "Aucun produit" : produitsData.length,
          },
          {
            label: "Locker",
            link: `/lockers/${casierData.locker_id}`,
            value: "Afficher le locker",
          },
        ]}
        isSubDetails={false}
      />
      <div>
        <h4 className="mt-4 mb-3">Liste des produits associés : </h4>

        <div className="">
          {produitsData &&
            produitsData.length >= 1 &&
            produitsData.map((produit, index) => (
              <div key={index} className="mb-3">
                <DetailsDataDisplay
                  data={[
                    {
                      label: "Référence",
                      link: null,
                      value: produit.id,
                    },
                    {
                      label: "Nom",
                      link: null,
                      value: produit.nom,
                    },
                    {
                      label: "Prix",
                      link: null,
                      value: produit.prix,
                    },
                    {
                      label: "Détails du produit",
                      link: `/produits/${produit.id}`,
                      value: "Afficher les détails du produit",
                    },
                  ]}
                  isSubDetails={true}
                  indexFromOriginMap={index}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
