import React from "react";
import data from "../../../../data/data.json";
import DetailsDataDisplay from "../../../components/DetailsDataDisplay";
import { Link } from "react-router-dom";

export default function Catalogues() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="d-flex flex-column flex-lg-row justify-content-lg-between  mb-4">
        <h4>Liste des catalogues</h4>
        <Link
          to="/catalogues/creer"
          style={{ width: "fit-content" }}
          className="btn-primary p-2 rounded-lg "
        >
          Créer un nouveau catalogue
        </Link>
      </div>
      {data &&
        data.catalogues.length >= 1 &&
        data.catalogues.map((catalogue, index) => (
          <div key={index} className="mb-2">
            <DetailsDataDisplay
              data={[
                {
                  label: "Nom",
                  link: null,
                  value: catalogue.titre,
                },
                {
                  label: "Référence",
                  link: null,
                  value: catalogue.titre,
                },
                {
                  label: "description",
                  link: null,
                  value: catalogue.description,
                },
                {
                  label: "Produits",
                  link: `/produits?search=${catalogue.id}`,
                  value: "Afficher les produits de ce catalogue",
                },
              ]}
              isSubDetails={true}
              indexFromOriginMap={index}
            />
          </div>
        ))}
    </div>
  );
}
