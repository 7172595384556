import React, { useState } from "react";

export default function Manager({ dataClient, setDataClient }) {
  const [errors, setErrors] = useState({});

  const handleSubmitClient = (e) => {
    e.preventDefault();

    const newErrors = {};

    // Permet d'afficher une erreur si le champs est vide (trim() sert à enlever les espaces inutiles)
    if (!dataClient.firstname.trim())
      newErrors.firstname = "Prénom est requis.";
    if (!dataClient.lastname.trim()) newErrors.lastname = "Nom est requis.";
    if (!dataClient.email.trim()) {
      newErrors.email = "Email est requis.";
    } else if (!/\S+@\S+\.\S+/.test(dataClient.email)) {
      newErrors.email = "Email est invalide.";
    }
    if (!dataClient.password) newErrors.password = "Mot de passe est requis.";

    // Si il y a au moins une erreur on les affiches et on stop la fonction
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const currentDate = new Date().toISOString();
    const updatedClientData = {
      ...dataClient,
      createdAt: currentDate,
      modifiedAt: currentDate,
    };

    console.log(updatedClientData);

    // Réinitialiser les erreurs
    setErrors({});
  };

  const handleUpdateData = (e) => {
    // Mettre à jour les datas grâce au type d'input
    const { id, value, type, checked, options } = e.target;
    const newData = { ...dataClient };

    if (type === "checkbox") {
      newData[id] = checked;
    } else if (type === "select-multiple") {
      newData[id] = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      newData[id] = value;
    }

    setDataClient(newData);
    setErrors({ ...errors, [id]: "" });
  };

  return (
    <div className="">
      <h5>Informations du manager</h5>
      <form
        onSubmit={handleSubmitClient}
        className="needs-validation"
        noValidate
      >
        <div className="form-group">
          <label htmlFor="firstname">Prénom :</label>
          <input
            type="text"
            className={`form-control ${errors.firstname ? "is-invalid" : ""}`}
            id="firstname"
            placeholder="Entrez le prénom"
            value={dataClient.firstname}
            onChange={handleUpdateData}
            required
          />
          {errors.firstname && (
            <div className="invalid-feedback">{errors.firstname}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="lastname">Nom :</label>
          <input
            type="text"
            className={`form-control ${errors.lastname ? "is-invalid" : ""}`}
            id="lastname"
            placeholder="Entrez le nom"
            value={dataClient.lastname}
            onChange={handleUpdateData}
            required
          />
          {errors.lastname && (
            <div className="invalid-feedback">{errors.lastname}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email :</label>
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            id="email"
            placeholder="Entrez le email"
            value={dataClient.email}
            onChange={handleUpdateData}
            required
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe :</label>
          <input
            type="password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
            id="password"
            placeholder="Entrez le mot de passe"
            value={dataClient.password}
            onChange={handleUpdateData}
            required
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="roles">Rôles :</label>
          <p>MANAGER</p>
        </div>
      </form>
    </div>
  );
}
