import React, { useState } from "react";
import authApi from "../../../services/api/authApi";
import { currentTime } from "../../../utils/generateDate";

const roles_availables = ["ADMIN", "SUPPORT"];

export default function TechnicalForm() {
  const [clientData, setClientData] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    userType: "technical",
    roles: [""],
    createdAt: "",
    modifiedAt: "",
    isActive: true,
  });

  const [errors, setErrors] = useState({});

  const handleUpdateData = (e) => {
    const { id, value, type } = e.target;

    if (type === "select-one") {
      setClientData((prevData) => ({
        ...prevData,
        [id]: [value],
      }));
    } else {
      setClientData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  // Fonction pour gérer la soumission du formulaire
  const handleSubmitClient = (e) => {
    e.preventDefault();

    // Validation des champs
    const newErrors = {};
    if (!clientData.firstname.trim())
      newErrors.firstname = "Prénom est requis.";
    if (!clientData.lastname.trim()) newErrors.lastname = "Nom est requis.";
    if (!clientData.email.trim()) {
      newErrors.email = "Email est requis.";
    } else if (!/\S+@\S+\.\S+/.test(clientData.email)) {
      newErrors.email = "Email est invalide.";
    }
    if (!clientData.password) newErrors.password = "Mot de passe est requis.";

    // Si des erreurs sont présentes, les afficher et arrêter la soumission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const time = currentTime();
    const updatedClientData = {
      ...clientData,
      createdAt: time,
      modifiedAt: time,
    };

    authApi.createUser(updatedClientData);
    setErrors({});
  };

  return (
    <form
      onSubmit={handleSubmitClient}
      className="needs-validation mt-4"
      noValidate
    >
      <div className="form-group">
        <label htmlFor="firstname">Prénom :</label>
        <input
          type="text"
          className={`form-control ${errors.firstname ? "is-invalid" : ""}`}
          id="firstname"
          placeholder="Entrez le prénom"
          value={clientData.firstname}
          onChange={handleUpdateData}
          required
        />
        {errors.firstname && (
          <div className="invalid-feedback">{errors.firstname}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="lastname">Nom :</label>
        <input
          type="text"
          className={`form-control ${errors.lastname ? "is-invalid" : ""}`}
          id="lastname"
          placeholder="Entrez le nom"
          value={clientData.lastname}
          onChange={handleUpdateData}
          required
        />
        {errors.lastname && (
          <div className="invalid-feedback">{errors.lastname}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="email">Email :</label>
        <input
          type="email"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          id="email"
          placeholder="Entrez l'email"
          value={clientData.email}
          onChange={handleUpdateData}
          required
        />
        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="password">Mot de passe :</label>
        <input
          type="password"
          className={`form-control ${errors.password ? "is-invalid" : ""}`}
          id="password"
          placeholder="Entrez le mot de passe"
          value={clientData.password}
          onChange={handleUpdateData}
          required
        />
        {errors.password && (
          <div className="invalid-feedback">{errors.password}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="roles">Rôles :</label>
        <select
          className={`mdb-select custom-select multiple-select-dropdown ${
            errors.roles ? "is-invalid" : ""
          }`}
          id="roles"
          value={clientData.roles}
          onChange={handleUpdateData}
          required
        >
          {roles_availables.map((role, index) => (
            <option key={index} value={role}>
              {role}
            </option>
          ))}
        </select>
      </div>

      <button type="submit" className="btn btn-primary">
        Soumettre
      </button>
    </form>
  );
}
