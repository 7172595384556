import React from "react";

const PaginationTable = ({ data, itemsPerPage, pageSetter, pageGetter }) => {
  const handlePagination = (numberToAddOrSoustract) => {
    if (numberToAddOrSoustract === -1 && pageGetter <= 1) {
      return;
    } else {
      pageSetter(pageGetter + numberToAddOrSoustract);
    }
  };

  return (
    <ul className="pagination justify-content-center">
      <li className={`page-item ${pageGetter === 1 && "disabled"} `}>
        <button
          className="page-link d-flex align-items-center"
          onClick={() => handlePagination(-1)}
        >
          <span aria-hidden="true">&laquo;</span>
          <span className="ml-2 mb-0">Précédent</span>
        </button>
      </li>
      <li
        className={`page-item ${
          data.length <= pageGetter * itemsPerPage && "disabled"
        }
            `}
      >
        <button
          className="page-link d-flex align-items-center"
          onClick={() => handlePagination(1)}
        >
          <span className="mr-2 mb-0">Suivant</span>
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  );
};

export default PaginationTable;
