import React, { useEffect, useState } from "react";
import { fetchAllData } from "../services/api/fetchAllData";

export const Context = React.createContext();

export default function AppContext({ children }) {
  //////////// States pour gérer les élements sélectionné dans la navigation
  const [clientSelected, setClientSelected] = useState(null);
  const [etablissementSelected, setEtablissementSelected] = useState(null);
  const [roomSelected, setRoomSelected] = useState({
    etablissement: null,
    room: null,
  });
  const [lockerSelected, setLockerSelected] = useState({
    etablissement: null,
    room: null,
    locker: null,
  });
  const [typeUserCreation, setTypeUserCreation] = useState(null);
  //////////////////////////////////////////////////////

  /////// States pour gérer toutes les datas récupérées depuis l'api
  const [groupsData, setGroupsData] = useState(null);
  const [clientsData, setClientsData] = useState(null);
  const [customProductsData, setCustomProductsData] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [categoriesData, setCategoriesData] = useState(null);
  const [subCategoriesData, setSubCategoriesData] = useState(null);
  const [fullData, setFullData] = useState(null);
  //////////////////////////////////////////////////////

  const [establishmentOfClientCreate, setEstablishmentOfClientCreate] =
    useState(null);

  const [listNotifications, setListNotifications] = useState([]);

  const [refreshData, setRefreshData] = useState(false); // Pour relancer le useeffect
  const [jwtUserContext, setJwtUserContext] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchAllData(`${API_URL}api/guestcare_jt_hotel_groups/`, setGroupsData);
    fetchAllData(`${API_URL}api/users/`, setClientsData);
    fetchAllData(
      `${API_URL}api/guestcare_ut_custom_products`,
      setCustomProductsData
    );
    fetchAllData(`${API_URL}api/guestcare_ut_products`, setProductsData);
    fetchAllData(
      `${API_URL}api/guestcare_ut_product_categories`,
      setCategoriesData
    );
    fetchAllData(
      `${API_URL}api/guestcare_ut_product_sub_categories`,
      setSubCategoriesData
    );
    fetchAllData(`${API_URL}api/guestcare_ut_establishments`, setFullData);
  }, [refreshData]);

  return (
    <Context.Provider
      value={{
        etablissementSelected,
        setEtablissementSelected,
        lockerSelected,
        setLockerSelected,
        roomSelected,
        setRoomSelected,
        clientsData,
        productsData,
        categoriesData,
        subCategoriesData,
        customProductsData,
        fullData,
        groupsData,
        establishmentOfClientCreate,
        setEstablishmentOfClientCreate,
        clientSelected,
        setClientSelected,
        typeUserCreation,
        setTypeUserCreation,
        listNotifications,
        setListNotifications,
        refreshData,
        setRefreshData,
      }}
    >
      {children}
    </Context.Provider>
  );
}
