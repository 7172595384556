import React from "react";
import { Link } from "react-router-dom";

export default function DetailsDataDisplay({
  data,
  isSubDetails,
  indexFromOriginMap,
  editMode,
}) {
  return isSubDetails ? (
    <div className="accordion mb-4" id={`accordion`}>
      {data.map((elem, index) => {
        return (
          <div className="card" key={index}>
            {index === 0 && (
              <div
                className="card-header"
                id={`headingDetails${indexFromOriginMap}`}
              >
                <button
                  className="btn mx-0 px-0 btn-link btn-block text-left "
                  type="button"
                  data-toggle="collapse"
                  data-target={`#collapseDetails${indexFromOriginMap}`}
                  aria-expanded={index === 0 ? "true" : "false"}
                  aria-controls={`collapseDetails${indexFromOriginMap}`}
                >
                  <div className="row">
                    <span className="col-12 col-sm-3 col-md-6">
                      {elem.label}
                    </span>
                    <span>{elem.value}</span>
                  </div>
                </button>
              </div>
            )}

            {index !== 0 && (
              <div
                id={`collapseDetails${indexFromOriginMap}`}
                className={`collapse `}
                aria-labelledby={`headingDetails${indexFromOriginMap}`}
                data-parent={`#accordion`}
              >
                <p
                  key={index}
                  className={`p-3 mb-0 row ${index !== 0 && "border-top"}`}
                >
                  <span className="col-12 col-sm-3 col-md-6">{elem.label}</span>
                  {elem.link ? (
                    <Link
                      className="p-3  p-sm-0 col-12 col-sm-9 col-md-6 text-primary"
                      to={elem.link}
                    >
                      {elem.value}
                    </Link>
                  ) : elem.image ? (
                    <img
                      className="p-3 p-sm-0"
                      style={{ width: "130px", height: "130px" }}
                      src={elem.value}
                      alt=""
                    />
                  ) : (
                    <input
                      className="form-control px-0 col-12 col-sm-9 col-md-6"
                      readOnly={!editMode}
                    >
                      {elem.value}
                    </input>
                  )}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  ) : (
    <div className="bg-white rounded-lg overflow-hidden">
      {data.map((elem, index) => (
        <p
          key={index}
          className={`p-3 mb-0 row ${index !== 0 && "border-top"}`}
        >
          <span className="col-12 col-sm-3 col-md-6">{elem.label}</span>
          {elem.link ? (
            <Link
              className="p-3 p-sm-0 col-12 col-sm-9 col-md-6 text-primary"
              to={elem.link}
            >
              {elem.value}
            </Link>
          ) : elem.image ? (
            <img
              className="p-3 p-sm-0"
              style={{ width: "130px", height: "130px" }}
              src={elem.value}
              alt=""
            />
          ) : (
            <input
              className="form-control px-0 col-12 col-sm-9 col-md-6 px-2 py-1"
              defaultValue={elem.value}
              readOnly={!editMode}
            />
          )}
        </p>
      ))}
    </div>
  );
}
