import React, { useContext, useEffect, useState } from "react";
import productsApi from "../../services/api/productsApi";
import { Context } from "../../context/AppContext";

export default function Locker() {
  const [editMode, setEditMode] = useState(false);
  const [catalog, setCatalog] = useState([]);
  const { lockerSelected, fullData, setLockerSelected } = useContext(Context);

  const handleCatalog = async () => {
    // Récupere les infos des produits de ce locker pour finalement les mettre dans le usestate catalog
    const products =
      fullData[lockerSelected.etablissement].rooms[lockerSelected.room].shops[
        lockerSelected.locker
      ].distribution_scheme;
    if (products !== undefined && products !== null && catalog.length === 0) {
      for (const product of products) {
        let tempProduct = [];
        try {
          tempProduct["price"] = product.price;
          tempProduct["is_sold"] = product.is_sold;
          tempProduct["is_custom"] = product.is_custom;
          tempProduct["ref"] = product.ref;
          if (product.is_custom) {
            tempProduct["product_id"] = await productsApi.fetchCustomProduct(
              product.product_id
            );
          } else {
            tempProduct["product_id"] = await productsApi.fetchFtvProduct(
              product.product_id
            );
          }
          setCatalog((arr) => [...arr, tempProduct]);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  useEffect(() => {
    // Lance la fonction au démarrage et dès que le locker sélectionné change
    handleCatalog();
  }, [lockerSelected]);

  return (
    <div className="w-100">
      <div className="">
        <div className="d-flex flex-column-reverse flex-md-row justify-content-md-between align-items-md-center">
          <h4 className="mb-4">Détails du locker :</h4>
          <button
            onClick={() =>
              setLockerSelected({
                etablissement: null,
                room: null,
                locker: null,
              })
            }
            style={{ width: "fit-content" }}
            className="btn btn-sm btn-primary mb-3 mb-md-0"
          >
            Retourner à la liste de lockers
          </button>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <h6>Données du locker : </h6>
            {fullData &&
              lockerSelected.etablissement !== null &&
              lockerSelected.room !== null &&
              lockerSelected.locker !== null && (
                <div className="">
                  <div className="form-group">
                    <label>Identifiant</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={
                        fullData[lockerSelected.etablissement].rooms[
                          lockerSelected.room
                        ].shops[lockerSelected.locker]["@id"]
                      }
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label>Nombre d'emplacements</label>
                    <input
                      type="text" // Correction du type d'entrée
                      className="form-control"
                      defaultValue={
                        fullData[lockerSelected.etablissement].rooms[
                          lockerSelected.room
                        ].shops[lockerSelected.locker].distribution_scheme
                          .length
                      }
                      readOnly={!editMode}
                    />
                  </div>
                  <div className="form-group d-flex flex-column">
                    <label>Appartient à la chambre :</label>
                    <span
                      style={{ cursor: "pointer" }}
                      className="text-primary"
                    >
                      {
                        fullData[lockerSelected.etablissement].rooms[
                          lockerSelected.room
                        ].label
                      }
                    </span>
                  </div>
                  {editMode ? (
                    <div
                      style={{ flexWrap: "nowrap", gap: "10px" }}
                      className="d-flex align-items-center"
                    >
                      <button
                        onClick={() => setEditMode(false)}
                        className="btn btn-sm btn-action"
                      >
                        Annuler
                      </button>
                      <button
                        onClick={() => setEditMode(false)}
                        className="btn btn-sm btn-primary"
                      >
                        Enregistrer
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => setEditMode(true)}
                      className="btn btn-sm btn-primary"
                    >
                      Modifier
                    </button>
                  )}
                </div>
              )}
          </div>
          <div className="px-4 col-12 col-md-8">
            <h6>Liste des produits associés : </h6>
            <div className="row">
              {catalog &&
                catalog.map((item, index) => {
                  return (
                    <a
                      href={`/produits/details/${item.product_id.id}`}
                      className="p-2 col-6 col-sm-4 col-lg-3"
                      key={index}
                    >
                      <div
                        className="card-wrapper"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{ aspectRatio: 1 / 1 }}
                          id="card-1"
                          className="card"
                        >
                          <div className="card-up h-auto position-relative">
                            <img
                              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.linnea.fr%2Fimages%2Fplarge%2Fx%2F43222.jpg&f=1&nofb=1&ipt=b910c6d64cf24f56c197a20ada0f139cc5aae4410fbf3e9f65f822d24ccaab25&ipo=images"
                              className="img-fluid w-100"
                              style={{ maxHeight: "150px", objectFit: "cover" }}
                              alt="Image d'un locker"
                              loading="lazy"
                            />
                            <div
                              style={{ opacity: "60%", top: 0 }}
                              className="overlay position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"
                            ></div>
                          </div>
                          <div className="card-body">
                            <h5>{item.product_id.label}</h5>
                            <p>{item.price} €</p>
                            <p>{item.product_id.short_description}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
