import React from "react";
import { useParams } from "react-router-dom";
import NotFundView from "../NotFundView";
import Casier from "../../components/details/Casier";
import Produit from "../../components/details/Produit";
import FacturesDetails from "../../components/details/Facture";
import CommandesDetails from "../../components/details/Commande";
import Locker from "../../components/details/Locker";

export default function Details() {
  const params = useParams();

  // exemple d'url : /:category/:id
  const id = params.id;

  return (
    <div className="container-fluid d-flex flex-column">
      {params.category === "casiers" && <Casier id={id} />}
      {params.category === "produits" && <Produit id={id} />}
      {params.category === "lockers" && <Locker id={id} />}
      {params.category === "factures" && <FacturesDetails id={id} />}
      {params.category === "commandes" && <CommandesDetails id={id} />}
      {![
        "casiers",
        "produits",
        "catalogues",
        "factures",
        "commandes",
        "lockers",
      ].includes(params.category) && <NotFundView />}
    </div>
  );
}
