export const transactions = [
  {
    id: 1,
    montant: 100,
    date: "2024-04-01",
    status: "Terminé",
    produit: "01002929384",
    client: "Alice Dubois",
  },
  {
    id: 2,
    montant: 200,
    date: "2024-04-05",
    status: "En attente",
    produit: "01002929384",
    client: "Bob Martin",
  },
  {
    id: 3,
    montant: 150,
    date: "2024-04-07",
    status: "Terminé",
    produit: "01002929384",
    client: "Claire Dupont",
  },
  {
    id: 4,
    montant: 75,
    date: "2024-04-10",
    status: "Terminé",
    produit: "01002929384",
    client: "David Garcia",
  },
  {
    id: 5,
    montant: 300,
    date: "2024-04-12",
    status: "En attente",
    produit: "01002929384",
    client: "Emma Johnson",
  },
  {
    id: 6,
    montant: 250,
    date: "2024-04-15",
    status: "Terminé",
    produit: "01002929384",
    client: "Francis Smith",
  },
  {
    id: 7,
    montant: 180,
    date: "2024-04-18",
    status: "Terminé",
    produit: "01002929384",
    client: "Gabriel Wilson",
  },
  {
    id: 8,
    montant: 130,
    date: "2024-04-20",
    status: "Terminé",
    produit: "01002929384",
    client: "Hannah Brown",
  },
  {
    id: 9,
    montant: 400,
    date: "2024-04-22",
    status: "En attente",
    produit: "01002929384",
    client: "Isaac Taylor",
  },
  {
    id: 10,
    montant: 500,
    date: "2024-04-25",
    status: "Terminé",
    produit: "01002929384",
    client: "Julia Anderson",
  },
  {
    id: 11,
    montant: 100,
    date: "2024-04-01",
    status: "Terminé",
    produit: "01002929384",
    client: "Alice Dubois",
  },
  {
    id: 12,
    montant: 200,
    date: "2024-04-05",
    status: "En attente",
    produit: "01002929384",
    client: "Bob Martin",
  },
  {
    id: 13,
    montant: 150,
    date: "2024-04-07",
    status: "Terminé",
    produit: "01002929384",
    client: "Claire Dupont",
  },
  {
    id: 14,
    montant: 75,
    date: "2024-04-10",
    status: "Terminé",
    produit: "01002929384",
    client: "David Garcia",
  },
  {
    id: 15,
    montant: 300,
    date: "2024-04-12",
    status: "En attente",
    produit: "01002929384",
    client: "Emma Johnson",
  },
  {
    id: 16,
    montant: 250,
    date: "2024-04-15",
    status: "Terminé",
    produit: "01002929384",
    client: "Francis Smith",
  },
  {
    id: 17,
    montant: 180,
    date: "2024-04-18",
    status: "Terminé",
    produit: "01002929384",
    client: "Gabriel Wilson",
  },
  {
    id: 18,
    montant: 130,
    date: "2024-04-20",
    status: "Terminé",
    produit: "01002929384",
    client: "Hannah Brown",
  },
  {
    id: 19,
    montant: 400,
    date: "2024-04-22",
    status: "En attente",
    produit: "01002929384",
    client: "Isaac Taylor",
  },
  {
    id: 20,
    montant: 500,
    date: "2024-04-25",
    status: "Terminé",
    produit: "01002929384",
    client: "Julia Anderson",
  },
  {
    id: 21,
    montant: 250,
    date: "2024-04-15",
    status: "Terminé",
    produit: "01002929384",
    client: "Francis Smith",
  },
  {
    id: 22,
    montant: 180,
    date: "2024-04-18",
    status: "Terminé",
    produit: "01002929384",
    client: "Gabriel Wilson",
  },
  {
    id: 23,
    montant: 130,
    date: "2024-04-20",
    status: "Terminé",
    produit: "01002929384",
    client: "Hannah Brown",
  },
  {
    id: 24,
    montant: 400,
    date: "2024-04-22",
    status: "En attente",
    produit: "01002929384",
    client: "Isaac Taylor",
  },
  {
    id: 25,
    montant: 500,
    date: "2024-04-25",
    status: "Terminé",
    produit: "01002929384",
    client: "Julia Anderson",
  },
];
