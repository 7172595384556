import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PaginationTable from "../../../components/PaginationTable";
import { Context } from "../../../context/AppContext";
import { checkPermission } from "../../../utils/checkPermission";
import authApi from "../../../services/api/authApi";

export default function Products() {
  const [jwtUser] = useState(authApi.setup());

  const [page, setPage] = useState(1);
  const [productsFiltered, setProductsFiltered] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [subCategoryFilter, setSubCategoryFilter] = useState("all");
  const [sortingPrice, setSortingPrice] = useState(null);
  const [sortingDate, setSortingDate] = useState(null);
  const [allProducts, setAllProducts] = useState(null);

  const {
    productsData,
    customProductsData,
    categoriesData,
    subCategoriesData,
  } = useContext(Context);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Permet de rassembler oui ou non les productftv et les customs products en fonction du role de celui qui est connecté
    if (jwtUser && productsData && customProductsData) {
      if (jwtUser.roles.includes("SUPPORT")) {
        const combinedProductsData = [
          ...productsData.map((product) => ({
            ...product,
            productType: "product",
          })),
          ...customProductsData.map((customProduct) => ({
            ...customProduct,
            productType: "customProduct",
          })),
        ];
        setAllProducts(combinedProductsData);
      } else {
        const combinedProductsData = [
          ...productsData.map((product) => ({
            ...product,
            productType: "product",
          })),
        ];
        setAllProducts(combinedProductsData);
      }
    }
  }, [productsData, customProductsData]);

  useEffect(() => {
    // Permet de filtrer directement avec les données de l'url
    // Ex d'url : /produits?search=&category=Soins&subcategory=all

    const search = searchParams.get("search") || "";
    const category = searchParams.get("category") || "all";
    const subCategory = searchParams.get("subcategory") || "all";

    setSearchFilter(search);
    setCategoryFilter(category);
    setSubCategoryFilter(subCategory);
  }, [searchParams]);

  const itemsPerPage = 20;

  const handleSortPrice = () => {
    // Permet de trier en fonction du prix
    setSortingDate(null);
    if (!sortingPrice) setSortingPrice("descending");
    else if (sortingPrice === "ascending") setSortingPrice("descending");
    else setSortingPrice("ascending");
  };

  const handleSortDate = () => {
    // Permet de trier en fonction de la date
    setSortingPrice(null);
    if (!sortingDate) setSortingDate("descending");
    else if (sortingDate === "ascending") setSortingDate("descending");
    else setSortingDate("ascending");
  };

  const handleFilterChange = (filterType, value) => {
    // Permet de filtrer les les datas
    if (filterType === "search") {
      setSearchFilter(value);
    } else if (filterType === "category") {
      setCategoryFilter(value);
    } else if (filterType === "subcategory") {
      setSubCategoryFilter(value);
    }

    setSearchParams({
      search: filterType === "search" ? value : searchFilter,
      category: filterType === "category" ? value : categoryFilter,
      subcategory: filterType === "subcategory" ? value : subCategoryFilter,
    });
  };

  useEffect(() => {
    if (allProducts) {
      const filteredElements = allProducts.filter((product) => {
        const matchName = product.label
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
        const matchCategory =
          categoryFilter === "all" ||
          categoriesData.find(
            (category) => category["@id"] === product.category
          )?.label === categoryFilter;
        const matchSubCategory =
          subCategoryFilter === "all" ||
          subCategoriesData.find(
            (subCategory) => subCategory["@id"] === product.sub_category
          )?.label === subCategoryFilter;
        return matchName && matchCategory && matchSubCategory;
      });

      setProductsFiltered(filteredElements);
    }
  }, [searchFilter, categoryFilter, subCategoryFilter, allProducts]);

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="d-flex flex-column flex-md-row justify-content-md-between align-content-md-center mb-4">
        <h4>Liste des produits : </h4>
        {checkPermission(jwtUser.roles, "admin") && (
          <Link
            to="/produits/creer"
            style={{ width: "fit-content" }}
            className="btn btn-sm btn-primary"
          >
            Créer un nouveau produit
          </Link>
        )}
      </div>
      <div className="row mb-3">
        <div className="form-group col-12 col-md-6">
          <label htmlFor="name">Rechercher :</label>
          <input
            type="search"
            className="form-control"
            placeholder="Entrez le nom du produit"
            id="name"
            value={searchFilter}
            onChange={(e) => handleFilterChange("search", e.target.value)}
          />
        </div>
        <div className="form-group col-6 col-md-3">
          <label htmlFor="category">Catégorie :</label>
          <select
            className="form-control"
            id="category"
            value={categoryFilter}
            onChange={(e) => handleFilterChange("category", e.target.value)}
          >
            <option value="all">Tout</option>
            {categoriesData &&
              categoriesData.map((category, index) => (
                <option key={index} value={category.label}>
                  {category.label}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group col-6 col-md-3">
          <label htmlFor="subcategory">Sous-catégorie :</label>
          <select
            className="form-control"
            id="subcategory"
            value={subCategoryFilter}
            onChange={(e) => handleFilterChange("subcategory", e.target.value)}
          >
            <option value="all">Tout</option>
            {subCategoriesData &&
              subCategoriesData.map((subCategory, index) => (
                <option key={index} value={subCategory.label}>
                  {subCategory.label}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="table-responsive overflow-auto">
        <table className="table table-bordered table-hover position-relative">
          <caption>
            Résultat pour : {!page ? "0" : (parseInt(page) - 1) * itemsPerPage}{" "}
            - {!page ? "20" : itemsPerPage * parseInt(page)}
          </caption>
          <thead className="thead-dark">
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Réf</th>
              <th scope="col">Nom</th>
              <th scope="col">Description</th>
              <th scope="col">Catégorie</th>
              <th scope="col">Sous catégorie</th>
              <th
                onClick={handleSortPrice}
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center"
                scope="col"
              >
                <span>Prix</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "5px",
                    transition: "0.2s",
                    rotate:
                      sortingPrice === "descending"
                        ? "90deg"
                        : sortingPrice === "ascending"
                        ? "-90deg"
                        : "0deg",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>
            {productsFiltered
              .sort((a, b) =>
                sortingPrice === "ascending"
                  ? a.price - b.price
                  : sortingPrice === "descending"
                  ? b.price - a.price
                  : sortingDate === "ascending"
                  ? a.createdAt - b.createdAt
                  : sortingDate === "descending"
                  ? b.createdAt - a.createdAt
                  : null
              )
              .slice(
                (page - 1) * itemsPerPage,
                page === 1 ? 20 : (page - 1) * itemsPerPage + itemsPerPage
              )
              .map((produit, index) => (
                <tr
                  onClick={() => navigate(`/produits/details/${produit.id}`)}
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ width: "100px" }}>
                    <img
                      src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimage.darty.com%2Ftelephonie%2Faccessoire_pour_telephone_mobile%2Fchargeur_pour_telephone_mobile%2Ftemium_cvoy_2slots_musb_k1606084207700A_111007467.jpg&f=1&nofb=1&ipt=8acb2de075f92776e0af40764feac4c2814edfa6230edffb2e77fb64ccc4795e&ipo=images"
                      alt={produit.nom}
                      className=""
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </td>
                  <td style={{ width: "100px" }}>{produit.reference}</td>
                  <td style={{ width: "100px" }}>{produit.label}</td>
                  <td style={{ width: "200px" }}>
                    {produit.short_description}
                  </td>
                  <td style={{ width: "200px" }}>
                    {categoriesData &&
                      categoriesData.find((x) => x["@id"] === produit.category)
                        ?.label}
                  </td>
                  <td style={{ width: "200px" }}>
                    {subCategoriesData &&
                      subCategoriesData.find(
                        (x) => x["@id"] === produit.sub_category
                      )?.label}
                  </td>
                  <td style={{ width: "100px" }}>{produit.price} €</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <PaginationTable
        data={productsFiltered}
        itemsPerPage={itemsPerPage}
        pageSetter={setPage}
        pageGetter={page}
      />
    </div>
  );
}
