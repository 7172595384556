import React, { useEffect } from "react";

export default function FilterTable({
  placeholder, // placeholder pour l'input de recherche
  data, // les datas entieres avant qu'elles ne soient filtrées
  searchFilter, // getter du usestate pour la barre de recherche
  setSearchFilter, // setter du usestate pour la barre de recherche
  setterDataFiltered, // usestate du setter des datas filtrées afin de pouvoir les modifier
  elementsToIncludesInSearch,
}) {
  const handleUpdateFilter = (e) => {
    setSearchFilter(e.target.value);
  };

  useEffect(() => {
    if (data) {
      const filteredElements = data.filter((item) =>
        elementsToIncludesInSearch.some((elem) => {
          const found = deepSearch(item, elem.name);
          if (found) {
            if (elem.type === "string") {
              return found
                .toString()
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            } else if (elem.type === "array") {
              return item[elem.name].some((item) =>
                item.toLowerCase().includes(searchFilter.toLowerCase())
              );
            }
          }
          return false;
        })
      );
      setterDataFiltered(filteredElements);
    }
  }, [searchFilter, data]);

  const deepSearch = (object, key) => {
    const keys = key.split(".");
    let currentObject = object;

    for (let i = 0; i < keys.length; i++) {
      if (currentObject.hasOwnProperty(keys[i])) {
        currentObject = currentObject[keys[i]];
      } else {
        return null;
      }
    }

    return currentObject;
  };

  return (
    <div className="form-group">
      <label htmlFor="name">Rechercher :</label>
      <input
        type="search"
        className="form-control"
        id="name"
        placeholder={placeholder}
        onChange={handleUpdateFilter}
        value={searchFilter}
      />
    </div>
  );
}
