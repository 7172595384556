import React, { useState } from "react";
import { factures } from "../../../data/factures";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationTable from "../../components/PaginationTable";
import FilterTable from "../../components/FilterTable";

export default function InvoicesView() {
  const [page, setPage] = useState(1);
  const [dataFiltered, setDataFiltered] = useState([]); // setter à envoyer au composant FilterTable
  const [searchFilter, setSearchFilter] = useState(""); // servira pour le composant FilterTable

  const itemsPerPage = 20;

  const navigate = useNavigate();

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="d-flex justify-content-between mb-4">
        <h4>Historique des commandes : </h4>
      </div>
      <FilterTable
        placeholder="Entrez le numéro de la transaction, la date, le nom de l'établissement ou leur email"
        data={factures}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        setterDataFiltered={setDataFiltered}
        elementsToIncludesInSearch={[
          { name: "numero", type: "string" },
          { name: "date", type: "string" },
          { name: "destinataire.nom", type: "string" },
          { name: "destinataire.email", type: "string" },
        ]}
      />
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Numéro</th>
              <th scope="col">Date</th>
              <th scope="col">Nom du Destinataire</th>
              <th scope="col">Email</th>
              <th scope="col">Téléphone</th>
              <th scope="col">Total TTC</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {dataFiltered
              .slice(
                (page - 1) * itemsPerPage,
                page === 1 ? 20 : (page - 1) * itemsPerPage + itemsPerPage
              )
              .map((transaction, index) => (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/commandes/details/${transaction.numero}`)
                  }
                  key={index}
                >
                  <th scope="row">{transaction.numero}</th>
                  <td>{transaction.date}</td>
                  <td>{transaction.destinataire.nom}</td>
                  <td>{transaction.destinataire.email}</td>
                  <td>{transaction.destinataire.telephone}</td>
                  <td>{transaction.total_TTC}</td>
                  <td>Payé</td>
                </tr>
              ))}
          </tbody>
        </table>
        <PaginationTable
          data={factures}
          itemsPerPage={itemsPerPage}
          pageSetter={setPage}
          pageGetter={page}
        />
      </div>
    </div>
  );
}
