import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/AppContext";
import establishmentsApi from "../../services/api/establishmentsApi";

export default function EtablissementInfos() {
  const [editMode, setEditMode] = useState(false);
  const {
    setRoomSelected,
    fullData,
    etablissementSelected,
    setEstablishmentOfClientCreate,
    clientsData,
    setClientSelected,
    setTypeUserCreation,
  } = useContext(Context);

  const navigate = useNavigate();

  const handleNavigate = (url, item) => {
    setRoomSelected(item);
    document.getElementById("btnCloseModalPoll").click();
    navigate(url);
  };

  const handleNavigateClientSelected = (clientId) => {
    let index = clientsData.findIndex((client) => client["@id"] === clientId);
    setClientSelected(index);
    document.getElementById("modalOpener").click();
    navigate("/clients");
  };

  const etablissementIndexSelected =
    fullData &&
    fullData.findIndex(
      (etablissement) => etablissement["@id"] === etablissementSelected
    );

  const initialValues =
    etablissementIndexSelected !== -1 && fullData
      ? {
          name: fullData[etablissementIndexSelected].name,
          address_one: fullData[etablissementIndexSelected].address_one,
          city: fullData[etablissementIndexSelected].city,
          email: fullData[etablissementIndexSelected].email,
          phone: fullData[etablissementIndexSelected].phone,
          is_active: fullData[etablissementIndexSelected].is_active,
        }
      : {};

  const [inputsValue, setInputsValue] = useState(initialValues);

  useEffect(() => {
    setInputsValue(initialValues);
  }, [etablissementIndexSelected]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInputsValue((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setInputsValue(initialValues);
    setEditMode(false);
  };

  const handleSaveClick = () => {
    // Add your save logic here
    setEditMode(false);
  };

  const handleDeleteEstablishment = async () => {
    const parts = fullData[etablissementIndexSelected]["@id"].split("/");
    const estId = parts[parts.length - 1];
    console.log(estId);
    const response = await establishmentsApi.deleteEstablishment(estId);
    console.log(response);
  };

  return etablissementIndexSelected !== -1 &&
    fullData &&
    fullData[etablissementIndexSelected] ? (
    <div className="card-body py-4">
      <div className="d-md-flex justify-content-between mb-4">
        <h5 className="mb-3 mb-md-0">Détails du client :</h5>
        {editMode ? (
          <div
            style={{ flexWrap: "nowrap", gap: "10px" }}
            className="d-flex align-items-center"
          >
            <button
              onClick={handleCancelClick}
              className="btn btn-sm btn-primary"
            >
              Annuler
            </button>
            <button
              onClick={handleSaveClick}
              className="btn btn-sm btn-primary"
            >
              Enregistrer
            </button>
          </div>
        ) : (
          <button onClick={handleEditClick} className="btn btn-sm btn-primary">
            Modifier
          </button>
        )}
      </div>
      <div className="form-group">
        <label>Identifiant</label>
        <input
          type="text"
          className="form-control"
          value={fullData[etablissementIndexSelected].id}
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Nom</label>
        <input
          type="text"
          name="name"
          className="form-control"
          value={inputsValue.name}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Adresse</label>
        <input
          type="text"
          name="address_one"
          className="form-control"
          value={inputsValue.address_one}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Ville</label>
        <input
          type="text"
          name="city"
          className="form-control"
          value={inputsValue.city}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          name="email"
          className="form-control"
          value={inputsValue.email}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group">
        <label>Numéro de tél.</label>
        <input
          type="text"
          name="phone"
          className="form-control"
          value={inputsValue.phone}
          onChange={handleInputChange}
          readOnly={!editMode}
        />
      </div>
      <div className="form-group form-check">
        <input
          type="checkbox"
          name="is_active"
          checked={inputsValue.is_active}
          onChange={handleInputChange}
          readOnly={!editMode}
          className="form-check-input"
          id="is_active"
        />
        <label className="form-check-label" htmlFor="is_active">
          Actif
        </label>
      </div>
      <div className="mt-4" id="accordionRoomsEtablissements">
        <div className="card">
          <button
            className="btn btn-link"
            data-toggle="collapse"
            data-target="#collapseRoomsEtablissements"
            aria-expanded="true"
            aria-controls="collapseRoomsEtablissements"
          >
            {fullData[etablissementIndexSelected].rooms &&
            fullData[etablissementIndexSelected].rooms.length < 1
              ? "Aucune chambre associée"
              : "Liste des chambres"}
          </button>

          <div
            id="collapseRoomsEtablissements"
            className="collapse"
            aria-labelledby="headingRoomsEtablissements"
            data-parent="#accordionRoomsEtablissements"
          >
            {fullData[etablissementIndexSelected].rooms &&
              fullData[etablissementIndexSelected].rooms.map((item, index) => (
                <div
                  key={index}
                  style={{ borderTop: index !== 0 && "1px solid grey" }}
                  className="card-body"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0">{item.label}</p>
                    <button
                      className="text-primary bg-transparent border-0"
                      onClick={() =>
                        handleNavigate(`/chambres`, {
                          etablissement: etablissementIndexSelected,
                          room: index,
                        })
                      }
                    >
                      Plus d'informations
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="mt-4" id="accordionStaffEtablissements">
        <div className="card">
          <button
            className="btn btn-link"
            data-toggle="collapse"
            data-target="#collapseStaffEtablissements"
            aria-expanded="true"
            aria-controls="collapseStaffEtablissements"
          >
            Afficher le personnel
          </button>

          <div
            id="collapseStaffEtablissements"
            className="collapse"
            aria-labelledby="headingStaffEtablissements"
            data-parent="#accordionStaffEtablissements"
          >
            {clientsData &&
              clientsData
                .filter(
                  (client) =>
                    client?.corresponding_establishment &&
                    client.corresponding_establishment["@id"] ===
                      fullData[etablissementIndexSelected]["@id"]
                )
                .map((item, index) => (
                  <div
                    key={index}
                    style={{ borderTop: index !== 0 && "1px solid grey" }}
                    className="card-body"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-column">
                        <p className="m-0">
                          {item.firstname} {item.lastname}
                        </p>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 text-uppercase"
                        >
                          {item.user_type}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          handleNavigateClientSelected(item["@id"])
                        }
                        className="text-primary bg-transparent border-0"
                      >
                        Plus d'informations
                      </button>
                    </div>
                  </div>
                ))}
            <div style={{ borderTop: "1px solid grey" }} className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  {/* <p className="m-0">Ajouter une personne</p> */}
                </div>
                <button
                  onClick={() => {
                    setEstablishmentOfClientCreate(
                      fullData[etablissementIndexSelected]["@id"]
                    );
                    setTypeUserCreation("personnel");
                    document.getElementById("btnCloseModalPoll").click();
                    navigate("/clients/creer");
                  }}
                  className="text-primary bg-transparent border-0"
                >
                  Ajouter une personne
                </button>
              </div>
            </div>
          </div>
        </div>{" "}
        <button
          onClick={() => handleDeleteEstablishment()}
          className="btn-sm btn-danger float-right mb-3 mt-4"
        >
          Supprimer cette établissement
        </button>
      </div>
    </div>
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <p>Aucun établissement sélectionné</p>
    </div>
  );
}
