import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EtablissementInfos from "../../../components/detailsWithModal/EtablissementInfos";
import { Context } from "../../../context/AppContext";
import { checkPermission } from "../../../utils/checkPermission";
import authApi from "../../../services/api/authApi";

export default function Etablissements() {
  const [jwtUser] = useState(authApi.setup());

  const [page, setPage] = useState(1);
  const [lockerStatus, setLockerStatus] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [mode, setMode] = useState("list");
  const [etablissementIndexSelected, setEtablissementIndexSelected] =
    useState(null);

  const {
    fullData,
    etablissementSelected,
    setEtablissementSelected,
    groupsData,
    clientsData,
  } = useContext(Context);

  const itemsPerPage = 20;

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    // Permet d'afficher directement l'etablissement sélectionné

    if (etablissementSelected) {
      document.getElementById("modalOpener").click();
    }
  }, [etablissementSelected]);

  return (
    <div className="container-fluid">
      <div className="container-fluid d-flex flex-column">
        <div className="d-md-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-4">Emplacements des établissement :</h4>
          {checkPermission(jwtUser.roles, "admin") && (
            <Link to="/etablissements/creer" className="btn btn-sm btn-primary">
              Ajouter un nouvel établissement
            </Link>
          )}
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center">
          <div style={{ maxWidth: "500px" }} className="form-group w-100">
            <label htmlFor="locker_id">
              Recherche un établissement par son nom ou son id
            </label>
            <input
              placeholder="Recherche un locker par son nom ou son id"
              className="form-control"
              name="connected_lockers"
              id=""
              value={searchQuery}
              onChange={handleInputChange}
            />
          </div>
          <button
            id="modalOpener"
            type="button"
            style={{ display: "none" }}
            data-toggle="modal"
            data-target="#modalPoll"
          ></button>
        </div>

        <div className="table-responsive overflow-auto">
          <table className="table table-bordered table-hover position-relative">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Ville</th>
                <th scope="col">Adresse</th>
                <th scope="col">Numéro de téléphone</th>
                <th scope="col">Nombre de chambres</th>
              </tr>
            </thead>
            <tbody>
              {fullData &&
                fullData
                  .slice(
                    (page - 1) * itemsPerPage,
                    page === 1 ? 20 : (page - 1) * itemsPerPage + itemsPerPage
                  )
                  .map((etablissement, indexEta) => (
                    <tr
                      className={`${
                        etablissementSelected === etablissement["@id"] &&
                        "bg-primary text-white"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEtablissementSelected(etablissement["@id"]);
                        document.getElementById("modalOpener").click();
                      }}
                      key={indexEta}
                    >
                      <th scope="row">{etablissement.name}</th>
                      <th scope="row">{etablissement.city}</th>
                      <th scope="row">{etablissement.address_one}</th>
                      <th scope="row">{etablissement.phone}</th>
                      <th scope="row">{etablissement.rooms.length}</th>
                    </tr>
                  ))}{" "}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade right"
        id="modalPoll"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-backdrop="true"
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-full-height modal-right modal-notify modal-primary"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <p className="heading lead">Détails de l'établissement</p>

              <button
                style={{ fontSize: "12px" }}
                type="button"
                className="close btn btn-sm btn-primary"
                data-dismiss="modal"
                aria-label="Close"
                id="btnCloseModalPoll"
              >
                <span aria-hidden="true" className="white-text">
                  ×
                </span>
              </button>
            </div>

            <div>
              <EtablissementInfos />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
