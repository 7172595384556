import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const createManager = async (data) => {
  try {
    const response = await axios.post(`${API_URL}api/users`, data);

    return response.data;
  } catch (error) {
    console.error("Error createManager:", error);
    throw error;
  }
};

const createEtablishment = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}api/guestcare_ut_establishments/`,
      data
    );

    return response.data;
  } catch (error) {
    console.error("Error createEtablishment:", error);
    throw error;
  }
};
const createGroup = async (data) => {
  const response = await axios.post(
    `${API_URL}api/guestcare_jt_hotel_groups`,
    data
  );

  return response;
};

const update = async (data, productId) => {
  const response = await axios.put(
    `${API_URL}guestcare_ut_custom_products/${productId}`,
    data
  );

  return response;
};

export default {
  createGroup,
  createEtablishment,
  createManager,
};
