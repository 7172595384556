import React, { useState } from "react";

export default function PaymentsView() {
  // Exemple de paramètres de paiement d'entreprise
  const [companyPaymentSettings, setCompanyPaymentSettings] = useState({
    bankAccount: "123456789",
    iban: "ABCD1234567890",
    swiftCode: "SWFT1234",
    billingAddress: "123 Main Street, City, Country",
  });

  // Fonction pour mettre à jour les paramètres de paiement
  const updatePaymentSettings = (fieldName, value) => {
    setCompanyPaymentSettings({
      ...companyPaymentSettings,
      [fieldName]: value,
    });
  };

  return (
    <div className="container">
      <h1>Paramètres de paiement de l'entreprise</h1>
      <form>
        <div className="form-group">
          <label htmlFor="bankAccount">Compte bancaire</label>
          <input
            type="text"
            className="form-control"
            id="bankAccount"
            value={companyPaymentSettings.bankAccount}
            onChange={(e) =>
              updatePaymentSettings("bankAccount", e.target.value)
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="iban">IBAN</label>
          <input
            type="text"
            className="form-control"
            id="iban"
            value={companyPaymentSettings.iban}
            onChange={(e) => updatePaymentSettings("iban", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="swiftCode">Code SWIFT</label>
          <input
            type="text"
            className="form-control"
            id="swiftCode"
            value={companyPaymentSettings.swiftCode}
            onChange={(e) => updatePaymentSettings("swiftCode", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="billingAddress">Adresse de facturation</label>
          <textarea
            className="form-control"
            id="billingAddress"
            rows="3"
            value={companyPaymentSettings.billingAddress}
            onChange={(e) =>
              updatePaymentSettings("billingAddress", e.target.value)
            }
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Enregistrer
        </button>
      </form>
    </div>
  );
}
