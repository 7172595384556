import React from "react";

export default function Product() {
  return (
    <form className="mt-4">
      <div className="mb-3">
        <label htmlFor="productName" className="form-label">
          Nom du produit
        </label>
        <input
          type="text"
          className="form-control"
          id="productName"
          placeholder="Nom du produit"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="productPrice" className="form-label">
          Prix du produit
        </label>
        <input
          type="number"
          className="form-control"
          id="productPrice"
          placeholder="Prix du produit"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="productDescription" className="form-label">
          Description du produit
        </label>
        <textarea
          className="form-control"
          id="productDescription"
          rows="3"
          placeholder="Description du produit"
        ></textarea>
      </div>
      <button type="submit" className="btn btn-primary">
        Enregistrer les changements
      </button>
    </form>
  );
}
