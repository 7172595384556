import React, { useContext, useState } from "react";
import { Context } from "../../context/AppContext";
import establishementApi from "../../services/api/establishmentsApi";
import { getTokenServiceApi } from "../../services/api/getTokenServiceApi";
import SelectMultiple from "../form_components/SelectMultiple";

export default function CreateEstablishment() {
  const [establishmentData, setEstablishmentData] = useState({
    name: "",
    zipcode: "",
    city: "",
    phone: "",
    fax: "",
    email: "",
    // hotelGroup: "https://example.com/",
    customProducts: [],
    rooms: [],
    staff: [],
    bookings: [],
    rating: 0,
    // establishmentStripeParameters: "https://example.com/",
    addressOne: "",
    addressTwo: "",
    isActive: true,
    createdAt: "",
    modifiedAt: "",
    eToken: "",
    subscriptionType: "basic",
    gcApiKey: "",
  });

  const [staffSelected, setStaffSelected] = useState([]);
  const [customProductsSelected, setCustomProductsSelected] = useState([]);

  const [errors, setErrors] = useState({});
  const { fullData, clientsData, customProductsData } = useContext(Context);

  const handleSubmitEstablishment = async (e) => {
    e.preventDefault();

    const newErrors = {};
    const requiredFields = [
      "name",
      "zipcode",
      "city",
      "phone",
      "email",
      "addressOne",
    ];
    // Validation des champs
    requiredFields.forEach((field) => {
      if (!establishmentData[field].trim()) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } est requis.`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const token = await getTokenServiceApi("admin");
    const currentDate = new Date().toISOString();
    const updatedEstablishmentData = {
      ...establishmentData,
      gcApiKey: token,
      etoken: token,
      staff: staffSelected,
      createdAt: currentDate,
      modifiedAt: currentDate,
    };

    console.log(updatedEstablishmentData);
    // establishementApi.create(updatedEstablishmentData);
    setErrors({});
  };

  const handleUpdateData = (e) => {
    const { id, value } = e.target;
    setEstablishmentData({ ...establishmentData, [id]: value });
    setErrors({ ...errors, [id]: "" });
  };

  return (
    <div className="container-fluid">
      <h2>Créer un nouvel établissement</h2>
      <form
        onSubmit={handleSubmitEstablishment}
        className="needs-validation"
        noValidate
      >
        <div className="form-group">
          <label htmlFor="name">Nom :</label>
          <input
            type="text"
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
            id="name"
            placeholder="Entrez le nom de l'établissement"
            onChange={handleUpdateData}
            required
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="zipcode">Code postal :</label>
          <input
            type="text"
            className={`form-control ${errors.zipcode ? "is-invalid" : ""}`}
            id="zipcode"
            placeholder="Entrez le code postal"
            onChange={handleUpdateData}
            required
          />
          {errors.zipcode && (
            <div className="invalid-feedback">{errors.zipcode}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="city">Ville :</label>
          <input
            type="text"
            className={`form-control ${errors.city ? "is-invalid" : ""}`}
            id="city"
            placeholder="Entrez la ville"
            onChange={handleUpdateData}
            required
          />
          {errors.city && <div className="invalid-feedback">{errors.city}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="customProducts">Produits personnalisés :</label>
          <SelectMultiple
            dataSelected={customProductsSelected}
            setDataSelected={setCustomProductsSelected}
            elemToCompare="@id"
            valueToDisplay="label"
            data={customProductsData}
            btnText="Sélectionner les produits"
            name="customProducts"
            modalTitle="Sélection des produits customs"
          />
          {errors.customProducts && (
            <div className="invalid-feedback">{errors.customProducts}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="rooms">Chambres :</label>
          <select
            multiple
            className={`mdb-select custom-select multiple-select-dropdown ${
              errors.rooms ? "is-invalid" : ""
            }`}
            id="rooms"
            value={establishmentData.rooms}
            onChange={handleUpdateData}
            required
          >
            {fullData &&
              fullData.map((establishment, etaIndex) =>
                establishment.rooms.map((room, roomIndex) => (
                  <option key={roomIndex} value={room.label}>
                    {room.label}
                  </option>
                ))
              )}
          </select>
          {errors.rooms && (
            <div className="invalid-feedback">{errors.rooms}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="rooms">
            Personnel ({staffSelected.length} sélectionné(s)) :
          </label>
          <SelectMultiple
            dataSelected={staffSelected}
            setDataSelected={setStaffSelected}
            valueToDisplay={["lastname", "firstname"]}
            elemToCompare="@id"
            data={clientsData}
            btnText="Sélectionner le personnel"
            name="staff"
            modalTitle="Sélection du personnel"
          />
          {errors.staff && (
            <div className="invalid-feedback">{errors.staff}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="bookings">Réservations :</label>
          <select
            multiple
            className={`mdb-select custom-select multiple-select-dropdown`}
            id="bookings"
            value={establishmentData.bookings}
            onChange={handleUpdateData}
            required
          >
            {/* {clientsData &&
              clientsData.map((client, index) => (
                <option key={index} value={client["@id"]}>
                  {client.lastname} {client.firstname}
                </option>
              ))} */}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Téléphone :</label>
          <input
            type="tel"
            className={`form-control ${errors.phone ? "is-invalid" : ""}`}
            id="phone"
            placeholder="Entrez le numéro de téléphone"
            onChange={handleUpdateData}
            required
          />
          {errors.phone && (
            <div className="invalid-feedback">{errors.phone}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="fax">Fax :</label>
          <input
            type="text"
            className="form-control"
            id="fax"
            placeholder="Entrez le numéro de fax"
            onChange={handleUpdateData}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email :</label>
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            id="email"
            placeholder="Entrez l'email"
            onChange={handleUpdateData}
            required
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="addressOne">Adresse ligne 1 :</label>
          <input
            type="text"
            className={`form-control ${errors.addressOne ? "is-invalid" : ""}`}
            id="addressOne"
            placeholder="Entrez l'adresse ligne 1"
            onChange={handleUpdateData}
            required
          />
          {errors.addressOne && (
            <div className="invalid-feedback">{errors.addressOne}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="addressTwo">Adresse ligne 2 :</label>
          <input
            type="text"
            className="form-control"
            id="addressTwo"
            placeholder="Entrez l'adresse ligne 2"
            onChange={handleUpdateData}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Soumettre
        </button>
      </form>
    </div>
  );
}
