import React from "react";
import { Link } from "react-router-dom";
import authApi from "../../services/api/authApi";

export default function Header({ toggleNav }) {
  const handleLogout = () => {
    authApi.logout();
    window.location.href = "/login";
  };

  const listActions = [
    {
      label: "Créer un groupe d'hotel",
      icon: <i class="fa-solid fa-people-roof" />,
      href: "/groupes/creer",
    },
    {
      label: "Ajouter un client",
      icon: <i class="fa-solid fa-user-plus " />,
      href: "/clients/creer",
    },
    {
      label: "Créer un établissement",
      icon: <i class="fa-solid fa-hotel" />,
      href: "/etablissements/creer",
    },
    {
      label: "Créer une chambre",
      icon: <i class="fa-solid fa-bed" />,
      href: "/chambres/creer",
    },
    {
      label: "Créer un locker",
      icon: <i class="fa-solid fa-door-closed" />,
      href: "/lockers/creer",
    },
    {
      label: "Créer un produit",
      icon: <i class="fa-solid fa-bottle-water" />,
      href: "/produits/creer",
    },
    {
      label: "Ecrire un mail",
      icon: <i class="fa-solid fa-envelope" />,
      href: "/mails/creer",
    },
  ];

  return (
    <div className="container-fluid d-flex flex-column px-0 h-100">
      <div
        style={{ color: "white", height: "60px" }}
        className="py-1 px-4 navbar bg-primary navbar-dark d-flex justify-content-between align-items-center"
      >
        <div className="d-flex align-items-center">
          <button
            onClick={toggleNav}
            style={{
              color: "white",
            }}
            className="bg-transparent btn-outline-light rounded-sm border-0"
            data-toggle="collapse"
            data-target="#collapseWidthExample"
            aria-expanded="false"
            aria-controls="collapseWidthExample"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{
                width: "35px",
                height: "35px",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h4 className="ml-3 mb-0">Guest Care</h4>
        </div>

        <div style={{ color: "white" }} className="d-flex ">
          <div
            className=" d-none d-md-flex border-right pr-3 mr-3"
            style={{ gap: "5px" }}
          >
            {listActions.map((action, index) => (
              <Link
                key={index}
                to={action.href}
                data-toggle="tooltip"
                title={action.label}
                style={{ color: "white", width: "30px", height: "30px" }}
                className="d-flex align-items-center justify-content-center"
              >
                {action.icon}
              </Link>
            ))}
          </div>

          <div className="d-flex align-items-center">
            <Link
              style={{ color: "white" }}
              data-toggle="tooltip"
              title="Notifications"
              className="d-flex align-items-center justify-content-center text-white"
            >
              <i class="fa-solid fa-bell" />
            </Link>

            <div className="dropdown dropleft float-right d-flex align-items-center justify-content-center">
              <button
                className="d-flex items-center ml-4 dropdown bg-transparent border-0 text-white"
                data-toggle="dropdown"
              >
                <i class="fa-solid fa-user" />
              </button>

              <div className="dropdown-menu">
                <Link to="/compte" className="dropdown-item">
                  Gérer mon compte
                </Link>
                <button
                  onClick={() => handleLogout()}
                  className="dropdown-item"
                  href="#"
                >
                  Me déconnecter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
