import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const create = async (data) => {
  const response = await axios.post(`${API_URL}guestcare_ut_rooms`, data);
};

const update = async (roomId, data) => {
  const response = await axios.put(
    `${API_URL}api/guestcare_ut_rooms/${roomId}`,
    data
  );
  return response;
};

const deleteRoom = async (roomId) => {
  const response = await axios.delete(
    `${API_URL}api/guestcare_ut_rooms/${roomId}`
  );
  return response;
};

export default {
  create,
  deleteRoom,
  update,
};
