import React from "react";
import TimeoutService from "../services/common/timeoutService";

const NotFundView = () => {

    TimeoutService.redirect_on_event_checking("/", 2000)

    return (
        <div className="col-12 text-center mb-3">
            <img src={require("../../styles/img/resources/404.gif")} width="300px" alt="logo-alt"/>
            <br/>
            <div className="text-center">
                <i className="fas fa-spinner fa-pulse"/>
                <br/>
                Vous allez être redirigé.
            </div>
        </div>
    );
};

export default NotFundView;
