export const currentTime = () => {
  // Fonction pour avoir l'heure actuelle
  const now = new Date();

  // Convertir en chaîne ISO 8601 (UTC)
  const isoString = now.toISOString();

  // Ajouter le décalage du fuseau horaire
  const offset = -now.getTimezoneOffset();
  const offsetHours = Math.abs(Math.floor(offset / 60));
  const offsetMinutes = Math.abs(offset % 60);
  const sign = offset >= 0 ? "+" : "-";
  const timezone = `${sign}${String(offsetHours).padStart(2, "0")}:${String(
    offsetMinutes
  ).padStart(2, "0")}`;

  // Concaténer la date ISO avec le décalage du fuseau horaire
  const formattedDate = `${isoString.substring(
    0,
    isoString.length - 1
  )}${timezone}`;

  return formattedDate;
};
