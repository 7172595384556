import axios from "axios";
import Axios from "axios";
import jwtDecode from "jwt-decode";

/**
 *
 * @param credentials
 * @returns {Promise<boolean>}
 */
function authenticate(credentials) {
  return Axios.post(
    `${process.env.REACT_APP_API_URL}api/user/login_check`,
    credentials
  )
    .then((response) => response.data.token)
    .then((token) => {
      //store jwt
      window.localStorage.setItem("authJwtToken", token);
      //set headers
      set_axios_token(token);
      return true;
    });
}

/**
 *
 * @returns {boolean}
 */
function is_authenticated() {
  // step 1 : check if token exists
  const token = window.localStorage.getItem("authJwtToken");
  // step 2 : check if token is still valid & validate
  if (token) {
    const { exp: expiration } = jwtDecode(token);
    return expiration * 1000 > new Date().getTime();
  }
  return false;
}

function logout() {
  window.localStorage.removeItem("authJwtToken");
  delete Axios.defaults.headers["Authorization"];
}

function signUp(data) {
  return Axios.post(`${process.env.REACT_APP_API_URL}api/users/`, data)
    .then((response) => response.data.token)
    .then((token) => {
      //store jwt
      window.localStorage.setItem("authJwtToken", token);
      //set headers
      set_axios_token(token);
      return true;
    });
}

const createUser = async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}api/users`,
    data
  );

  return response.data;
};

const updateUser = async (userId, data) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}api/users/${userId}`,
    data
  );

  return response.data;
};

const deleteUser = async (userId) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}api/users/${userId}`
  );

  return response.data;
};

/**
 *
 * @param token
 */
function set_axios_token(token) {
  Axios.defaults.headers["Authorization"] = "Bearer " + token;
}

/**
 *
 * @returns {unknown}
 */
function setup() {
  // step 1 : check if token exists
  const token = window.localStorage.getItem("authJwtToken");
  // step 2 : check if token is still valid & validate
  if (token) {
    const { exp: expiration } = jwtDecode(token);
    if (expiration * 1000 > new Date().getTime()) {
      set_axios_token(token);
      return jwtDecode(token);
    }
  }
}

export default {
  authenticate,
  is_authenticated,
  logout,
  setup,
  signUp,
  updateUser,
  deleteUser,
  createUser,
};
